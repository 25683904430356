import { Component, ChangeDetectorRef, HostListener, OnInit, ViewChild, ElementRef, ViewEncapsulation, viewChild, TemplateRef } from '@angular/core';
import { Subscription, Observable, ReplaySubject } from 'rxjs';
import { DataService } from 'src/app/services/data.service';
import { GlobalService } from 'src/app/services/global.service';
import { environment } from 'src/environments/environment';
import * as global from 'src/app/globals';
import { DynamicPopupsModalComponent } from '../dynamic-popups-modal/dynamic-popups-modal.component';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';
import { ContentfulService } from 'src/app/services/contentful.service';

import { Clipboard } from '@angular/cdk/clipboard';
import { ComponentCanDeactivate } from 'src/app/guard/auth-guard.service';
import { DynamicPopsModalComponent } from '../../cart/dynamic-pops-modal/dynamic-pops-modal.component';
import { takeUntil } from 'rxjs/operators';
import { RegisterComponent } from 'src/app/user/register/register.component';
import { FormBuilder, FormGroup } from '@angular/forms';

declare var $: any;
declare var moment: any;
@Component({
    selector: 'app-myaccountproile',
    templateUrl: './myaccountproile.component.html',
    styleUrls: ['./myaccountproile.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class MyaccountproileComponent implements OnInit, ComponentCanDeactivate {
  sharedProjectCount: any;
  passwordShow = false;
  showIcon: boolean = false;
  showIcon2: boolean = false;
  imageIconPath: any;
  previewCrossed: any;
  previewOutline: any;
  currentOemCode: any;
  showSavedProfileMsg: boolean = false;
  loginSubscription: Subscription;
  errorMessage: any = {};
  lastTypeValue: string;
  accountFormGroup:FormGroup;
  selectedDayfs: any;
  selectedMonthfs: any;
  selectedYearfs: any;
  canDeactivate(): Observable<boolean> | boolean {
    return true
  }
  @ViewChild('myprofileForm') myprofileForm: any;
  @ViewChild('changePasswordForm') changePasswordForm: any;
  @ViewChild("changeDateOfBirthForm") changeDateOfBirthForm: any;
  @ViewChild('container') container: any;
  @ViewChild('input') input: any;

  @ViewChild('onEdit', { static: false }) onEdit: ElementRef;

  @ViewChild("onPhoneNoEdit") onPhoneNoEdit: any;
  @ViewChild('shippingForm') shippingForm: any;
  @ViewChild('billingForm') billingForm: any;
  @ViewChild('profileDetailUpdate') profileDetailUpdate: TemplateRef<any>;
  @ViewChild('profilePasswordUpdate') profilePasswordUpdate: TemplateRef<any>;
  @ViewChild('addAddress') addAddress: TemplateRef<any>;
  public profileDetailUpdateRef: NgbModalRef;
  public profilePasswordUpdateRef: NgbModalRef;
  public addAddressRef: NgbModalRef;
  isPicChanged: Boolean;
  albmData = [];
  user: any = {};
  userProfile: any;
  oldEmail: any;
  isEmailConfirmed: Boolean;
  isEmailNonverified: Boolean;
  isEmailverified: Boolean;
  selectedDay: any;
  disableValue1: any;
  submitted: any;
  selectedMonth: any;
  disableValue2: any;
  selectedYear: any;
  disableValue3: any;
  arrCountry = [];
  profileImageUrl: any;
  profileData1: any;
  profileData2: any;
  isDisabled: Boolean;
  isPasswordMatched: Boolean;
  isPasswordWrong: Boolean;
  isPasswordSame: Boolean;
  invalidPassword1: Boolean;
  isNameInputDisabled: boolean = true;
  invalidPassword2: Boolean;
  showPasswordButton: boolean = false;
  showAccountInformatio: boolean = false;
  showDateSelect: boolean = false;
  showMonthSelect: boolean = false;
  showYearSelect: boolean = false;
  arrDay = [];
  arrMonth = [];
  arrYear = [];
  ageLimitValue: any = 10;
  mainArrDay = [];
  mainArrMonth = [];
  mainArrYear = [];
  ageLimitExceed: Boolean = false;
  emailReq: Boolean;
  emailInvalid: Boolean;
  emailSendMsg: any;
  emailUpdateSuccess: any = false;
  emailAlreadyExists: any;
  emailSendingFail: any;
  isUpdateProcessing: Boolean;
  updateSuccessMsg: Boolean;
  phoneLenIncorrect: Boolean;
  matchPassword: Boolean;
  newsletterApiValue: any;
  resetPasswordSuccess: any;
  message: any;
  isPasswordSame1: any;
  isPasswordSame2: any;
  newsletterDate: any;
  arrCountries: any = [];
  charMinLength: any;
  charMaxLength: any;
  applyPattern: any;
  countryObj: any;
  isDataLoading: Boolean;
  charLenIncorrect: Boolean;
  streetError: Boolean;
  isformDisabled: Boolean = false;
  isInputDisabled: Boolean = false;
  deleteProfileUrl: any;
  newsletterUnsubscribeUrl: any;
  dicUrls: any;
  apiMsg: any;
  showError: Boolean = false;
  contactusUrl: any;
  newsletterCheckbox: any;
  isEmailInputDisabled: Boolean = true;
  isPhoneNoInputDisabled: Boolean = true;
  businessInfo = {};
  isShowBusinessInfo: Boolean = false;
  bannerImage: any;
  displayForgotPasswordBtn: Boolean = false;
  languageData: any;
  succesMessage: any;
  EDIT_ICON: any;
  OK_ICON: any;
  showMsgFlag: Boolean = false;
  confirmationMsg = undefined;
  styleClassApiMsg: any = {};
  displayResendVerifyEmailLink: Boolean = false;
  isDisplayPasswordSection: Boolean = false;
  totalProductNo: any;
  languageDataSubscription: Subscription;
  productCount: any
  orderCount: any;
  accountIcon: any;
  showAccountInfo: boolean = false;
  dayInfo: any;
  selectday: boolean = false;
  selectmonth: boolean = false;
  selectyear: boolean = false;
  totalRecord: number;
  totalOrderNo: any;
  KontoImg: any;
  displayVerifyEmailLink: Boolean = true;
  isValidDate: Boolean = false;
  showEditIcon: boolean = false;
  isValidEmail: boolean = false;
  emailVerification: Boolean;
  contentType: string = 'page';
  urlLink: any;
  showLinkButton: boolean = false
  referalMSG: string;

  // For my Profile
  binIcon: any;
  searchIcon: any;
  searchTextBilling = '';
  selectedAddressIndex: any;
  formName: any;
  billingAddressData = [];
  billingAddressEditable = false;
  addressBookData = [];
  arrBillingAddressBook: any;
  userEmail: any;
  authId: any;
  arrOemCountries: any = [];
  userProfileData: any = [];
  oemCountryObj: any;
  selectedShippingCountry = undefined;
  arrAddressBook: any;
  isEnabledBillingForm: Boolean = false;
  charMinLengthBl: any;
  charMaxLengthBl: any;
  applyPatternBl: any;
  isDisabledShippingFeilds: Boolean = false;
  termsPageUrl: any;
  AGBTagHtml: any;
  shippingAddress: any;
  billingAddress: any = {};
  charLenIncorrectBl: Boolean;
  isZipBlRequired = false;
  arrCountrieCodesStr = '';
  pickupAddressData: any;
  pickupAddressDataLength = 0;
  arrUsedPickupLocations: any = [];
  arrAllPickupLocations: any = [];
  activeTab: any = 'shipping';
  activeUsedTab: any;
  shippingType: boolean;
  isZipRequired = false;
  error1: Boolean = false;
  streetErrorBl: Boolean;
  showErrorMsg: Boolean = false;
  showNonverified: boolean = false;
  isSameAsShippingChecked: Boolean = false;
  sameShippingChecked: Boolean = false;
  userInfo: any;
  getGender = [];
  addressList = {
    billingDetail: {
      ID: '',
      firstName: '',
      lastName: '',
      gender: '',
      company: '',
      houseNumber: '',
      street: '',
      city: '',
      zip: '',
      selectedCountry: '',
      selectedCountryCode: '',
      phoneExtension: '',
      phoneNo: '',
      phone: '',
      email: '',
      IsDefaultShipping: 0,
      IsDefaultBilling: 0,
    },
    pickupLocationAddress: {},
    userOrderLength: 0,
  };
  submitDisabled: boolean = true;
  deleteAccountEnabled = true;
  isEditMode:any=false;
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  constructor(private changeDetectorRef: ChangeDetectorRef, private globalService: GlobalService, private dataService: DataService, private modalService: NgbModal, private router: Router,
    private contentfulservice: ContentfulService, private route: ActivatedRoute, public contentfulService: ContentfulService,
    private clipboard: Clipboard,private fb:FormBuilder) {
      this.creatAccountFormGroup();
    document.addEventListener('click', this.fillEmail.bind(this));
    this.bannerImage = environment[global.env].RESOURCE_URL_AMAZON + environment[global.env].oemCode + '/preview/my_profile_nl_banner.jpg';
    this.accountIcon = environment[global.env].RESOURCE_URL_AMAZON + environment[global.env].oemCode + '/web-assets/profile.svg';
    let iconsObj = this.globalService.getIconsObj();
    this.EDIT_ICON = environment[global.env].RESOURCE_URL_AMAZON + environment[global.env].oemCode + '/icons/edit-24-turq.svg';
    this.previewCrossed = environment[global.env].RESOURCE_URL_AMAZON + environment[global.env].oemCode + '/icons/preview-crossed-24.svg';
    this.previewOutline = environment[global.env].RESOURCE_URL_AMAZON + environment[global.env].oemCode + '/icons/preview-outline-24.svg';
    // this.EDIT_ICON = iconsObj['EDIT_ICON'];
    this.imageIconPath = environment[global.env].RESOURCE_URL_AMAZON + environment[global.env].RESOURCE_CONTAINER + environment[global.env].oemCode + "/icons/";
    this.OK_ICON = iconsObj['OK_ICON'];
    this.KontoImg = environment[global.env].RESOURCE_URL_AMAZON + environment[global.env].oemCode + '/web-assets/Konto.jpg';
    this.searchIcon =
      environment[global.env].RESOURCE_URL_AMAZON +
      environment[global.env].oemCode +
      '/icons/search-24.svg';
    this.binIcon =
      environment[global.env].RESOURCE_URL_AMAZON +
      environment[global.env].oemCode +
      '/icons/trash-24-turq.svg';
    this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
    //
    if (this.userInfo !== null) {
      this.authId = this.userInfo.auth;
    }
    this.getGender = [{ id: "1", gender: 'Male' }, { id: "2", gender: 'Female' }, { id: "3", gender: 'Neutral' }];


    this.loginSubscription = this.globalService.getUserLoginDetailObservable().subscribe((data) => {
      this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
      this.loginSubscription.unsubscribe();
      this.loginSubscription = undefined;
      if (data && data.loginType == 'recallApi') {
        this.fetchProfileData();
        this.arrDay = this.getDMY(1, 31, false);
        this.arrMonth = this.getDMY(1, 12, false);
        this.arrYear = this.getDMY((new Date().getFullYear() - (this.ageLimitValue)), 100, true);
        this.getfullCalendar();
        //window.location.reload();
      }
    });
    // window.addEventListener('message', function (event) {
    //   if (event.data.userData) {
    //     console.log('event data my acc profile called=====>', (JSON.stringify(event.data.userData)));
    //     let userInfo =  JSON.stringify(event.data.userData);
    //     localStorage.clear();
    //     localStorage.setItem('userInfo', userInfo);


    //   }

    //   // Optionally, send a message back to the parent
    //   // event.source.postMessage({ response: 'Hello from iframe' }, event.origin);
    // });

  }
  // loadParams(){
  //   setTimeout(() => {
  //     const urlParams = new URLSearchParams(window.location.search);
  //     const param1Value = urlParams.get('param1');
  //     console.log('param1Value',param1Value);  // Outputs: value1
  //   }, 2000);

  // }
  ngOnInit(): void {
    // this.loadParams();
    var domain = location.origin;
    if (domain.includes('sw.fit') || domain.includes('localhost')) {
      this.deleteAccountEnabled = true;
    }
    
    this.currentOemCode = environment[global.env].oemCode;
    this.getAddressBook(undefined, '');
    this.productCount = localStorage.getItem('totalProductCount')
    this.orderCount = localStorage.getItem('totalOrderCount')
    this.sharedProjectCount = localStorage.getItem('totalSharedProjectCount')
    this.orderCount = localStorage.getItem('totalOrderCount')


    if (localStorage.getItem('userInfo') == null) {
      let data = { type: "OPEN_LOGIN" };
      // this.globalService.setOpenLogin(data);
      // this.globalService.setOpenRegister(data);
    }
    else {
      this.isDataLoading = true;

      if (this.globalService.gettLanguageData() != undefined) {
        this.languageData = this.globalService.gettLanguageData();
        this.fetchProfileData();
        this.arrDay = this.getDMY(1, 31, false);
        this.arrMonth = this.getDMY(1, 12, false);
        this.arrYear = this.getDMY((new Date().getFullYear() - (this.ageLimitValue)), 100, true);
        this.getfullCalendar();
        // 
      }
      else {
        this.languageDataSubscription = this.globalService.languageDataEmiter.subscribe(data => {
          if (data) {
            this.languageData = data;
            //this.getGender.push({ id:"1", gender:this.languageData.web['address_gender_male'] }, { id:"2", gender: this.languageData.web['address_gender_female']});
            // 

            this.fetchProfileData();
            this.arrDay = this.getDMY(1, 31, false);
            this.arrMonth = this.getDMY(1, 12, false);
            this.arrYear = this.getDMY((new Date().getFullYear() - (this.ageLimitValue)), 100, true);
            this.getfullCalendar();
          }
        });
      }

      this.dicUrls = this.globalService.getStaticPagesUrls(global.env, global.lang);
      let myAccountUrl = this.dicUrls['ACCOUNT_PREVIEW'];
      this.deleteProfileUrl = myAccountUrl + '/' + this.dicUrls['DELETE_PROFILE'];
      this.newsletterUnsubscribeUrl = myAccountUrl + '/' + this.dicUrls['NEWSLETTER_UNSUBSCRIBE_PREVIEW'];
      this.contactusUrl = this.dicUrls['CONTACTUS_PREVIEW'];
    }
    let url = this.router.url.split('?')[0];
    let arrUrl = url.split('/');
    let pageSlug = decodeURIComponent(arrUrl[1]);

    if (this.route.snapshot.params['level1']) {
      pageSlug = pageSlug + "/" + this.route.snapshot.params['level1'];
    }
    if (this.route.snapshot.params['level2']) {
      pageSlug = pageSlug + "/" + this.route.snapshot.params['level2'];
    }
    if (this.route.snapshot.params['level3']) {
      pageSlug = pageSlug + "/" + this.route.snapshot.params['level3'];
    }
    if (this.route.snapshot.params['level4']) {
      pageSlug = pageSlug + "/" + this.route.snapshot.params['level4'];
    }
    if (pageSlug) {
      this.getDataFromContentFul(pageSlug);
    }

    let userData = JSON.parse(localStorage.getItem('userInfo'));
    if (userData !== null && userData.userType == "Normal" && userData.isPassword == 'No') {
      const data = { code: '', fromemail: false };
      this.contentfulService.openemailResetPasswordModal(data)

    }

  }
  getDataFromContentFul(pageSlug) {
    this.contentfulService.getContentByFieldName(this.contentType, pageSlug).then(data => {
      if (data) {
        if (data.length == 0) {
          this.router.navigateByUrl('/404');
          return;
        }
        if (data.length > 0 && data[0].fields['seoMetadata']) {
          let seoData = {};
          seoData = data[0].fields['seoMetadata'].fields;
          this.globalService.setSeoMetaData(seoData);
          this.contentfulService.emittranslatedUrl(data[0].fields['seoMetadata'].sys.id);
          //

        }
        let headerObject = {
          headerVersion: false,
          headerData: false
        }
        let footerObject = {
          footerVersion: false,
          footerData: false
        }
        if (data[0].fields['topSection']) {
          let allComponentFilter = JSON.parse(JSON.stringify(data[0].fields['topSection']))
          let headerFound = allComponentFilter.filter(item => item.fields && item.fields.selectDesign);


          if (headerFound.length > 0) {
            let headerData = headerFound[0];
            if (headerData.fields.selectDesign) {
              let headerObject = {
                headerVersion: headerData.fields.selectDesign.split('-')[2],
                headerData
              }
              //
              this.contentfulService.emitHeaderData(headerObject)

            }
          } else {
            this.contentfulService.emitHeaderData(headerObject)
          }


        } else {
          this.contentfulService.emitHeaderData(headerObject)
        }

        if (data[0].fields['pageFooter']) {

          let allComponentFilter = JSON.parse(JSON.stringify(data[0].fields['pageFooter']))
          let footerFound = allComponentFilter.filter(item => item.fields && item.fields.selectDesign);


          if (footerFound.length > 0) {
            let footerData = footerFound[0];
            if (footerData.fields.selectDesign) {
              let footerObject = {
                footerVersion: footerData.fields.selectDesign.split('-')[2],
                footerData
              }
              // 
              this.contentfulService.emitFooterData(footerObject)

            }
          } else {
            this.contentfulService.emitFooterData(footerObject)
          }


        } else {
          this.contentfulService.emitFooterData(footerObject)
        }
        this.contentfulService.emitFooterAtLast(true);
      }

    })
  }
  myPhotos($event) {
    $event.stopPropagation();
    // 
    var url;
    if (global.lang == 'de') {
      url = '/myphotos';
    }
    else {
      url = '/myphotos';
    }
    this.router.navigate([url]);
  }
  myprojectUrl($event) {
    $event.stopPropagation();
    // 
    var url;
    if (global.lang == 'de') {
      url = '/projekte';
    }
    else {
      url = '/projects';
    }
    this.router.navigate([url]);
  }
  sharedprojectUrl($event) {
    $event.stopPropagation();
    // 
    var url;
    if (global.lang == 'de') {
      url = '/geteilte-projekte';
    }
    else {
      url = '/shared-projects';
    }
    this.router.navigate([url]);
  }
  orderUrl($event) {
    $event.stopPropagation();
    // 
    var url;
    if (global.lang == 'de') {
      url = '/bestellungen';
    }
    else {
      url = '/orders';
    }
    this.router.navigate([url]);

  }
  myAccountUrl($event) {
    $event.stopPropagation();

    var url;
    if (global.lang == 'de') {
      url = '/konto';
    }
    else {
      url = '/account';
    }
    this.router.navigate([url]);
  }

  preventLeadingSpace(event: any) {
    const pasteData = event.clipboardData?.getData('text') || '';
    if (event.key === '"') {
      event.preventDefault();
    }
    if (pasteData.includes('"')) {
      event.preventDefault();
    }
  }

  fillEmail(event: any) {
    if (this.container?.nativeElement?.contains(event.target)) {
      if ((this.user.userName == "") && (this.myprofileForm.form.value.email == "")) {
        this.input.nativeElement.value = this.userProfile.userEmail;
        this.user.userName = this.userProfile.userEmail;
      }
    }
  }
  showSignUpModal(data) {
    const modalRef = this.modalService.open(RegisterComponent, {
      size: 'md',
      windowClass: 'modalWindowWidth',
      centered: true,
      backdrop: 'static',
      keyboard: true,
    });
    modalRef.result.then((result) => { }).catch((error) => { });
    modalRef.componentInstance.productdata = data;
  }
  fetchProfileData() {

    let userData = JSON.parse(localStorage.getItem('userInfo'));
    if (userData.userType == "Guest") {

      let data = { type: "OPEN_LOGIN" };
      //this.globalService.setOpenLogin(data);
      // this.globalService.setOpenRegister(data);
      this.showSignUpModal({ loginType: 'recallApi' })
      return false;
    }
    this.showAccountInfo = true
    this.getData()
    //this.getData(true)

    let authkey = userData.auth;
    this.isPicChanged = false;

    this.dataService.getprofileDetails(authkey).subscribe((res: Response) => {
      let resSTR = JSON.stringify(res);
      let resJSON = JSON.parse(resSTR);

      if (resJSON.success == true) {
        this.userProfile = resJSON.data;
        this.urlLink = this.userProfile.referalLink


        this.displayResendVerifyEmailLink = this.userProfile.user_type == "Guest" || this.userProfile.user_type == "Nonverified" ? true : false;
        // if(this.userProfile.user_type == "Guest"){
        //     this.displayForgotPasswordBtn = true;
        // }
        // else{
        //     this.displayForgotPasswordBtn = false;
        // }

        if (resJSON.businessInfo) {
          if (resJSON.businessInfo.resellerStatus == 'confirm') {
            this.businessInfo = resJSON.businessInfo;
            this.isShowBusinessInfo = true;
          }
          else {
            this.isShowBusinessInfo = false;
          }
        }
        else {
          this.isShowBusinessInfo = false;
        }

        this.arrCountries = resJSON.oemCountries;

        if (this.userProfile.Street != '') {
          this.isformDisabled = true;
          this.isPhoneNoInputDisabled = true;
        }
        else {
          this.isformDisabled = false;
          this.isInputDisabled = true;
        }

        this.isEmailInputDisabled = this.userProfile.userEmail != '' && this.userProfile.userEmail != null ? true : false;

        this.user.userName = this.userProfile.userEmail;
        if (this.userProfile.Salutation != null) {
          let salutationKey = 'my_profile_gender_' + this.userProfile.Salutation;
          this.user.salutation = this.languageData.web[salutationKey];
        }
        if (this.userProfile.FirstName == '') {
          this.isInputDisabled = false;
        }
        this.oldEmail = this.userProfile.userEmail;
        this.user.firstName = this.userProfile.FirstName;
        this.user.lastName = this.userProfile.LastName;
        this.user.selectedCountry = this.userProfile.countryName;
        this.user.street = this.userProfile.Street;
        this.user.houseNumber = this.userProfile.HouseNumber;
        this.user.cosUserName = this.userProfile.CosUserId;
        this.user.userNumber = this.userProfile.userNumber;
        this.user.gender = this.userProfile.gender;
        this.user.zip = this.userProfile.zipCode;
        this.user.city = this.userProfile.City
        this.user.state = this.userProfile.state;
        if (this.userProfile.countryName == undefined || this.userProfile.countryName == null || this.userProfile.countryName == '') {
          this.countryObj = this.getCountry('', environment[global.env].defaultCountryCode);
          this.getZipPattern(this.countryObj);
        }
        else {
          this.countryObj = this.getCountry(this.userProfile.countryName, this.userProfile.Country);
          this.getZipPattern(this.countryObj);
        }

        if (this.userProfile.Phone != undefined) {
          if (this.userProfile.Phone.indexOf("-") > -1) {
            this.user.phoneNo = this.userProfile.Phone.split("-")[1];
          }
          else {
            this.user.phoneNo = this.userProfile.Phone;
          }
        }
        else {
          this.user.phoneNo = '';
        }

        if (this.userProfile.Status == "Emailconfirmlink" && this.userProfile.isConfirmed == "Yes") {
          this.isEmailConfirmed = true;
        } else {
          this.isEmailConfirmed = false;
        }

        // if (this.userProfile.isConfirmed == "Yes" && (userData.userType == "Guest" || userData.userType == "Nonverified"))
        if (this.userProfile.isConfirmed == "Yes" && this.userProfile.user_type == "Nonverified") {
          this.isEmailNonverified = true;
        }
        else if (this.userProfile.isConfirmed == "Yes" && this.userProfile.user_type == "Normal") {
          this.isEmailverified = true;
          this.isEmailConfirmed = false;
        }
        if ((this.userProfile.Status == "Active" && this.userProfile.isConfirmed == "Yes") || (this.userProfile.Status == "Resetpasswordlink" && this.userProfile.isConfirmed == "Yes")) {
          this.isEmailverified = false;
          this.isEmailNonverified = false;
          this.isEmailConfirmed = false;
        }


        this.user.aboutMe = (this.userProfile.AboutMe == 'null') ? '' : this.userProfile.AboutMe;


        let yyyymmdd = this.userProfile.Dateofbirth.split(' ')[0].split('-');
        if (this.languageData) {
          if (yyyymmdd[2] == '00' || yyyymmdd[2] == '' || yyyymmdd[2] == undefined) {
            this.selectedDay = this.languageData.web.register_field_label_dd;
            this.disableValue1 = false;
            //  this.emailUpdateSuccess = false;
          }
          else {
            this.submitted = true;
            this.disableValue1 = true;
            this.selectedDay = parseInt(yyyymmdd[2]);
            // if (this.showAccountInformatio == true) {
            //     this.emailUpdateSuccess = true;
            // }
          }
          if (yyyymmdd[1] == '00' || yyyymmdd[1] == '' || yyyymmdd[1] == undefined) {
            this.selectedMonth = this.languageData.web.register_field_label_mm;
            this.disableValue2 = false;
          }
          else {
            this.submitted = true;
            this.disableValue2 = true;
            this.selectedMonth = parseInt(yyyymmdd[1]);
          }
          if (yyyymmdd[0] == '0000' || yyyymmdd[0] == '' || yyyymmdd[0] == undefined) {
            this.selectedYear = this.languageData.web.register_field_label_yyyy;
            this.disableValue3 = false;
          }
          else {
            this.submitted = true;
            this.disableValue3 = true;
            this.selectedYear = parseInt(yyyymmdd[0]);
          }
        }

        this.user.gender = this.userProfile.gender;
        if (this.userProfile.Title == 'null' || this.userProfile.Title == undefined || this.userProfile.Title == 'undefined') {
          this.userProfile.Title = '';
          this.user.title = ''
        }
        else {
          this.user.title = this.userProfile.Title
        }

        if (this.userProfile.subtitle == 'null' || this.userProfile.subtitle == undefined || this.userProfile.subtitle == 'undefined') {
          this.userProfile.Title = '';
          this.user.subtitle = ''
        }
        else {
          this.user.subtitle = this.userProfile.subtitle
        }


        this.user.newsletter = this.userProfile.newsletter;
        this.newsletterApiValue = this.userProfile.newsletter;

        var profilePic = this.userProfile.ProfileImage || 'placeholder.jpg';
        this.profileImageUrl = (this.userProfile.ProfileImage == null) ? null : (environment[global.env].RESOURCE_URL_AMAZON + environment[global.env].AMAZON_CUSTOMER_FOLDER_PATH + 'thumb/250/' + profilePic);

        if (this.userProfile.newsletterUpdateDate != null) {
          var newsletterDate = this.userProfile.newsletterUpdateDate;
          newsletterDate = newsletterDate.split(' ')[0];
          var letterDate = newsletterDate.split('-');
          this.newsletterDate = letterDate[2] + "." + letterDate[1] + "." + letterDate[0];
        }

        this.profileData1 = JSON.parse(JSON.stringify(this.user));
        this.profileData2 = JSON.parse(JSON.stringify(this.user));

        if (this.userProfile.Dateofbirth == "0000-00-00 00:00:00") {
          this.isDisabled = false;
        }
        else {
          this.isDisabled = true;
        }
        this.isDataLoading = false;
      }
    })

    this.referalMSG = this.languageData.web.myprofile_referal_msg;


  }


  getZipPattern(countryObj) {
    if (countryObj) {
      this.user.selectedCountry = countryObj.countryName;
      this.user.extension = countryObj.ISD;
      this.charMinLength = countryObj.MinPostalLength;
      this.charMaxLength = countryObj.PostalLength;
      if (countryObj.isAlphaNumeric == "Yes") {
        this.applyPattern = '^[- a-zA-Z0-9]+$';
      }
      else if (countryObj.isAlphaNumeric == "No") {
        this.applyPattern = '^[0-9]*$';
      }
      else {
        this.applyPattern = '^[0-9]*$';
      }
    }
  }

  // newsletterSubscribe() {
  //     // this.user.newsletter = true;
  //     let email = this.oldEmail;
  //     var type = "subscribe"
  //     // this.isLoadingData = true;

  //     this.dataService.newsletterSubscribe(email, type).subscribe((res: Response) => {
  //         
  //         let resSTR = JSON.stringify(res);
  //         let resJSON = JSON.parse(resSTR);
  //         if (resJSON.success == true) {
  //             this.newsletterApiValue = '1';
  //             this.apiMsg = resJSON.message;
  //             setTimeout(() => {
  //                 this.apiMsg = '';
  //             }, 4000);
  //         }
  //         else {
  //             this.apiMsg = resJSON.message;
  //         }
  //     });
  // }
  characterValidation(infoObj: any) {
    if (infoObj && Object.keys(infoObj).length > 0) {
      this.addMessageIfChanged(infoObj);
    }
  }
  addMessageIfChanged(currentTypeValue: any) {
    let textCharacter = this.languageData.web.character_limit_title.replace('%d', currentTypeValue.maximumLength);
    const inputLength: any = currentTypeValue.value2.length;
    if (inputLength == currentTypeValue.maximumLength) {
      this.errorMessage.currentValue = currentTypeValue.type;
      this.errorMessage.msg = textCharacter;
      this.lastTypeValue = currentTypeValue.type;
      setTimeout(() => {
        this.errorMessage.msg = '';
      }, 2000);
    }
    else if (currentTypeValue.type == this.lastTypeValue) {
      this.errorMessage.currentValue = currentTypeValue.type;
      // return;
    }

  }
  newsletterSubscribe() {
    // this.user.newsletter = true;
    let email = this.oldEmail;
    var type = "subscribe"
    // this.isLoadingData = true;
    let title = this.languageData.web.myprofile_nl_conf_popup_title;
    let subTitle = this.languageData.web.myprofile_nl_conf_popup_message;
    // let cancelBtn = this.languageData.web.;
    let okBtn = this.languageData.web.myprofile_nl_conf_popup_cta_ok;
    // const modalRef = this.modalService.open(DynamicPopupsModalComponent, { size: 'md', windowClass: 'modalWindowWidth', centered: true, backdrop: 'static', keyboard: false });
    // modalRef.componentInstance.data = {
    //   title: title, subTitle: subTitle, okBtnTxt: okBtn, modalName: 'nlSubscribe', cancelIcon: false,
    //   albmData: this.albmData
    // };
    // modalRef.componentInstance.newletterSubscribe.subscribe((res) => {
    // if (res) {
    this.dataService.newsletterSubscribe(email, type).subscribe((res: Response) => {
      let resSTR = JSON.stringify(res);
      let resJSON = JSON.parse(resSTR);
      if (resJSON.success == true) {
        this.newsletterApiValue = '1';
        this.apiMsg = resJSON.message;
        let currentUserInfo = JSON.parse(localStorage.getItem('userInfo'));
        currentUserInfo['Newsletter'] = '1';
        localStorage.setItem('userInfo', JSON.stringify(currentUserInfo));
        this.contentfulservice.emitNewsletterUpdate('1');
        setTimeout(() => {
          this.apiMsg = '';
        }, 4000);

        const modalRef = this.modalService.open(DynamicPopupsModalComponent, { size: 'md', windowClass: 'modalWindowWidth', centered: true, backdrop: 'static', keyboard: false });
        modalRef.componentInstance.data = {
          title: title, subTitle: subTitle, okBtnTxt: okBtn, modalName: 'nlSubscribe', cancelIcon: false,
          albmData: this.albmData
        };
      }
      else if (resJSON.success == false) {
        subTitle = '';
        subTitle = this.languageData.web.myprofile_nl_missing_popup_message;
        const modalRef = this.modalService.open(DynamicPopupsModalComponent, { size: 'md', windowClass: 'modalWindowWidth', centered: true, backdrop: 'static', keyboard: false });
        modalRef.componentInstance.data = {
          title: title, subTitle: subTitle, okBtnTxt: okBtn, modalName: 'nlSubscribeError', cancelIcon: false,
          albmData: this.albmData
        };
      }
      else {
        this.apiMsg = resJSON.message;
      }
    });
    // }
    // })


  }
  getCountry(countryName, countryCode) {
    for (var i = 0; i < this.arrCountries.length; i++) {
      if (this.arrCountries[i].countryName == countryName || this.arrCountries[i].countryCode == countryCode) {
        return this.arrCountries[i];
      }
    }
  }



  countPostalCode(newVal) {
    this.isPhoneNoInputDisabled = false
    if (this.charMaxLength == 0 && this.charMinLength == 0) {
      this.user.zip = '';
      this.charLenIncorrect = false;
      return;
    } else {
      if (newVal != undefined) {
        if ((newVal.length == this.charMaxLength) || (newVal.length == this.charMinLength)) {
          this.user.zip = newVal;
          this.charLenIncorrect = false
        }
        else {
          this.charLenIncorrect = true;
        }
      }
      else {
        this.charLenIncorrect = true;
      }
    }
  }


  changeCountryAndSetExtn(country) {

    this.countryObj = this.getCountry(country, '');
    this.user.selectedCountry = this.countryObj.countryName;
    this.charMinLength = this.countryObj.MinPostalLength;
    this.charMaxLength = this.countryObj.PostalLength;
    this.user.zip = '';
    this.user.extension = this.countryObj.ISD;
    this.countPostalCode('');
  }

  getfullCalendar() {
    this.mainArrDay = [];
    this.mainArrDay.push(this.languageData.web.register_field_label_dd);
    for (var k = 0; k < this.arrDay.length; k++) {
      this.mainArrDay.push(this.arrDay[k]);
    }
    this.mainArrMonth = [];
    this.mainArrMonth.push(this.languageData.web.register_field_label_mm);
    for (var j = 0; j < this.arrMonth.length; j++) {
      this.mainArrMonth.push(this.arrMonth[j]);
    }
    this.mainArrYear = [];
    this.mainArrYear.push(this.languageData.web.register_field_label_yyyy);
    for (var l = 0; l < this.arrYear.length; l++) {
      this.mainArrYear.push(this.arrYear[l]);
    }
  }

  getDMY(start, limit, isYear) {
    if (isYear == false) {
      var range = [];
      range.push(start);
      for (var i = 1; i < limit; i++) {
        range.push(start + i);
      }
      return range;
    }
    else {
      var range = [];
      range.push(start);
      for (var i = 1; i < limit; i++) {
        range.push(start - i);
      }
      return range;
    }
  }

  isLeapYear(selectedYear) {
    return ((selectedYear % 400 === 0 || selectedYear % 100 !== 0) && (selectedYear % 4 === 0)) ? 1 : 0;
  }

  ageLimit(day, month, year, ageLimitValue) {
    var d = new Date();
    if (month > d.getMonth() + 1 && year >= d.getFullYear() - ageLimitValue) {
      this.ageLimitExceed = true;
    } else {
      this.ageLimitExceed = false;
    }

  }

  UpdateNumberOfDays(val) {
    this.showDateSelect = true
    this.isValidDate = false;
    this.selectday = true;
    this.selectedDay = val;
    var days31 = [1, 3, 5, 7, 8, 10, 12];
    if (this.selectedMonth == 2) {
      this.resetDay(val)
    }
    if (this.selectedMonth == 2) {
      if (this.selectedDay == 29) {
        if ((this.selectedYear % 400 === 0 || this.selectedYear % 100 !== 0) && (this.selectedYear % 4 === 0)) {
        }
        else {
          this.selectedDay = {}
        }
      }
    }
    if (this.isLeapYear(this.user.selectedYear) && this.user.selectedMonth == 2) {
      this.arrDay = this.getDMY(1, 29, false);
    } else if (this.user.selectedMonth == 2) {
      this.arrDay = this.getDMY(1, 28, false);
    } else if (days31.indexOf(this.user.selectedMonth) != -1) {
      this.arrDay = this.getDMY(1, 31, false);
    } else if (!this.user.selectedMonth && !this.user.selectedYear) {
      this.arrDay = this.getDMY(1, 31, false);
    } else {
      this.arrDay = this.getDMY(1, 30, false);
    }
    if (this.user.selectedDay && this.user.selectedMonth && this.user.selectedYear) {
      this.ageLimit(this.user.selectedDay, this.user.selectedMonth, this.user.selectedYear, this.ageLimitValue);
    }
  }

  UpdateNumberOfMonths(val) {
    this.showMonthSelect = true;
    this.selectmonth = true;
    this.selectedMonth = val;
    var days31 = [1, 3, 5, 7, 8, 10, 12];

    if (this.selectedMonth == 2) {
      this.resetDay(this.selectedMonth);
    }

    if (this.isLeapYear(this.user.selectedYear) && this.user.selectedMonth == 2) {
      this.arrDay = this.getDMY(1, 29, false);
    } else if (this.user.selectedMonth == 2) {
      this.arrDay = this.getDMY(1, 28, false);
    } else if (days31.indexOf(this.user.selectedMonth) != -1) {
      this.arrDay = this.getDMY(1, 31, false);
    } else if (!this.user.selectedMonth && !this.user.selectedYear) {
      this.arrDay = this.getDMY(1, 31, false);
    } else {
      this.arrDay = this.getDMY(1, 30, false);
    }
    if (this.user.selectedDay && this.user.selectedMonth && this.user.selectedYear) {
      this.ageLimit(this.user.selectedDay, this.user.selectedMonth, this.user.selectedYear, this.ageLimitValue);
    }
  }

  resetDay(val) {
    if (this.selectedDay == 30 || this.selectedDay == 31) {
      this.selectedDay = {}

    }
  }

  UpdateNumberOfYears(val) {
    this.showYearSelect = true;
    this.selectyear = true
    this.selectedYear = val
    var days31 = [1, 3, 5, 7, 8, 10, 12];

    if (this.selectedMonth == 2) {
      if (this.selectedDay == 29) {
        if ((this.selectedYear % 400 === 0 || this.selectedYear % 100 !== 0) && (this.selectedYear % 4 === 0)) {
        }
        else {
          this.selectedDay = {}
        }
      }
    }
    if (this.isLeapYear(this.user.selectedYear) && this.user.selectedMonth == 2) {
      this.arrDay = this.getDMY(1, 29, false);
    } else if (this.user.selectedMonth == 2) {
      this.arrDay = this.getDMY(1, 28, false);
    } else if (days31.indexOf(this.user.selectedMonth) != -1) {
      this.arrDay = this.getDMY(1, 31, false);
    } else if (!this.user.selectedMonth && !this.user.selectedYear) {
      this.arrDay = this.getDMY(1, 31, false);
    } else {
      this.arrDay = this.getDMY(1, 30, false);
    }
    if (this.user.selectedDay && this.user.selectedMonth && this.user.selectedYear) {
      this.ageLimit(this.user.selectedDay, this.user.selectedMonth, this.user.selectedYear, this.ageLimitValue);
    }
  }
  openConfModal() {
    var myprofileForm = this.myprofileForm.form;
    let selectedMonth, selectedDay, selectedYear;
    this.selectedDay;
    this.selectedMonth;
    this.selectedYear;
    if (myprofileForm.invalid == true) {
      if (myprofileForm.value.email == '') {
        this.emailReq = true;
        this.emailInvalid = false;
      }
      else if (myprofileForm.value.email == undefined) {
        this.emailReq = false;
        this.emailInvalid = true;
      }
      else {
        this.emailReq = false;
        this.emailInvalid = false;
      }
      return;
    }

    this.emailReq = false;
    this.emailInvalid = false;
    let phoneNum = myprofileForm.value.phoneNo;
    let extension = myprofileForm.value.extension;

    myprofileForm.value.extension = extension == undefined ? "" : extension;
    myprofileForm.value.phoneNo = phoneNum == undefined ? "" : phoneNum;

    if (this.user.newPassword === this.user.matchPassword) {
      this.matchPassword = false;
    } else {
      this.matchPassword = true;
      return false;
    }

    if (this.selectedMonth <= 9) {
      selectedMonth = '0' + this.selectedMonth
    }
    else if (myprofileForm.value.selectedMonth != 'MM') {
      selectedMonth = this.selectedMonth;
    }
    else {
      selectedMonth = '00';
    }

    if (this.selectedDay <= 9) {
      selectedDay = '0' + this.selectedDay
    }
    else if (this.selectedDay != 'DD') {
      selectedDay = this.selectedDay;
    }
    else {
      selectedDay = '00';
    }

    if (this.selectedYear == 'YYYY') {
      selectedYear = '00';
    }
    else {
      selectedYear = this.selectedYear
    }

    this.user.dob = (selectedYear || this.selectedYear) + '-' + (selectedMonth || this.selectedMonth) + '-' + (selectedDay || this.selectedDay);

    if (myprofileForm.value.extension != undefined && myprofileForm.value.extension.length > 0) {
      if (myprofileForm.value.phoneNo != undefined && myprofileForm.value.phoneNo.length > 0) {
        this.user.phone = myprofileForm.value.extension + '-' + myprofileForm.value.phoneNo;
      }
      else {
        this.user.phone = ""
      }
    }
    else if (myprofileForm.value.phoneNo != undefined && myprofileForm.value.phoneNo.length > 0) {
      if (myprofileForm.value.extension != undefined && myprofileForm.value.extension.length > 0) {
        this.user.phone = myprofileForm.value.myprofileForm + '-' + myprofileForm.value.phoneNo;
      }
      else {
        this.user.phone = this.languageData.web.register_country_code + '-' + myprofileForm.value.phoneNo;
      }
    }
    else {
      this.user.phone = ""
    }
    this.confirmSubmitModal();
  }

  confirmSubmitModal() {
    let title = this.languageData.web.myprofile_profile_update_conf_popup_tit;
    let subTitle = this.languageData.web.my_profile_update_confirmation_msg;
    let cancelBtn = this.languageData.web.my_profile_update_confirmation_cta_no;
    let okBtn = this.languageData.web.my_profile_update_confirmation_cta_yes;
    const modalRef = this.modalService.open(DynamicPopupsModalComponent, { size: 'md', windowClass: 'modalWindowWidth', centered: true, backdrop: 'static', keyboard: false });
    modalRef.componentInstance.data = {
      title: title, subTitle: subTitle, cancelBtnTxt: cancelBtn, okBtnTxt: okBtn, modalName: 'updateProfile', cancelIcon: false,
    };
    modalRef.componentInstance.updateProfileData.subscribe((res) => {
      if (res) {
        this.uploadProfile()
      }
    })
  }

  checkPassword(inputField) {
    if (inputField == 1) {

      this.showIcon2 = true;
      if (this.user.newPassword != undefined && this.user.oldPassword != undefined) {
        if (this.user.newPassword === this.user.oldPassword) {
          this.isPasswordMatched = true;
          this.isPasswordWrong = false;
        }
        else {
          this.isPasswordMatched = false;
          this.isPasswordSame = false;
          this.isPasswordWrong = false;

          if (this.user.newPassword.length > 0) {
            let str = this.user.newPassword;
            if (str !== undefined) {
              if (str.length < 8) {
                this.invalidPassword1 = true;
              }
              if (str.search(/\d/) == -1) {
                this.invalidPassword1 = true;
              }
              if (str.search(/[A-Z]+/) == -1) {
                this.invalidPassword1 = true;
              }

              if (!(str.search(/\&/) == -1)) {
                this.invalidPassword1 = false;
              }

              if (!(str.search(/\%/) == -1)) {
                this.invalidPassword1 = false;
              }
              if (!(str.search(/\s/) == -1)) {
                this.invalidPassword1 = true;
              }
            }
            else {
              this.invalidPassword1 = false;
              this.user.newPassword = '';
            }
          }
          else {
            this.invalidPassword1 = false;
          }
        }
      }
    }
    else if (inputField === 'oldpassword') {
      if (this.user.newPassword != undefined && this.user.oldPassword != undefined) {
        if (this.user.newPassword === this.user.oldPassword) {
          this.isPasswordMatched = true;
          this.isPasswordWrong = false;
        }
        else {
          this.isPasswordMatched = false;
          this.isPasswordSame = false;
          this.isPasswordWrong = false;
        }
      }
    }
    else {
      if (this.user.matchPassword != undefined) {
        if (this.user.matchPassword.length > 0) {
          if (this.user.newPassword != undefined && this.user.newPassword.length > 0) {
            if ((this.user.newPassword == this.user.matchPassword) && this.invalidPassword1 == false) {
              this.invalidPassword2 = false;
              this.invalidPassword1 = false;
            }
            else if ((this.user.newPassword !== this.user.matchPassword) && this.invalidPassword1 == false) {
              this.invalidPassword2 = true;
              this.invalidPassword1 = false;
              this.showPasswordButton = true
            }
            else {
              this.invalidPassword2 = true;
              this.invalidPassword1 = true;

            }
          }
        }
        else {
          if (this.invalidPassword1 == false) {
            this.invalidPassword2 = false;
            this.invalidPassword1 = false;
          }
        }
      }
      else {
        if (this.invalidPassword1 == false) {
          this.invalidPassword2 = false;
          this.invalidPassword1 = false;
        }
      }
    }
  }
  checkFirstName() {
    this.isPhoneNoInputDisabled = false
  }
  checkLastName() {
    this.isPhoneNoInputDisabled = false
  }
  checkStreet(val) {
    this.isPhoneNoInputDisabled = false
    if (val == '' || val == undefined) {
      return;
    }
    let streetVal = val.replace(/\s/g, "");
    streetVal = streetVal.toLowerCase();
    if (this.user.houseNumber) {
      let houseVal = this.user.houseNumber.replace(/\s/g, "");
      houseVal = houseVal.toLowerCase();

      if (houseVal.length > 0) {
        if (houseVal == streetVal) {
          this.streetError = true;
        }
        else {
          this.streetError = false;
        }
      }
      else {
        this.streetError = false;
      }
    }
    else {
      this.streetError = false;
    }
  }

  checkHouse(val) {
    this.isPhoneNoInputDisabled = false
    var houseVal = val.replace(/\s/g, "");
    houseVal = houseVal.toLowerCase();
    if (this.user.street) {
      var streetVal = this.user.street.replace(/\s/g, "");
      streetVal = streetVal.toLowerCase();

      if (streetVal.length > 0) {
        if (streetVal == houseVal) {
          this.streetError = true;
        }
        else {
          this.streetError = false;
        }
      }
      else {
        this.streetError = false;
      }
    }
    else {
      this.streetError = false;
    }
  }
  checkCity() {
    this.isPhoneNoInputDisabled = false
  }
  checkPhoneNumber() {
    this.isPhoneNoInputDisabled = false
  }
  closeConfirmModal() {
    // $("#profilePic").val('');
    this.isUpdateProcessing = false;
    this.phoneLenIncorrect = false;
    // this.fetchProfileData();
    // this.profileData2 = JSON.parse(JSON.stringify(this.profileData1));
    // this.user = this.profileData2;
  }

  checkEmail(email) {
    this.showAccountInformatio = true;
    this.isValidEmail = false;
    this.emailVerification = true;
  }
  dateOfBirth() {
    var changeDateOfBirthForm = this.changeDateOfBirthForm.form;
    let selectedMonth, selectedDay, selectedYear;
    if (changeDateOfBirthForm.invalid == true) {
      if (changeDateOfBirthForm.value.email == '') {
        this.emailReq = true;
        this.emailInvalid = false;
      }
      else if (changeDateOfBirthForm.value.email == undefined) {
        this.emailReq = false;
        this.emailInvalid = true;
      }
      else {
        this.emailReq = false;
        this.emailInvalid = false;
      }
      return;
    }
    else if (this.showEditIcon == true) {
      changeDateOfBirthForm.value.date = this.selectedDay;
    } else {
      changeDateOfBirthForm.value.date = this.selectedDay;

      // if(this.selectedDay<10){
      //   changeDateOfBirthForm.value.date=this.selectedDay

      // }
      // if(this.selectedMonth<10){
      //   changeDateOfBirthForm.value.month=this.selectedMonth

      // }
      changeDateOfBirthForm.value.month = this.selectedMonth
      changeDateOfBirthForm.value.year = this.selectedYear;
    }
    // 
    if (Object.keys(changeDateOfBirthForm.value.date).length === 0 && changeDateOfBirthForm.value.date.constructor === Object) {
      this.isValidDate = true;
      return false;
    }
    this.emailReq = false;
    this.emailInvalid = false;
    this.isNameInputDisabled = true;

    if (changeDateOfBirthForm.value.month <= 9) {
      selectedMonth = '0' + changeDateOfBirthForm.value.month
    }
    else if (changeDateOfBirthForm.value.selectedMonth != 'MM') {
      selectedMonth = changeDateOfBirthForm.value.month;
    }
    else {
      selectedMonth = '00';
    }

    if (changeDateOfBirthForm.value.date <= 9) {
      selectedDay = '0' + changeDateOfBirthForm.value.date
    }
    else if (changeDateOfBirthForm.value.date != 'DD') {
      selectedDay = changeDateOfBirthForm.value.date;
    }
    else {
      selectedDay = '00';
    }

    if (changeDateOfBirthForm.value.year == 'YYYY') {
      selectedYear = '00';
    }
    else {
      selectedYear = changeDateOfBirthForm.value.year
    }

    this.user.dob = (selectedYear || changeDateOfBirthForm.value.year) + '-' + (selectedMonth || changeDateOfBirthForm.value.month) + '-' + (selectedDay || changeDateOfBirthForm.value.date);
    if (this.showEditIcon == true) {
      this.user.dob = (selectedYear || this.selectedYear) + '-' + (selectedMonth || this.selectedMonth) + '-' + (selectedDay || this.selectedDay);
    }
    if (changeDateOfBirthForm.value.extension != undefined && changeDateOfBirthForm.value.extension.length > 0) {
      if (changeDateOfBirthForm.value.phoneNo != undefined && changeDateOfBirthForm.value.phoneNo.length > 0) {
        this.user.phone = changeDateOfBirthForm.value.extension + '-' + changeDateOfBirthForm.value.phoneNo;
      }
      else {
        this.user.phone = ""
      }
    }
    if (this.emailVerification) {
      this.dataService.checkValidEmailOnly(changeDateOfBirthForm.value.email).subscribe((res: Response) => {
        let resSTR = JSON.stringify(res);
        let resJSON = JSON.parse(resSTR);
        if (resJSON.success == true) {
          this.isValidEmail = true;
          return false;
        }
        else {
          this.isValidEmail = false;
          this.confirmSubmit();
        }
      })
    }
    else {
      this.confirmSubmit();
    }


  }

  confirmSubmit() {
    this.showAccountInformatio = false;
    this.showDateSelect = false;
    this.showMonthSelect = false;
    this.showYearSelect = false;
    let title = this.languageData.web.myprofile_profile_update_conf_popup_tit;
    let subTitle = this.languageData.web.my_profile_update_confirmation_msg;
    let cancelBtn = this.languageData.web.my_profile_update_confirmation_cta_no;
    let okBtn = this.languageData.web.my_profile_update_confirmation_cta_yes;
    const modalRef = this.modalService.open(DynamicPopupsModalComponent, { size: 'md', windowClass: 'modalWindowWidth', centered: true, backdrop: 'static', keyboard: false });
    modalRef.componentInstance.data = {
      title: title, subTitle: subTitle, cancelBtnTxt: cancelBtn, okBtnTxt: okBtn, modalName: 'updateProfile', cancelIcon: false,
    };
    modalRef.componentInstance.updateProfileData.subscribe((res) => {
      if (res) {
        this.uploadProfile();


      }
    })

  }


  updateEmail(newEm, oldEm) {
    this.displayVerifyEmailLink = false;
    this.dataService.updateUserEmail(newEm, oldEm).subscribe((res: Response) => {

      let resSTR = JSON.stringify(res);
      let resJSON = JSON.parse(resSTR);
      this.emailSendMsg = resJSON.message;

      if (resJSON.code == 1) {
        this.emailUpdateSuccess = true;
        this.emailAlreadyExists = false;
        this.emailSendingFail = false;
        // this.fetchProfileData();
        this.displayResendVerifyEmailLink = false
      }
      else if (resJSON.code == 2) {
        this.emailUpdateSuccess = false;
        this.emailAlreadyExists = true;
        this.emailSendingFail = false;
      }
      else {
        this.emailSendingFail = true;
        this.emailUpdateSuccess = false;
        this.emailAlreadyExists = false;
      }
    })

  }

  finalChanges() {
    let countryObj = this.getCountry(this.user.selectedCountry, '');
    this.user.selectedCountryCode = countryObj.countryCode;

    this.user.newsletter = this.newsletterApiValue;

    let phoneExtension = this.addressList.billingDetail.phoneExtension;//this.user.extension;
    let phoneNumber = this.addressList.billingDetail.phoneNo//this.user.phoneNo;

    if (phoneExtension != undefined && phoneExtension.length > 0) {
      if (phoneNumber != undefined && phoneNumber.length > 0) {
        this.user.phone = phoneExtension + '-' + phoneNumber;
      }
      else {
        this.user.phone = ""
      }
    }
    else if (phoneNumber != undefined && phoneNumber.length > 0) {
      if (phoneExtension != undefined && phoneExtension.length > 0) {
        this.user.phone = phoneExtension + '-' + phoneNumber;
      }
      else {

        // this.user.phone = this.languageData['register_country_code']+'-'+this.shippingForm.phoneNo.$viewValue;
      }
    }
    else {
      this.user.phone = ""
    }
    this.user['from'] = 'profile';



    this.dataService.updateProfileData(this.user).subscribe((res: Response) => {
      let resSTR = JSON.stringify(res);
      let resJSON = JSON.parse(resSTR);
      //this.submitDisabled=true;
      if (resJSON.success == true) {
        // var updatedProfileImage = resJSON.image;
        this.isUpdateProcessing = false;
        this.updateSuccessMsg = resJSON.message;

        var jsonString = localStorage.getItem('userInfo');
        var userDetail = JSON.parse(jsonString);
        userDetail.Email = this.oldEmail;
        this.user.userName = this.oldEmail;
        userDetail.Title = this.user.title;
        userDetail.subTitle = this.user.subtitle;
        userDetail.FirstName = this.user.firstName;
        userDetail.LastName = this.user.lastName;
        userDetail.HouseNumber = this.user.houseNumber;
        userDetail.Street = this.user.street;
        userDetail.Phone = this.user.phone;
        userDetail.Zipcode = this.user.zip;
        userDetail.City = this.user.city;
        userDetail.Dateofbirth = this.user.dob;
        userDetail.Gender = this.user.gender;

        localStorage.setItem('userInfo', JSON.stringify(userDetail));

        this.submitted = true;
        this.disableValue1 = this.submitted && (this.selectedDay !== this.languageData.web.register_field_label_dd);
        this.disableValue2 = this.submitted && (this.selectedDay !== this.languageData.web.register_field_label_mm);
        this.disableValue3 = this.submitted && (this.selectedDay !== this.languageData.web.register_field_label_yyyy);

        if (this.disableValue1) {
          this.disableValue2 = true;
          this.disableValue3 = true;
          this.emailUpdateSuccess = true;
        } else {
          this.disableValue2 = false;
          this.disableValue3 = false;
        }

        this.isUpdateProcessing = false;
        this.isformDisabled = true;
        this.isPhoneNoInputDisabled = true;
        this.isNameInputDisabled = true;

        setTimeout(() => {
          this.updateSuccessMsg = false;
        }, 8000);
        this.fetchProfileData();
      }
      else {
        this.updateSuccessMsg = false;
        this.fetchProfileData();
      }
    })
  }



  uploadProfile() {
    this.isPhoneNoInputDisabled = true
    this.isUpdateProcessing = true;
    if (this.userProfile.userEmail === this.user.userName) {
    }
    else {
      this.updateEmail(this.user.userName, this.userProfile.userEmail)
    }
    this.finalChanges();
  }

  resetPassword() {

    this.showPasswordButton = false
    if (this.changePasswordForm.invalid) {
      this.showError = true;
      return;
    }
    this.showError = false;

    this.checkPassword(1);
    this.checkPassword(2);

    if (this.isPasswordMatched) {
      return;
    }
    let title = this.languageData.web.myprofile_pass_conf_popup_title;
    let subTitle = this.languageData.web.myprofile_pass_conf_popup_msg;
    let cancelBtn = this.languageData.web.myprofile_pass_conf_popup_cta_cancel;
    let okBtn = this.languageData.web.myprofile_pass_conf_popup_cta_ok;

    const modalRef = this.modalService.open(DynamicPopupsModalComponent, { size: 'md', windowClass: 'modalWindowWidth', centered: true, backdrop: 'static', keyboard: false });
    modalRef.componentInstance.data = {
      title: title, subTitle: subTitle, okBtnTxt: okBtn, cancelBtnTxt: cancelBtn, modalName: 'resetPass', cancelIcon: false,
      albmData: this.albmData
    };
    modalRef.componentInstance.updatePassword.subscribe((res) => {
      if (res) {

        if (this.user.newPassword == this.user.matchPassword && this.invalidPassword1 == false && this.invalidPassword2 == false) {

          this.dataService.resetOTPPassword('', this.user.newPassword, this.user.matchPassword, '', this.user.userName, this.user.oldPassword, undefined).subscribe((res: Response) => {
            let resSTR = JSON.stringify(res);
            let resJSON = JSON.parse(resSTR);
            if (resJSON.success == true) {
              this.resetPasswordSuccess = true;
              this.changePasswordForm.reset();
              this.isPasswordMatched = false;
              this.message = resJSON.message;
              this.isDisplayPasswordSection = false;

              let subTitle = resJSON.message;
              let title = this.languageData.web.myprofile_pass_confirm_popup_title;
              let okBtn = this.languageData.web.myprofile_pass_confirm_popup_cta_ok;
              const modalRef = this.modalService.open(DynamicPopupsModalComponent, { size: 'md', windowClass: 'modalWindowWidth', centered: true, backdrop: 'static', keyboard: false });
              modalRef.componentInstance.data = {
                title: title, subTitle: subTitle, okBtnTxt: okBtn, modalName: 'confirmPassMsg', cancelIcon: false,
                albmData: this.albmData
              };
              // modalRef.componentInstance.confirmPasswordMsg.subscribe((res) => {
              //     if (res) {

              //     }
              // })

            } else {

              this.resetPasswordSuccess = false;
              if (resJSON.hasOwnProperty('message')) {
                if (resJSON.message.key == 'api_res_val_msg6') {
                  this.message = resJSON.message;
                }
                else {
                  this.isPasswordWrong = true;
                }
              }
              else if (resJSON.error.detail == 'api_res_val_msg7') {
                this.changePasswordForm.form.value.matchPassword = '';
                this.changePasswordForm.form.value.newPassword = '';
                this.isPasswordWrong = true;
              }
              else {
                this.isPasswordWrong = true;
              }
            }
          })
        }
      }
      else {
        this.checkPassword(0);
        this.resetPasswordSuccess = false;
        this.message = ''
      }
    })

  }

  passFormat(val) {
    if (val == 1) {
      this.invalidPassword1 = false;
      this.showIcon = true;
      var str = this.user.newPassword || "";
      if (str.length > 0) {
        if (str.length < 8) {
          this.invalidPassword1 = true;
        }
        if (str.search(/\d/) == -1) {
          this.invalidPassword1 = true;
        }
        if (str.search(/[A-Z]+/) == -1) {
          this.invalidPassword1 = true;
        }
        if (!(str.search(/\&/) == -1)) {
          this.invalidPassword1 = true;
        }
        if (!(str.search(/\%/) == -1)) {
          this.invalidPassword1 = true;
        }
        if (!(str.search(/\s/) == -1)) {
          this.invalidPassword1 = true;
        }
      }
      else {
        this.invalidPassword1 = false;
        this.user.newPassword = '';
        this.user.newPassword = '';
      }
    }
    else {
      if ((this.user.newPassword == undefined) && (this.user.matchPassword.length > 0)) {
        this.invalidPassword2 = true;
        this.invalidPassword1 = true;
      }
      else if (this.user.matchPassword != undefined) {
        if (this.user.matchPassword.length == 0) {
          this.invalidPassword2 = false;
          this.invalidPassword1 = false;
        }
      }
    }
  }



  ngOnDestroy() {
    // unsubscribe to ensure no memory leaks
    if (this.languageDataSubscription) {
      this.languageDataSubscription.unsubscribe();
    }

  }

  cancleSave(profileOrPassword) {
    if (profileOrPassword == 1) {

      this.charLenIncorrect = false;
      this.phoneLenIncorrect = false;

      this.fetchProfileData();
      this.profileData2 = JSON.parse(JSON.stringify(this.profileData1));
      this.user = this.profileData2;

    }
    else {

      // this.changePasswordForm.submitted = false;
      this.showError = false;
      this.user.oldPassword = undefined;
      this.user.newPassword = undefined;
      this.user.matchPassword = undefined;
      this.isPasswordWrong = false;
      this.isPasswordMatched = false;
      this.isPasswordMatched = false;
      this.isPasswordSame = false;
      this.matchPassword = false;
      this.invalidPassword1 = false;
      this.invalidPassword2 = false;
      this.isDisplayPasswordSection = false;
      // $('.heading_wrap').find('i').show();
    }

  }

  changePassword() {
    this.isDisplayPasswordSection = !this.isDisplayPasswordSection;
  }

  focus() {
    this.showEditIcon = true;
    this.showAccountInformatio = true
    this.isEmailInputDisabled = false;
    setTimeout(() => {
      this.onEdit.nativeElement.focus();
    }, 100);

  }
  editEmail() {
    this.user.userName = this.onEdit.nativeElement.value;
    this.isEmailInputDisabled = true;
  }
  focusPh() {
    this.isPhoneNoInputDisabled = false;
    this.onPhoneNoEdit.nativeElement.focus();
  }

  editName() {
    this.isNameInputDisabled = false;
    setTimeout(() => {
      document.getElementById('renameInputField').focus();
    }, 1000)
  }

  editPhoneNo(phone) {
    if (phone.control.status == 'INVALID') {
      return;
    }
    this.user.phoneNo = this.onPhoneNoEdit.nativeElement.value;
    this.isPhoneNoInputDisabled = true;

  }
  forgotPwd() {
    // this.closeModal();
    // let data = {type:"OPEN_FORGOTPASSWORD"};
    // this.globalService.setOpenForgotPassword(data)
    this.forgetPassword();
  }

  forgetPassword() {

    let loginUrl = 'reset-password';
    let email = this.userProfile.userEmail;

    this.dataService.forgetPassword(email, loginUrl).subscribe((res: Response) => {
      let resSTR = JSON.stringify(res);
      let resJSON = JSON.parse(resSTR);
      if (resJSON.success == true) {

        let msg = resJSON.message.toLowerCase();
        this.succesMessage = this.languageData.web[msg];
        setTimeout(() => {
          this.succesMessage = '';
        }, 4000);

      } else {
        // this.forgotPassLoader = false;
        // this.resetPassLink = false;
        // this.emailNotExist = true;
        // this.email = '';
      }
    });

  }

  verifyEmail() {
    let email = this.userProfile.userEmail;
    if (!email) {
      return;
    }
    this.dataService.resendVerificationMail(email).subscribe((res: Response) => {
      let resSTR = JSON.stringify(res);
      let resJSON = JSON.parse(resSTR);
      this.showMsgFlag = true;
      // let msgkey = resJSON.message;
      // this.confirmationMsg = this.languageData.web[msgkey];
      this.confirmationMsg = resJSON.message;
      if (resJSON.success == true) {
        this.styleClassApiMsg.color = "green";
        this.styleClassApiMsg.padding = "10px";

      } else {
        this.styleClassApiMsg.color = "red";
      }

      setTimeout(() => {
        this.confirmationMsg = '';
        this.showMsgFlag = false;
      }, 5000);
    })
  }

  @HostListener('window: click', ['$event']) // for window scroll events
  onScroll(event) {
    if (event.keyCode == 9 || event.keyCode == 13) {

    }
    else {
      this.resetPasswordSuccess = false;
      this.isPasswordWrong = false;
      this.isPasswordMatched = false;
      this.invalidPassword1 = false;
      this.invalidPassword2 = false;
    }
  }


  resendVerificationEmail() {
    let email = this.userProfile.userEmail;
    if (!email) {
      return;
    }
    let title = this.languageData.web.myprofile_profile_resend_verif_email_conf_popup_tit;
    let subTitle = this.confirmationMsg;
    let okBtn = this.languageData.web.myprofile_profile_resend_verif_email_conf_popup_cta_ok;
    const modalRef = this.modalService.open(DynamicPopupsModalComponent, { size: 'md', windowClass: 'modalWindowWidth', centered: true, backdrop: 'static', keyboard: false });
    modalRef.componentInstance.data = {
      title: title, subTitle: subTitle, okBtnTxt: okBtn, modalName: 'resendEmailVerification', cancelIcon: false,
      albmData: this.albmData
    };

    modalRef.componentInstance.resendEmail.subscribe((res) => {
      if (res) {
        // this.dataService.resendVerificationMail(email).subscribe((res: Response) => {
        //     let resSTR = JSON.stringify(res);
        //     let resJSON = JSON.parse(resSTR);
        //     this.showMsgFlag = true;

        //     this.confirmationMsg = resJSON.message;
        //     if (resJSON.success == true) {
        //         this.styleClassApiMsg.color = "green";
        //         this.styleClassApiMsg.padding = "10px";
        //     } else {
        //         this.styleClassApiMsg.color = "red";
        //     }
        // })
        this.dataService.resendVerificationMail(email).subscribe((res: Response) => {
          let resSTR = JSON.stringify(res);
          let resJSON = JSON.parse(resSTR);
          this.showMsgFlag = true;
          // let msgkey = resJSON.message;
          // this.confirmationMsg = this.languageData.web[msgkey];
          this.confirmationMsg = resJSON.message;
          if (resJSON.success == true) {
            this.styleClassApiMsg.color = "green";
            this.styleClassApiMsg.padding = "10px";

          } else {
            this.styleClassApiMsg.color = "red";
          }

          setTimeout(() => {
            this.confirmationMsg = '';
            this.showMsgFlag = false;
          }, 5000);
        })
      }
    })
  }


  getData(listShared = false) {
    let isShared;
    if (listShared) {
      isShared = 'Yes'
    }
    else {
      isShared = 'No'
    }

    this.dataService.getMyProjectList(0, 500, "0", isShared).pipe(takeUntil(this.destroyed$)).subscribe((res: Response) => {
      let resSTR = JSON.stringify(res);
      let resJSON = JSON.parse(resSTR);

      if (resJSON.success == true) {
        this.albmData = [];
        if (resJSON.data.length != 0) {
          for (let i = 0; i < resJSON.data.length; i++) {
            let projectPreview = resJSON.data[i].preview;

            resJSON.data[i].isDisplayInputField = false;
            if (projectPreview == 'PHOTOBOOK' || projectPreview == 'DESKCALENDAR' || projectPreview == 'BILET'
              || projectPreview == 'YEARCALENDAR' || projectPreview == 'WALLCALENDAR'
              || projectPreview == 'CARD-SINGLE' || projectPreview == 'BIZCARD') {
              resJSON.data[i].isAddShadowClass = true;
            }
            if (projectPreview == "ALBUMBOOK" || projectPreview == "ALBUMBOOK-PRO-LINE" || projectPreview == "MINIBOOK" || projectPreview == "PHOTOBOOK"
              || projectPreview == "PHOTOBOOK-" || projectPreview == "SOFTBOOK" || projectPreview == "SOFTBOOK-" || projectPreview == "RINGBOOK" || projectPreview == "WALLCALENDAR" || projectPreview == "DESKCALENDAR") {
              resJSON.data[i].isDisplayEditIcon = true;
              this.albmData.push(resJSON.data[i]);
              // this.productCount = this.albmData.length
              this.totalProductNo = this.albmData.length


            }
          }
          localStorage.setItem('totalProductCount', this.totalProductNo);
          this.productCount = this.totalProductNo;

          // if(isShared=='Yes'){
          //   
          //   localStorage.setItem('totalSharedProjectCount', this.totalProductNo);
          //   this.sharedProjectCount = this.totalProductNo;

          // }else{
          //   localStorage.setItem('totalProductCount', this.totalProductNo);
          //   this.productCount = this.totalProductNo;
          // }

        }

      }
    })
    this.dataService.myOrders(1, 100, 'No').pipe(takeUntil(this.destroyed$)).subscribe((res: Response) => {
      let resSTR = JSON.stringify(res);
      let resJSON = JSON.parse(resSTR);
      let userInfo = JSON.parse(localStorage.getItem('userInfo'));

      if (resJSON.success == true) {
        this.totalRecord = resJSON.totalRecord;
        this.totalOrderNo = resJSON.totalRecord;
        localStorage.setItem('totalOrderCount', this.totalOrderNo);
        this.orderCount = this.totalOrderNo
      }
    }, (error) => {

    })

  }


  copyUrlLink(item: string) {
    this.showLinkButton = true
    this.clipboard.copy(item);
  }
  // For Contact form in my Profile
  // From Address Book

  getAddressBook(event, formName) {
    this.selectedAddressIndex = undefined;
    this.formName = formName;
    if (event != undefined) {
      // this.openAddressBookModal(formName);
      return;
    }

    this.dataService
      .getShippingAndBillingAddress()
      .subscribe((res: Response) => {
        let resSTR = JSON.stringify(res);
        let resJSON = JSON.parse(resSTR);
        if (resJSON.billingAddressEditable) {
          this.billingAddressEditable =
            resJSON.billingAddressEditable == 'Yes' ? true : false;
        }

        if (resJSON.success == true) {
          this.addressBookData = resJSON.data;
          console.log();

          this.billingAddressData = JSON.parse(JSON.stringify(resJSON.data));
          this.arrBillingAddressBook = Object.assign(
            [],
            this.billingAddressData
          );

          this.addressBookAssignCopy();
          this.addressList.billingDetail['email'] = this.userEmail;
          if (this.addressBookData.length == 0) {
            this.addressList.billingDetail.gender = 'Female';
            this.getBillingAddressDetail();
            this.addressList.userOrderLength = 0;
          } else {
            this.getBillingAddressDetail();
            this.dataService
              .getprofileDetails(this.authId)
              .subscribe((res: Response) => {
                let resSTR = JSON.stringify(res);
                let resJSON = JSON.parse(resSTR);
                this.arrOemCountries = resJSON.oemCountries;
                this.userProfileData = resJSON.data;
                this.oemCountryObj = this.getOemCountry(
                  resJSON.data.countryName,
                  resJSON.data.Country
                );
                this.userEmail = resJSON.data.userEmail;
                let phone = resJSON.data.Phone.split('-');

                // 


                for (var i = 0; i < this.addressBookData.length; i++) {

                  if (this.addressBookData[i].IsDefaultBilling == 1) {
                    this.oemCountryObj = this.getOemCountry(
                      this.addressBookData[i].CountryName,
                      this.addressBookData[i].Country
                    );

                    let addressBookData = this.addressBookData[i];
                    let phone = addressBookData.Phone.split('-');
                    //
                    // //////

                    this.addressList.billingDetail = {
                      firstName: addressBookData.FirstName,
                      lastName: addressBookData.LastName,
                      gender: addressBookData.gender,
                      company: addressBookData.Company,
                      street: addressBookData.Address1,
                      houseNumber: addressBookData.Address2,
                      city: addressBookData.City,
                      selectedCountry: this.oemCountryObj.countryName,
                      selectedCountryCode: this.oemCountryObj.countryCode,
                      zip: addressBookData.Zip,
                      email: addressBookData.Email,
                      phone: addressBookData.Phone,
                      phoneExtension: this.oemCountryObj.ISD,
                      phoneNo: phone[1],
                      ID: addressBookData.ID,
                      IsDefaultShipping: addressBookData.IsDefaultShipping,
                      IsDefaultBilling: addressBookData.IsDefaultBilling,
                    };

                    this.getZipPatternBl(this.oemCountryObj);
                  }
                }

                if (this.countryObj == undefined) {
                  this.countryObj = this.getCountry(
                    '',
                    environment[global.env].defaultCountryCode
                  );
                  if (this.countryObj && this.countryObj.hasOwnProperty('code') && this.countryObj.hasOwnProperty('country')) {
                    this.selectedShippingCountry = {
                      code: this.countryObj.code,
                      country: this.countryObj.country,
                    };
                  }
                  // this.selectedShippingCountry = {
                  //   code: this.countryObj.code,
                  //   country: this.countryObj.country,
                  // };

                }
              });
          }
        } else {
          //  this.router.navigate(['/']);
          let data = { type: "OPEN_LOGIN" };
          // this.globalService.setOpenLogin(data);
          //  this.globalService.setOpenRegister(data);
          this.showSignUpModal({ loginType: 'recallApi' })
        }
      });
  }
  getZipPatternBl(countryObj) {

    this.addressList.billingDetail.selectedCountry = countryObj.countryName;
    this.addressList.billingDetail.phoneExtension = countryObj.ISD;
    this.addressList.billingDetail.selectedCountryCode = countryObj.countryCode;
    // 
    this.charMinLengthBl = countryObj.MinPostalLength;
    this.charMaxLengthBl = countryObj.PostalLength;

    if (countryObj.isAlphaNumeric == 'Yes') {
      this.applyPatternBl = '^[- a-zA-Z0-9]+$';
    } else if (countryObj.isAlphaNumeric == 'No') {
      this.applyPatternBl = '^[0-9]*$';
    } else {
      this.applyPatternBl = '^[0-9]*$';
    }
  }

  getOemCountry(countryName, countryCode) {
    for (var i = 0; i < this.arrOemCountries.length; i++) {
      if (
        this.arrOemCountries[i].countryName == countryName ||
        this.arrOemCountries[i].countryCode == countryCode ||
        this.arrOemCountries[i].countryName == countryCode
      ) {
        return this.arrOemCountries[i];
      }
    }
    return undefined;
  }
  selectAddress(index, fromBilling = false) {
    this.updateAddressInShippingForm(index, fromBilling);
  }

  updateAddressInShippingForm(index, fromBilling = false) {
    if (fromBilling) {
      //
      var currentAddress = this.arrBillingAddressBook[index];
      var phone = currentAddress.Phone.split('-');
      this.oemCountryObj = this.getOemCountry(
        currentAddress.CountryName,
        currentAddress.Country
      );
      this.getZipPatternBl(this.oemCountryObj);
      this.addressList.billingDetail.firstName = currentAddress.FirstName;
      this.addressList.billingDetail.lastName = currentAddress.LastName;
      this.addressList.billingDetail.gender = currentAddress.gender;
      this.addressList.billingDetail.company = currentAddress.Company;
      this.addressList.billingDetail.street = currentAddress.Address1;
      this.addressList.billingDetail.houseNumber = currentAddress.Address2;
      this.addressList.billingDetail.city = currentAddress.City;
      this.addressList.billingDetail.zip = currentAddress.Zip;
      this.addressList.billingDetail.selectedCountry =
        currentAddress.CountryName;
      this.countPostalCodeBl(this.addressList.billingDetail.zip);
      this.addressList.billingDetail.phoneNo = phone[1];
      this.addressList.billingDetail.phoneExtension = phone[0];
      this.billingAddress = this.addressList.billingDetail;
    }
  }
  countPostalCodeBl(newVal) {
    if (this.charMaxLengthBl == 0 && this.charMinLengthBl == 0) {
      this.addressList.billingDetail.zip = '';
      this.charLenIncorrectBl = false;
      this.isZipBlRequired = false;
      return;
    }
    if (newVal != undefined) {
      if ((newVal.length == this.charMinLengthBl) || (newVal.length == this.charMaxLengthBl)) {
        this.addressList.billingDetail.zip = newVal;
        this.charLenIncorrectBl = false;
        this.isZipBlRequired = false;
      }
      else {
        this.charLenIncorrectBl = true;
        this.isZipBlRequired = true;
      }
    }
    else {
      this.isZipBlRequired = true;
      this.charLenIncorrectBl = true;
    }
  }
  ///// Need
  filterAddressBookItem(value, fromBilling = false) {
    if (!value) {
      this.addressBookAssignCopy(fromBilling);
    } // when nothing has typed

    if (!fromBilling) {
      this.arrAddressBook = Object.assign([], this.addressBookData).filter(
        (item) =>
          item.FirstName.toLowerCase().indexOf(value.toLowerCase()) > -1 ||
          item.LastName.toLowerCase().indexOf(value.toLowerCase()) > -1 ||
          item.Address1.toLowerCase().indexOf(value.toLowerCase()) > -1 ||
          item.Address2.toLowerCase().indexOf(value.toLowerCase()) > -1 ||
          item.City.toLowerCase().indexOf(value.toLowerCase()) > -1 ||
          item.CountryName.toLowerCase().indexOf(value.toLowerCase()) > -1 ||
          item.Zip.toLowerCase().indexOf(value.toLowerCase()) > -1
      );
    } else {
      this.arrBillingAddressBook = Object.assign(
        [],
        this.billingAddressData
      ).filter(
        (item) =>
          item.FirstName.toLowerCase().indexOf(value.toLowerCase()) > -1 ||
          item.LastName.toLowerCase().indexOf(value.toLowerCase()) > -1 ||
          item.Address1.toLowerCase().indexOf(value.toLowerCase()) > -1 ||
          item.Address2.toLowerCase().indexOf(value.toLowerCase()) > -1 ||
          item.City.toLowerCase().indexOf(value.toLowerCase()) > -1 ||
          item.CountryName.toLowerCase().indexOf(value.toLowerCase()) > -1 ||
          item.Zip.toLowerCase().indexOf(value.toLowerCase()) > -1
      );
    }
  }
  addressBookAssignCopy(fromBilling = false) {
    this.arrBillingAddressBook = Object.assign([], this.billingAddressData);
    this.arrAddressBook = Object.assign([], this.addressBookData);
    //
  }

  openNewShippingAddressM(fromBilling = false) {
    let title, subTitle, okBtn, cancelBtn;
    if (fromBilling) {
      title =
        this.languageData.web.checkout_confirm_billing_newaddress_popup_title;
      subTitle =
        this.languageData.web.checkout_confirm_billing_newaddress_popup_msg;
      okBtn =
        this.languageData.web
          .checkout_confirm_billing_address_default_popup_cta_yes;
      cancelBtn =
        this.languageData.web
          .checkout_confirm_billing_address_default_popup_cta_no;
    } else {
      title = this.languageData.web.checkout_confirm_shipping_newaddress_til;
      subTitle = this.languageData.web.checkout_confirm_shipping_newaddress_msg;
      okBtn = this.languageData.web.checkout_confirm_address_default_cta_yes;
      cancelBtn = this.languageData.web.checkout_confirm_address_default_cta_no;
    }

    const modalRef = this.modalService.open(DynamicPopsModalComponent, {
      size: 'md',
      windowClass: 'modalWindowWidth',
      centered: true,
      backdrop: 'static',
      keyboard: false,
    });
    modalRef.componentInstance.modalData = {
      modalName: 'addNewShippingAddress',
      title: title,
      subTitle: subTitle,
      okBtn: okBtn,
      cancelBtn: cancelBtn,
      cancelIcon: false,
    };
    modalRef.componentInstance.shippingAddres.subscribe((data) => {
      if (data) {
        this.addNewShippingAddress(fromBilling);
      }
    });
  }
  addNewShippingAddress(fromBilling = false) {
    if (fromBilling) {
      this.addressList.billingDetail.firstName = '';
      this.addressList.billingDetail.lastName = '';
      this.addressList.billingDetail.company = '';
      this.addressList.billingDetail.street = '';
      this.addressList.billingDetail.houseNumber = '';
      this.addressList.billingDetail.city = '';
      this.addressList.billingDetail.phoneNo = '';
      //this.showdefaultShippingBtn = true;
    }
  }

  deleteAddress(index, fromBilling) {
    let selectedAddress;
    let title, subTitle, cancelBtn, okBtn;
    if (fromBilling) {
      selectedAddress = this.arrBillingAddressBook[index];
      title = this.languageData.web.checkout_confirm_delete_billing_address_til;
      subTitle =
        this.languageData.web.checkout_confirm_delete_billing_address_msg;
      cancelBtn =
        this.languageData.web
          .checkout_confirm_delete_billing_address_default_cta_no;
      okBtn =
        this.languageData.web
          .checkout_confirm_delete_address_billing_default_cta_yes;
    } else {
      selectedAddress = this.arrAddressBook[index];
      title = this.languageData.web.checkout_confirm_delete_address_til;
      subTitle = this.languageData.web.checkout_confirm_delete_address_msg;
      cancelBtn =
        this.languageData.web.checkout_confirm_delete_address_default_cta_no;
      okBtn =
        this.languageData.web.checkout_confirm_delete_address_default_cta_yes;
    }

    const modalRef = this.modalService.open(DynamicPopsModalComponent, {
      size: 'md',
      windowClass: 'modalWindowWidth',
      centered: true,
      backdrop: 'static',
      keyboard: false,
    });
    modalRef.componentInstance.modalData = {
      title: title,
      cancelBtn: cancelBtn,
      subTitle: subTitle,
      okBtn: okBtn,
      modalName: 'deleteAddressFromAddressbook',
      cancelIcon: true,
    };
    modalRef.componentInstance.selectedAddress.subscribe((data) => {
      if (data) {
        this.deleteSelectedAddress(selectedAddress);
      }
    });
  }
  deleteSelectedAddress(selectedAddress) {
    this.dataService
      .deleteAddress(selectedAddress.ID)
      .subscribe((res: Response) => {
        let resSTR = JSON.stringify(res);
        let resJSON = JSON.parse(resSTR);
        if (resJSON.success == true) {
          for (let i = 0; i < this.addressBookData.length; i++) {
            if (this.addressBookData[i].ID == selectedAddress.ID) {
              this.addressBookData.splice(i, 1);
              this.billingAddressData.splice(i, 1);
              this.addressBookAssignCopy();
              return;
            }
          }
        }
      });
  }
  checkStreetBl(val) {
    if (val == '' || val == undefined) {
      return;
    }
    let streetVal = val.replace(/\s/g, '');
    streetVal = streetVal.toLowerCase();
    if (this.addressList.billingDetail.houseNumber) {
      let houseVal = this.addressList.billingDetail.houseNumber.replace(/\s/g, '');
      houseVal = houseVal.toLowerCase();

      if (houseVal.length > 0) {
        if (houseVal == streetVal) {
          this.streetErrorBl = true;
        } else {
          this.streetErrorBl = false;
        }
      } else {
        this.streetErrorBl = false;
      }
    } else {
      this.streetErrorBl = false;
    }
  }
  checkHouseBL(val) {
    var houseVal = val.replace(/\s/g, '');
    houseVal = houseVal.toLowerCase();
    if (this.addressList.billingDetail.street) {
      var streetVal = this.addressList.billingDetail.street.replace(/\s/g, '');
      streetVal = streetVal.toLowerCase();

      if (streetVal.length > 0) {
        if (streetVal == houseVal) {
          this.streetErrorBl = true;
        } else {
          this.streetErrorBl = false;
        }
      } else {
        this.streetErrorBl = false;
      }
    } else {
      this.streetErrorBl = false;
    }
  }
  checkPhoneBl(newVal) {
    let regEx = /^[0-9]\d*$/;
    let emailPattern = regEx.test(newVal);
    if (!emailPattern) {
      this.showErrorMsg = true;
      return;
    }
    newVal = newVal * 1;
    this.showErrorMsg = false;
    this.addressList.billingDetail.phoneNo = newVal.toString();
  }
  checkEmailExist(email) {
    if (email == undefined || email.length == 0) {
      this.showNonverified = false
      return;
    }
    else {
      let regEx = /[\w-]+(\.[\w-]+)*@[a-z0-9]+([.-][a-z0-9]+)*\.[a-z]{2,6}(:\d{4})?$/;
      let emailPattern = regEx.test(email);
      if (!emailPattern) {
        this.showNonverified = false
        return;
      }
    }
    this.dataService.checkValidEmailOnly(email).subscribe((res: Response) => {
      let resSTR = JSON.stringify(res);
      let resJSON = JSON.parse(resSTR);
      if (resJSON.success == true) {
        // this.error1 = true;
        if (this.isSameAsShippingChecked) {
          this.isEnabledBillingForm = true;
          this.isSameAsShippingChecked = false;
          this.sameShippingChecked = false;
        }
        if (this.userInfo.userType == 'Guest' || this.userInfo.userType == 'Nonverified') {
          this.showNonverified = true;
        }
      }
      else {
        this.showNonverified = false
        this.error1 = false;
      }
    })
  }

  changeGender(gender, formName) {
    this.languageData
    if (formName == 'billingForm') {
      this.addressList.billingDetail.gender = gender;
    }

    $('.genderList').click(function () {
      $(".genderBillingingTag").dropdown('hide');
    });

    this.changeDetectorRef.detectChanges()
  }


  changeCountryAndSetExtnForBillingAddress(country, formName) {
    if (formName == "billingForm") {
      this.oemCountryObj = this.getOemCountry(country, '');
      this.charMinLengthBl = this.oemCountryObj.MinPostalLength;
      this.charMaxLengthBl = this.oemCountryObj.PostalLength;
      let indexofSelectedCountry = this.arrBillingAddressBook.findIndex(item => item.IsDefaultBilling == 1 && item.CountryName == country)
      if (indexofSelectedCountry > -1) {
        this.addressList.billingDetail.zip = this.arrBillingAddressBook[indexofSelectedCountry].Zip;
        this.countPostalCodeBl(this.addressList.billingDetail.zip);
      } else {
        this.addressList.billingDetail.zip = '';
        this.countPostalCodeBl('');
      }
      this.addressList.billingDetail.phoneExtension = this.oemCountryObj.ISD;
      this.addressList.billingDetail.selectedCountry = this.oemCountryObj.countryName;
      this.addressList.billingDetail.selectedCountryCode = this.oemCountryObj.countryCode;
      $('.billingCountryList').click(function () {
        $(".billingCountryTag").dropdown('hide');
      });
      this.changeDetectorRef.detectChanges()
    }
  }
  splitCountryCodeFromPhone(phone: any) {
    let phoneNo = phone.split('-')
    return phoneNo[1];
  }

  submitAddress(form: any) {
    if (form.status == "INVALID") {
      return;
    }
    // this.addressList.billingDetail['from']='profile'


    let phoneExtensionB = this.addressList.billingDetail.phoneExtension;
    let phoneNumberB = this.addressList.billingDetail.phoneNo;

    if (phoneExtensionB != undefined && phoneExtensionB.length > 0) {
      if (phoneNumberB != undefined && phoneNumberB.length > 0) {
        this.addressList.billingDetail.phone =
          phoneExtensionB + '-' + phoneNumberB;
      } else {
        this.addressList.billingDetail.phone = '';
      }
    } else if (phoneNumberB != undefined && phoneNumberB.length > 0) {
      if (phoneExtensionB != undefined && phoneExtensionB.length > 0) {
        this.addressList.billingDetail.phone =
          phoneExtensionB + '-' + phoneNumberB;
      } else {
        // this.user.billingDetail.phone = this.languageData['register_country_code']+'-'+this.billingForm.phoneNo.$viewValue;
      }
    } else {
      this.addressList.billingDetail.phone = '';
    }



    this.submitDisabled = true;
    this.dataService.setShippingAndBillingAddress('', this.addressList.billingDetail, 'profile').subscribe((res: Response) => {
      let resSTR = JSON.stringify(res);
      let resJSON = JSON.parse(resSTR);

      if (resJSON.success == true) {
        this.billingAddress = this.addressList.billingDetail;
        this.uploadProfile();
      }
    })

    this.showSavedProfileMsg = true;
    setTimeout(() => {
      this.showSavedProfileMsg = false;
    }, 3000);
  }

  passwordToggle() {
    this.passwordShow = !this.passwordShow;
  }

  getBillingAddressDetail() {
    this.dataService
      .getprofileDetails(this.authId)
      .subscribe((res: Response) => {
        let resSTR = JSON.stringify(res);
        let resJSON = JSON.parse(resSTR);
        this.arrOemCountries = resJSON.oemCountries;
        //
        this.userProfileData = resJSON.data;
        if (resJSON.success == true && resJSON.data.user_type != 'Guest') {
          //  ////////
          let indexOfBillingAddress = this.addressBookData.findIndex(
            (item) => item.IsDefaultBilling == '1'
          );


          if (this.addressBookData.length == 0) {
            let defaultIndex = this.arrOemCountries.findIndex(
              (item) => item.isDefault == 'Yes'
            );
            this.oemCountryObj = this.getOemCountry(
              this.arrOemCountries[defaultIndex].countryName,
              this.arrOemCountries[defaultIndex].countryCode
            );
            this.countryObj = this.getOemCountry(
              this.arrOemCountries[defaultIndex].countryName,
              this.arrOemCountries[defaultIndex].countryCode
            );
            let dataForShipping = this.getCountry(
              this.arrOemCountries[defaultIndex].countryName,
              this.arrOemCountries[defaultIndex].countryCode
            );

            this.countryObj.code = this.countryObj.countryCode;
            this.countryObj.country = this.countryObj.countryName;
            this.countryObj.code = this.countryObj.countryCode;
            this.countryObj.country = this.countryObj.countryName;
            this.countryObj.minPostalLength = this.countryObj.MinPostalLength;
            this.countryObj.maxPostalLength = this.countryObj.PostalLength;
            this.getZipPattern(this.countryObj);
            this.getZipPatternBl(this.oemCountryObj);

            this.countPostalCode('');
            this.countPostalCodeBl('');
          } else {
            this.addressBookData.map((item, index) => {
              if (item.IsDefaultBilling == 1) {
                this.oemCountryObj = this.getOemCountry(
                  item.countryName,
                  item.Country
                );
              }
            });
          }
          //////
          this.userEmail = resJSON.data.userEmail;
          let phone = resJSON.data.Phone.split('-');

          if (this.addressBookData.length == 0) {
            this.isDisabledShippingFeilds = true;
            // ////////

            this.countryObj = this.getOemCountry(
              this.oemCountryObj.countryName,
              this.oemCountryObj.countryCode
            );
            this.countryObj.code = this.oemCountryObj.countryCode;
            this.countryObj.country = this.oemCountryObj.countryName;
            this.selectedShippingCountry = {
              code: this.countryObj.countryCode,
              country: this.countryObj.countryN,
            };
            this.getZipPattern(this.countryObj);
          } else {
            this.isDisabledShippingFeilds = false;
          }
        } else {
          this.isEnabledBillingForm = true;
          for (let i = 0; i < this.arrOemCountries.length; i++) {
            if (this.arrOemCountries[i].isDefault == 'Yes') {
              this.oemCountryObj = this.getOemCountry(
                this.arrOemCountries[i].countryName,
                this.arrOemCountries[i].countryCode
              );
              this.countryObj = this.getOemCountry(
                this.arrOemCountries[i].countryName,
                this.arrOemCountries[i].countryCode
              );

              let dataForShipping = this.getCountry(
                this.arrOemCountries[i].countryName,
                this.arrOemCountries[i].countryCode
              );
              //

              //  
              this.countryObj.code = this.countryObj.countryCode;
              this.countryObj.country = this.countryObj.countryName;
              this.countryObj.minPostalLength = this.countryObj.MinPostalLength;
              this.countryObj.maxPostalLength = this.countryObj.PostalLength;
              this.countryObj.shippingOptions = dataForShipping.shippingOptions;
              this.selectedShippingCountry = {
                code: this.countryObj.countryCode,
                country: this.countryObj.countryN,
              };
              this.getZipPattern(this.countryObj);
            }
          }
        }
        this.getZipPatternBl(this.oemCountryObj);
      });
  }
  




  // New ts start
  updateProfileDetail(template: TemplateRef<any>){
    this.profileDetailUpdateRef = this.modalService.open(template, {
      size: 'md',
      windowClass: 'custom-modal',
      centered: true,
    });
  }

  UpdateProfilePassword(template: TemplateRef<any>){
    this.profilePasswordUpdateRef = this.modalService.open(template, {
      size: 'md',
      windowClass: 'custom-modal',
      centered: true,
    }); 
  }
  
  addNewAddress(template: TemplateRef<any>){
    this.addAddressRef = this.modalService.open(template, {
      size: 'md',
      windowClass: 'custom-modal',
      centered: true,
      scrollable:true
    }); 
  }

  closePopup(){
    this.profileDetailUpdateRef?.close();
    this.profilePasswordUpdateRef?.close();
    this.addAddressRef?.close();
  }


creatAccountFormGroup(){
  this.accountFormGroup = this.fb.group({
   firstName:[''],
   lastName:[''],
   emailAddress:[''],
   dateOfBIrth:[''],
  })
}


// New code Fotosystem Start

 selectDate(day){
  this.selectedDayfs = day;
  //  this.accountFormGroup.get('selectedAction');
  //  this.accountFormGroup.get('selectedAction')?.setValue(day);
}
selectMonth(month){
  this.selectedMonthfs = month;
}
selectYear(year){
  this.selectedYearfs = year;
}
submitForm(){
  console.log(this.accountFormGroup.value);
  
}
editAccount(){
  this.isEditMode = true;
}
cancelAccount(){
  this.isEditMode = false; 
}
// bindDateOptions(){
//   this.accountFormGroup.value.selectedAction
// }
}
