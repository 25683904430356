import { Component, OnInit,ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { DataService } from 'src/app/services/data.service';
import { GlobalService } from 'src/app/services/global.service';
import * as global from 'src/app/globals';
import { ContentfulService } from 'src/app/services/contentful.service';
import { ComponentCanDeactivate } from 'src/app/guard/auth-guard.service';
@Component({
    selector: 'app-newsletter-unsubscribe',
    templateUrl: './newsletter-unsubscribe.component.html',
    styleUrls: ['./newsletter-unsubscribe.component.scss'],
    standalone: false
})
export class NewsletterUnsubscribeComponent implements OnInit ,ComponentCanDeactivate {

  canDeactivate(): Observable<boolean> | boolean {
    return true
  }
    @ViewChild('autoFocusEnabled') autoFocusEnabled;
    isLoading: Boolean;
    reasonUnSub: any;
    reason: any;
    codeId: any = "loggedIn";
    user: any = {};
    defaultCheckedState: Boolean;
    letterMsg1: Boolean = false;
    letterMsg2: Boolean;
    apiMsg: any = '';
    userInfo: any;
    selectedOption: any;
    defaultSelectedOption: any;
    languageData; any;
    other1: any;
    isDisplayTextArea: Boolean = false;
    dicUrls: any;
    profilePageUrl: any;
    isOptionSelected: Boolean = false;
    showInputEnabled : boolean = true;
    languageDataSubscription: Subscription;

    constructor(private dataservice: DataService, private router: Router, private globalService: GlobalService,private contentfulservice:ContentfulService) {

     this.contentfulservice.$newsletterUpdate.subscribe(response => {
              if (response == '1') {
              this.userInfo.Newsletter='1';
              }
              else if(response == '0') {
              this.userInfo.Newsletter='0';
              }
             });
             window.onbeforeunload = function() {window.scrollTo(0,0);}

     }

    ngOnInit() {
     window.scrollTo(0,0)
        this.userInfo = localStorage.getItem('userInfo');
       if (localStorage.getItem('userInfo')) {
            this.userInfo = JSON.parse(this.userInfo);
            this.user.email = this.userInfo.Email;
       }else{
        this.showInputEnabled = false;
        this.userInfo = {};
        this.user.email = this.userInfo.Email;
       }

            if (this.globalService.gettLanguageData() != undefined) {
                this.languageData = this.globalService.gettLanguageData()
                this.selectedOption = this.languageData.web.unsubscribe_select_options;
                this.defaultSelectedOption = this.languageData.web.unsubscribe_select_options;
            }
            else {
                this.languageDataSubscription = this.globalService.languageDataEmiter.subscribe(data => {
                    this.languageData = data;
                    this.selectedOption = this.languageData.web.unsubscribe_select_options;
                    this.defaultSelectedOption = this.languageData.web.unsubscribe_select_options;
                });
            }

            this.dicUrls = this.globalService.getStaticPagesUrls(global.env, global.lang);
            this.profilePageUrl = this.dicUrls['ACCOUNT_PREVIEW'];
        // }
        // else {
        //     this.router.navigate(['/']);
        // }
    }

    unsubscribeLetter() {
        if (this.selectedOption == this.defaultSelectedOption) {
            this.isOptionSelected = true;
            return;
        }
        this.isLoading = true;
        var email = this.user.email;
        var type = "unsubscribe"
        var reason;

        if (this.reasonUnSub) {
            reason = this.reasonUnSub;

        }
        else {
            reason = this.reason;
            reason = reason == undefined ? "" : reason;

        }
        if (this.isDisplayTextArea) {
            if (this.other1 == '' || this.other1 == undefined) {
                // $('#text-Box').css('border-bottom', '1px solid #ec4b5b');
                return;
            }
        }

         this.dataservice.newsletterUnsubscribe(email, type, reason, this.codeId, '').subscribe((res: Response) => {
            let resSTR = JSON.stringify(res);
            let resJSON = JSON.parse(resSTR);
            if (resJSON.success == true) {
                this.user.newsletter = false;
                this.defaultCheckedState = false;
                this.letterMsg1 = true;
                this.letterMsg2 = false;
                this.apiMsg = resJSON.message;
                this.isLoading = false;
                let currentUserInfo=JSON.parse(localStorage.getItem('userInfo'));
                if(currentUserInfo !== null && currentUserInfo !== '' && currentUserInfo !==  undefined){
                    this.userInfo['Newsletter']='0';
                    currentUserInfo['Newsletter']='0';
                    localStorage.setItem('userInfo', JSON.stringify(currentUserInfo));
                    this.contentfulservice.emitNewsletterUpdate('0');
                    setTimeout(() => {
                        this.goToMyprofile();
                    }, 3000);
                }
                // $(".radiobuttonsform").prop('checked', false);
            }
            else {
                this.user.newsletter = true;
                this.defaultCheckedState = true;
                this.letterMsg1 = false;
                this.letterMsg2 = true;
                this.apiMsg = resJSON.message;
                this.isLoading = false;
                // $(".radiobuttonsform").prop('checked', false);
            }
        });


    }

    updateReason(reason) {
        if (reason == 'NoReson') {
            this.isOptionSelected = false;
            this.isDisplayTextArea = true;
            this.reason = this.languageData.web.unsubscribe_drop_down_option_7;
        }
        if (reason == this.defaultSelectedOption) {
            this.isOptionSelected = true;
        }
        else {
            this.isDisplayTextArea = false;
            this.isOptionSelected = false;
            this.reason = reason;
            this.autoFocusEnabled.nativeElement.focus();
        }
    }

    getReason1(reason) {
        this.reasonUnSub = reason;
    }
    goToMyprofile() {
        this.router.navigate([this.profilePageUrl]);
    }

}
