<div class="main-content icw-1220 m-auto" *ngIf="userisLoggedIn">
    <video playsinline autoplay muted src="{{imageIconPath}}colourful_spinner.webm" class="position-absolute custom-spinner" [hidden]="isHeaderLoaded"></video>
    <div class="container" *ngIf="isHeaderLoaded">
        <h3 class="title">{{'web.delete_account_title' | translate}}</h3>
        <div class="delete-wrap">
            <p>{{'web.delete_account_sub_title' | translate}}</p>
            <p class="pt-3">{{'web.delete_account_desc' | translate}}</p>
            <div class="c_content d-flex align-items-center pt-3 flex-wrap row">
                <div class="position-relative col-12 col-sm-3">
                    <input type="password" class="mb-0 mt-2 w-100" (keyup.enter)="login(userEmail)" name="password" #password="ngModel" [(ngModel)]="userPassword" required 
                placeholder="{{'web.my_profile_current_pass' | translate }}" [ngClass]="{'disabled':isApiLoading}">
                    <div *ngIf="passwordIncorrect" class="text-danger">{{'web.delete_account_password_false' | translate}}</div>
                </div>
                <div class="d-flex col-12 col-sm-6 mt-md-2 mt-3 ps-sm-0">
                
                    <a class="btn btn-primary me-2" (click)="redirectMyProfile()" [ngClass]="{'disabled':isApiLoading}" *ngIf="!fromApp">{{'web.delete_account_back_cta' | translate}}</a>
                    <a class="btn btn-primary me-2" (click)="closeFromApp()" [ngClass]="{'disabled':isApiLoading}" *ngIf="fromApp">{{'web.delete_account_back_cta' | translate}}</a>

                    <a class="btn btn-light" (click)="login(userEmail,true)" [ngClass]="{'disabled':isApiLoading}">{{'web.delete_account_cta' | translate}}</a>

                                 </div>
            </div>
        </div>
    </div>
</div>