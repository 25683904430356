
    <div class="old-design">
        <div class="profile icw icw-1260" #container>
            <video playsinline autoplay muted src="{{imageIconPath}}colourful_spinner.webm" class="loader"
                *ngIf="!user.userNumber"></video>
            <div class="left-link show">
                <button class="btn btn-link" type="button" data-bs-toggle="collapse" data-bs-target="#collapseLincs"
                    aria-expanded="false" aria-controls="collapseLincs">{{'web.home_myaccount' | translate}}
                    <img src="{{imageIconPath}}arrow-up-16.svg" class="ms-auto" alt="" title=""></button>
                <div class="collapse links show" id="collapseLincs" *ngIf="showAccountInfo">
                    <h5 (click)="myprojectUrl($event)">{{'web.home_my_project' | translate}} <span
                            *ngIf="productCount >= 1">({{productCount}})</span></h5>
                    <h5 (click)="sharedprojectUrl($event)" [ngClass]="{'active': activeUrlIs=='shared-projects'}">
                        {{'web.home_shared_project' | translate}} <span
                            *ngIf="sharedProjectCount >= 1">({{sharedProjectCount}})</span> </h5>
                    <h5 (click)="orderUrl($event)">{{'web.home_myorders' | translate}} <span
                            *ngIf="orderCount >= 1">({{orderCount}})</span></h5>
                    <h5 (click)="myAccountUrl($event)" class="active">{{'web.home_myaccount' | translate}}</h5>
                    <h5 (click)="myPhotos($event)">{{'web.home_myphotos' | translate}}</h5>
                </div>
            </div>
            <div class="account-info" *ngIf="user.userNumber">
                <!-- <div class="greet">productos-fotograficos
                    <span class="profile-icon">
                        <img src="{{accountIcon}}" title="" alt="">
                    </span>{{user.firstName}} {{user.lastName}}
                </div> -->
                <div class="contact-info">
                    <button class="btn btn-link collapse-head pb-20 px-0" type="button" data-bs-toggle="collapse"
                    data-bs-target="#collapseContactInfo" aria-expanded="true" aria-controls="collapseContactInfo">
                        <span>{{'web.myprofile_sec_header_account_info_tit' | translate }}</span>
                        <img src="{{imageIconPath}}arrow-down-16.svg" alt="" title="">
                    </button>
                    <div class="collapse show" id="collapseContactInfo">
                        <!-- <form action=""> -->
                        <div class="row mb-20">
                            <div class="col-sm-6 col-12">
                                <label for="fName">{{'web.my_profile_first_name_label' | translate }}</label>
                                <p *ngIf="isNameInputDisabled">{{user.firstName}}</p>
                                <input type="text" [(ngModel)]="user.firstName" placeholder="{{user.firstName}}" name="fname"
                                    class="form-control mb-0" *ngIf="!isNameInputDisabled" id="renameInputField"  minlength="2" maxlength="17" (paste)="preventLeadingSpace($event)" (keydown)="preventLeadingSpace($event);characterValidation({type:'firstName',minimumLength:0,maximumLength:17,value2:$event.target.value})" (input)="characterValidation({type:'firstName',minimumLength:0,maximumLength:17,value2:$event.target.value})">
                                    <div class="text-danger fs-12" style="position: absolute" *ngIf="errorMessage.currentValue == 'firstName' ">{{errorMessage.msg}}</div>
                        <!-- <div class="text-danger" *ngIf="showEmailMessage" class="validation-message">
                            <span *ngIf="emailInput.errors?.minlength">Minimum 5 characters required.</span>
                            <span *ngIf="emailInput.errors?.maxlength">Maximum 100 characters allowed.</span>
                        </div> -->
                                </div>
                            <div class="col-sm-6 col-12">
                                <label for="lName">{{'web.my_profile_last_name_label' | translate }}</label>
                                <p *ngIf="isNameInputDisabled">{{user.lastName}}</p>
                                <input type="text" placeholder="{{user.lastName}}" [(ngModel)]="user.lastName" name="lName"
                                    class="form-control mb-0" *ngIf="!isNameInputDisabled"  minlength="2" maxlength="17" (paste)="preventLeadingSpace($event)" (keydown)="preventLeadingSpace($event);characterValidation({type:'lastName',minimumLength:0,maximumLength:17,value2:$event.target.value})" (input)="characterValidation({type:'lastName',minimumLength:0,maximumLength:17,value2:$event.target.value})">
                                    <div class="text-danger fs-12" style="position: absolute"  *ngIf="errorMessage.currentValue == 'lastName' ">{{errorMessage.msg}}</div>
                                    <img src="{{EDIT_ICON}}" alt="" title="" class="editName cursor-pointer"
                                    *ngIf="isNameInputDisabled" (click)="editName()">
                            </div>
                        </div>
                        <!-- <label for="" class="mt-20">{{'web.my_profile_user_number' | translate}}</label>
                        <p>{{user.userNumber}}</p> -->

                        <label for="" class=""
                            *ngIf="newsletterApiValue == 0 || newsletterApiValue == 3 || newsletterApiValue == 2">{{'web.myprofile_newsletter_field_title'
                            |
                            translate}}</label>


                        <div class="delete-account">
                            <p class="mb-0" *ngIf="newsletterApiValue != 1">

                                <label *ngIf="newsletterApiValue == 0">{{'web.newsletter_not_subscribed_yet' |
                                    translate}}</label>
                                <label *ngIf="newsletterApiValue == 3">{{'web.newsletter_unsubscription_date' | translate}}
                                    <span *ngIf=" newsletterApiValue == 3" class="ps-2">{{newsletterDate}} </span>
                                </label>
                                <label *ngIf="newsletterApiValue == 2">{{'web.newsletter_subscribed' | translate}}</label>


                            </p>
                        </div>

                        <button class="btn btn-warning btn-md w-100 d-block mt-20 mb-20" type="button"
                            *ngIf="newsletterApiValue != 1" (click)="newsletterSubscribe()">{{(newsletterApiValue == 0||
                            newsletterApiValue == 3) ? ('web.my_profile_newsletter_section_cta_txt' | translate) :
                            (newsletterApiValue == 2 ? ('web.newsletter_subscribed-notconfirmed_cta' |
                            translate) : '')}}</button>
                        <!-- </form> -->
                        <form class="mt-20" action="" name="form" #changeDateOfBirthForm="ngForm" novalidate>
                            <div class="dob-wrap pb-20" *ngIf="disableValue1 && disableValue2 && disableValue3">
                                <label for="">{{'web.my_profile_dob_label' | translate }}</label>
                                <p><span *ngIf="selectedDay<10">0</span>{{selectedDay}}.<span
                                        *ngIf="selectedMonth<10">0</span>{{selectedMonth}}.{{selectedYear}}</p>
                            </div>
                            <div class="dob-wrap" *ngIf="!disableValue1 && !disableValue2 && !disableValue3">
                                <label for="">{{'web.my_profile_dob_label' | translate }}</label>
                                <div class="select">
                                    <div class="dropdown select-input">
                                        <select [disabled]="disableValue1" class="form-control" name="date" #date="ngModel"
                                            [(ngModel)]="selectedDay" (ngModelChange)="UpdateNumberOfDays($event)"
                                            [ngClass]="{'error': isValidDate}">
                                            <option *ngFor="let day of mainArrDay" [ngValue]="day">{{day}}</option>
                                        </select>
                                    </div>

                                    <div class="dropdown select-input">
                                        <select [disabled]="disableValue2 " class="form-control" name="month" #month="ngModel"
                                            [(ngModel)]="selectedMonth" (ngModelChange)="UpdateNumberOfMonths($event)">
                                            <option *ngFor="let month of mainArrMonth" [ngValue]="month">{{month}}</option>
                                        </select>
                                    </div>

                                    <div class="dropdown select-input">
                                        <select [disabled]="disableValue3" class="form-control" name="year" #year="ngModel"
                                            [(ngModel)]="selectedYear" (ngModelChange)="UpdateNumberOfYears($event)">
                                            <option *ngFor="let year of mainArrYear" [ngValue]="year">{{year}}</option>
                                        </select>
                                    </div>

                                </div>
                            </div>

                            <div [hidden]="!isEmailInputDisabled">
                                <label>{{'web.my_profile_user_email_label' | translate }}<span>*</span></label>
                                <p class="user-email">{{user.userName}}
                                    <!-- <span [hidden]="isEmailConfirmed" *ngIf="!emailUpdateSuccess" class="edit" -->
                                    <span [hidden]="isEmailConfirmed" class="edit cursor-pointer"
                                        title="{{'web.my_profile_email_col_focus_tip' | translate }}" (click)="focus()">
                                        <img src="{{EDIT_ICON}}" alt="" title="">
                                    </span>
                                </p>
                            </div>
                            <div class="position-relative" [hidden]="isEmailInputDisabled">
                                <label>{{'web.my_profile_user_email_label' | translate }}<span>*</span></label>
                                <input type="email" #onEdit name="email" #emailinput="ngModel" [(ngModel)]="user.userName"
                                (keyup)="checkEmail(user.userName)"  appEmailValidation="contentfulService.emailValidation()">
                                <div *ngIf="emailinput.invalid && (emailinput.dirty || emailinput.touched) && emailinput.errors.invalidEmail"
                                class="text-danger errorMsg">
                                {{'web.register_val_invalid_email' | translate }}
                            </div>
                            </div>
                            <div class="discription pb-20">
                                <span *ngIf="isEmailverified" class="fs-12">
                                    {{'web.my_profile_email_verification_msg' | translate }}
                                </span>
                                <span class="discription pb-20 text-primary cursor-pointer ps-1 fs-12" *ngIf="isEmailverified">
                                    <a [routerLink]=""
                                        (click)="resendVerificationEmail()">{{'web.my_profile_resend_verification_email_link' |
                                        translate}}</a>
                                </span>
                            </div>
                            <div class="discription pb-20">
                                <span *ngIf="isEmailNonverified" class="fs-12">
                                    {{'web.my_profile_email_notconfirmed_msg' | translate }}
                                </span>
                                <span class="discription pb-20 text-primary cursor-pointer ps-1 fs-12" *ngIf="isEmailNonverified">
                                    <a [routerLink]=""
                                        (click)="resendVerificationEmail()">{{'web.my_profile_resend_verification_email_link' |
                                        translate}}</a>
                                </span>
                            </div>
                            <div class="discription pb-20">
                                <span class="text-danger" *ngIf="isValidEmail">
                                    {{'web.myprofile_email_update_already_exists_msg' | translate }}
                                </span>
                            </div>
                            <!-- <div  class="discription pb-20" *ngIf="displayResendVerifyEmailLink"> -->

                            <!-- || !selectday || !selectmonth || !selectyear -->
                            <!-- <button class="btn btn-primary btn-md w-100 d-block" [disabled]='isEmailInputDisabled '
                                (click)="dateOfBirth()">{{'web.my_profile_cta_save' | translate}}</button> -->
                            <button class="btn btn-primary btn-md w-100 d-block save-btn"
                                *ngIf="showAccountInformatio || (showDateSelect && showMonthSelect && showYearSelect) || !isNameInputDisabled"
                                (click)="dateOfBirth()">{{'web.my_profile_cta_save' | translate}}</button>
                        </form>
                    </div>
                </div>
                <div class="password">
                    <button class="btn btn-link collapse-head pb-20 px-0" type="button" data-bs-toggle="collapse"
                        data-bs-target="#collapsePassword" aria-expanded="false" aria-controls="collapsePassword">
                        <span>{{'web.myprofile_sec_header_account_pass_tit' | translate }}</span>
                        <img src="{{imageIconPath}}arrow-down-16.svg" alt="" title="">
                    </button>
                    <div class="collapse" id="collapsePassword">

                        <form name="form" #changePasswordForm="ngForm" novalidate>

                            <div class="input-wrap position-relative">
                                <label>{{'web.my_profile_current_change_pass' | translate }}<span>*</span></label>
                                <input [type]="passwordShow ? 'text' : 'password'"
                                    placeholder="{{'web.my_profile_current_pass' | translate }}" name="oldpassword"
                                    #oldpassword="ngModel" (keyup.enter)="resetPassword()" [(ngModel)]="user.oldPassword"
                                    (keyup)="checkPassword('oldpassword')"
                                    (paste)="preventPasteLeadingSpace($event)"
                                    [ngClass]="{ 'error': (oldpassword.touched && oldpassword.invalid) || showError}"
                                    required />

                                <span class="cursor-pointer position-absolute passwordToggle" (click)="passwordToggle()">
                                    <img src="{{passwordShow ? previewCrossed : previewOutline }}" alt="" title="">
                                </span>

                                <span *ngIf="isPasswordWrong"
                                    class="text-danger position-absolute">{{'web.my_profile_val1_old_pass' | translate }}</span>
                            </div>

                            <div class="input-wrap position-relative">
                                <label>{{'web.my_profile_new_change_pass1' | translate }}<span>*</span></label>
                                <input [type]="passwordShow ? 'text' : 'password'" (keyup.enter)="resetPassword()"
                                    placeholder="{{'web.my_profile_new_pass' | translate }}" name="newPassword"
                                    [ngClass]="{ 'error': (newPassword.touched && newPassword.invalid) || showError}"
                                    #newPassword="ngModel" [(ngModel)]="user.newPassword"
                                    (keyup)="checkPassword(1); passFormat(1)" maxlength="20" required />

                                <span class="cursor-pointer position-absolute passwordToggle" (click)="passwordToggle()">
                                    <img src="{{passwordShow ? previewCrossed : previewOutline }}" alt="" title="">
                                </span>

                                <span *ngIf="isPasswordMatched"
                                    class="text-danger position-absolute">{{'web.my_profile_pass_val_old' | translate }}</span>
                                <span *ngIf="invalidPassword1 && newPassword.touched"
                                    class="text-danger position-absolute">{{'web.my_profile_val_invalid_pass' | translate
                                    }}</span>
                            </div>


                            <div class="input-wrap position-relative">
                                <label>{{'web.my_profile_new_change_pass2' | translate }}<span>*</span></label>
                                <input [type]="passwordShow ? 'text' : 'password'" (keyup.enter)="resetPassword()"
                                    placeholder="{{'web.my_profile_confirm_pass' | translate }}" name="matchPassword"
                                    [ngClass]="{ 'error': (matchPassword.touched && matchPassword.invalid) || showError}"
                                    (keyup)="checkPassword(2); passFormat(2)" maxlength="20" [(ngModel)]="user.matchPassword"
                                    #matchPassword="ngModel" required />

                                <span class="cursor-pointer position-absolute passwordToggle" (click)="passwordToggle()">
                                    <img src="{{passwordShow ? previewCrossed : previewOutline }}" alt="" title="">
                                </span>

                                <span *ngIf="invalidPassword2"
                                    class="text-danger position-absolute">{{'web.register_val_pass_match' | translate }}</span>
                            </div>

                            <div class="action-btn">

                                <!-- <button class="btn btn-primary btn-md w-100 d-block save-btn" type="button" *ngIf="showPasswordButton" (click)="resetPassword()">{{'web.my_profile_cta_save'
                                    | translate}}</button> -->

                                <button class="btn btn-primary btn-md w-100 d-block save-btn" type="button"
                                    (click)="resetPassword()">{{'web.my_profile_cta_save' | translate}}</button>

                            </div>
                        </form>
                    </div>
                </div>
                <!-- <div class="contactEmail">
                    <button class="btn btn-link collapse-head pb-20 px-0" type="button" data-bs-toggle="collapse"
                        data-bs-target="#collapseContactEmail" aria-expanded="false" aria-controls="collapseContactEmail">

                        <span>{{'web.myprofile_sec_header_account_email_tit' | translate }}</span>
                        <img src="{{imageIconPath}}arrow-up-16.svg" alt="" title="">
                    </button>

                    <div class="collapse" id="collapseContactEmail" >
                        <div [hidden]="!isEmailInputDisabled">
                            <label>{{'web.my_profile_user_email_label' | translate }}<span>*</span></label>
                            <p class="user-email">{{user.userName}}
                                <span [hidden]="isEmailConfirmed" *ngIf="!emailUpdateSuccess" class="edit"
                                    title="{{'web.my_profile_email_col_focus_tip' | translate }}" (click)="focus()">
                                    <img src="{{EDIT_ICON}}" alt="" title="">
                                </span>
                            </p>
                        </div>
                        <div class="position-relative" [hidden]="isEmailInputDisabled">
                            <label>{{'web.my_profile_user_email_label' | translate }}<span>*</span></label>
                            <input type="text" #onEdit name="email" [(ngModel)]="user.userName">
                            <span [hidden]="isEmailConfirmed" class="edit"
                                title="{{'web.my_profile_email_col_focus_tip' | translate }}" (click)="editEmail()"><img
                                    src="{{OK_ICON}}">
                            </span>
                        </div>

                        <div  class="discription pb-20" *ngIf="emailUpdateSuccess || isEmailConfirmed">
                            {{'web.my_profile_email_verification_msg' | translate }}
                        </div>
                        <div  class="discription pb-20" *ngIf="emailUpdateSuccess || isEmailConfirmed">
                        <a [routerLink]="" (click)="resendVerificationEmail()">{{'web.my_profile_resend_verification_email_link' | translate}}</a>
                        </div>

                        <div  class="discription pb-20" *ngIf="emailUpdateSuccess || isEmailConfirmed">
                            {{'web.my_profile_email_verification_msg' | translate }}
                        </div>
                        <div  class="discription pb-20" *ngIf="displayResendVerifyEmailLink">
                        <a [routerLink]="" (click)="resendVerificationEmail()">{{'web.my_profile_resend_verification_email_link' | translate}}</a>
                        </div>

                        <button class="btn btn-primary btn-md w-100 d-block" type="button" [disabled]='isEmailInputDisabled' (click)="updateEmailButton()">{{'web.my_profile_cta_save' | translate}}</button>
                        <div class="discription text-center"  *ngIf="emailUpdateSuccess || isEmailConfirmed">
                            <p>{{'web.api_val_update_email_link_success' | translate }}</p>
                        </div>
                    </div>

                </div> -->
                <div class="contactAddress">
                    <button class="btn btn-link collapse-head pb-20 px-0" type="button" data-bs-toggle="collapse"
                        data-bs-target="#collapseContactAddress" aria-expanded="false" aria-controls="collapseContactAddress">
                        <span>{{'web.myprofile_sec_header_account_billing_add_tit' | translate}}</span>
                        <img src="{{imageIconPath}}arrow-down-16.svg" alt="" title="">
                    </button>
                    <div class="collapse" id="collapseContactAddress">
                        <div class="address-list">
                            <button class="btn dropdown-toggle" type="button" data-flip="false" id="billingcollapseAddress"
                                data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                                *ngIf="activeTab == 'shipping' || activeTab == 'pickup'">
                                <span>{{'web.checkout_address_billing_title' | translate }}</span>
                                <img src="{{searchIcon}}" alt=""
                                    *ngIf="billingAddressEditable && billingAddressData.length >0 && (activeTab == 'shipping' || activeTab == 'pickup')">
                                <!-- <img src="{{arrowDownIcon}}" alt="" *ngIf="billingAddressData.length == 0 && (activeTab == 'shipping' || activeTab == 'pickup')"> -->
                            </button>
                            <ng-container *ngIf="billingAddressEditable">
                                <div class="dropdown-menu" aria-labelledby="billingcollapseAddress"
                                    *ngIf="billingAddressData.length >0 && (activeTab == 'shipping' || activeTab == 'pickup')">
                                    <form class="search-input">
                                        <img src="{{searchIcon}}" alt="" title="">
                                        <input type="email" class="form-control mb-1" #myInput name="search"
                                            [(ngModel)]="searchTextBilling" placeholder="{{'web.mycart_bill_address_search' | translate
                                    }}" (ngModelChange)="filterAddressBookItem(myInput.value,true)" autofocus>
                                    </form>
                                    <span class="title"
                                        (click)="openNewShippingAddressM(true)">{{'web.mycart_bill_address_dropdown_tit' |
                                        translate}}</span>
                                    <div class="address-item" *ngFor="let address of arrBillingAddressBook; let indx = index">
                                        <div (click)="selectAddress(indx,true);submitDisabled=false;" style="cursor: pointer;">
                                            <p class="address-name">{{address.FirstName}} {{address.LastName}}</p>
                                            <p>{{address.Address1}} {{address.Address2}}, {{address.Zip}} {{address.City}},
                                                {{address.CountryName}}
                                            </p>
                                        </div>
                                        <img src="{{binIcon}}" alt="" title="" (click)="deleteAddress(indx,true)">
                                    </div>
                                </div>
                            </ng-container>


                            <form class="form-horizontal" name="form1"
                                (ngSubmit)="billingAddressEditable && submitAddress(billingForm)" #billingForm="ngForm"
                                novalidate>

                                <div class="row mt-3">
                                    <div class="col-3">
                                        <!-- <select name="gender" class="form-control" [(ngModel)]="addressList.billingDetail.gender"  (ngModelChange)="changeGender($event, 'billingForm')" id="exampleFormControlSelect1">
                                            <option *ngFor="let gender of getGender" [ngValue]="gender.gender">
                                                {{gender.gender}}</option>
                                        </select> -->

                                        <div class="select-box mt-0">
                                            <button [disabled]="!billingAddressEditable"
                                                class="btn btn-link pe-2 ps-0 py-1 genderBillingingTag" type="button"
                                                id="genderBillingingTag" data-flip="false" data-bs-toggle="dropdown"
                                                aria-haspopup="true" aria-expanded="false">
                                                <div *ngIf="addressList.billingDetail.gender!=''">
                                                    <!-- {{addressList.billingDetail.gender}} -->
                                                    {{(addressList.billingDetail.gender == 'Male')?('web.address_gender_male' |
                                                    translate):(addressList.billingDetail.gender == 'Female')?('web.address_gender_female' | translate):('web.address_gender_neutral' | translate)}}
                                                </div>
                                                <div *ngIf="addressList.billingDetail.gender == ''">
                                                    {{'web.address_gender' |translate }}</div>
                                                    
                                            </button>
                                            <div class="dropdown-menu genderList" aria-labelledby="genderBillingingTag">
                                                <span class="dropdown-item px-2 py-0 rounded-1 d-flex align-items-center" *ngFor="let gender of getGender"
                                                    (click)="changeGender(gender.gender, 'billingForm');submitDisabled=false">
                                                    {{(gender.gender == 'Male')?('web.address_gender_male' |
                                                    translate):(gender.gender == 'Female')?('web.address_gender_female' | translate):('web.address_gender_neutral' | translate)}}</span>
                                            </div>
                                        </div>


                                    </div>
                                    <div class="col-9">
                                        <div class="row">
                                            <div class="col-6">
                                                <input type="text" class="form-control"
                                                    placeholder="{{'web.address_fname' | translate }}*" name="firstName"
                                                    [(ngModel)]="addressList.billingDetail.firstName" #firstName="ngModel"
                                                    required [disabled]="!billingAddressEditable"
                                                    [ngClass]="{ 'error': (firstName.touched && firstName.invalid) || (billingForm.submitted && firstName.invalid)}" minlength="1" maxlength="17" (paste)="preventLeadingSpace($event)" (keydown)="preventLeadingSpace($event);characterValidation({type:'firstName',minimumLength:1,maximumLength:17,value2:$event.target.value})" (input)="characterValidation({type:'firstName',minimumLength:1,maximumLength:17,value2:$event.target.value});submitDisabled=false"/>
                                                    <div class="text-danger fs-12 align-err-text" *ngIf="errorMessage.currentValue == 'firstName' ">{{errorMessage.msg}}</div>
                                                </div>
                                    

                                            <div class="col-6">
                                                <input type="text" class="form-control"
                                                    name="lastName" [disabled]="!billingAddressEditable"
                                                    placeholder="{{'web.address_lname' | translate }}*" required
                                                    [(ngModel)]="addressList.billingDetail.lastName" #lastName="ngModel"
                                                    [ngClass]="{ 'error': (lastName.touched && lastName.invalid) || (billingForm.submitted && lastName.invalid)}" 
                                                    minlength="1" maxlength="17" (paste)="preventLeadingSpace($event)" (keydown)="preventLeadingSpace($event);characterValidation({type:'lastName',minimumLength:1,maximumLength:17,value2: $event.target.value})" (input)="characterValidation({type:'lastName',minimumLength:1,maximumLength:17,value2: $event.target.value});submitDisabled=false"/>
                                                    <div class="text-danger fs-12 align-err-text" *ngIf="errorMessage.currentValue == 'lastName' ">{{errorMessage.msg}}</div>     
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-12">
                                        <input type="text" class="form-control"
                                            [(ngModel)]="addressList.billingDetail.company" #company="ngModel"
                                            [disabled]="!billingAddressEditable" name="company"
                                            placeholder="{{'web.address_company' | translate }}" minlength="1" maxlength="50" (paste)="preventLeadingSpace($event)" (keydown)="preventLeadingSpace($event);characterValidation({type:'company',minimumLength:1,maximumLength:50,value2: $event.target.value})" (input)="characterValidation({type:'company',minimumLength:1,maximumLength:50,value2: $event.target.value});submitDisabled=false"/>
                                            <div class="text-danger fs-12 align-err-text" *ngIf="errorMessage.currentValue == 'company' ">{{errorMessage.msg}}</div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-9">
                                        <input type="road" class="form-control"
                                            [(ngModel)]="addressList.billingDetail.street" #street="ngModel"
                                            placeholder="{{'web.address_street' | translate }}*" name="street"
                                            [disabled]="!billingAddressEditable" (paste)="preventLeadingSpace($event)" (keyup)="preventLeadingSpace($event);checkStreetBl(street.value)"
                                            [ngClass]="{ 'error': (street.touched && street.invalid) || (billingForm.submitted && street.invalid)}"
                                            required 
                                            minlength="1" maxlength="35" (keydown)="characterValidation({type:'street',minimumLength:1,maximumLength:35,value2:$event.target.value})" (input)="characterValidation({type:'street',minimumLength:1,maximumLength:35,value2:$event.target.value});submitDisabled=false"/>
                                        <div class="text-danger fs-12 align-err-text" *ngIf="errorMessage.currentValue == 'street' ">{{errorMessage.msg}}</div>
                                        <div *ngIf="streetErrorBl" class="text-danger">
                                            {{'web.register_street_house_val' | translate }}</div>
                                    </div>
                                    <div class="col-3">
                                        <input type="text" class="form-control"
                                            name="houseNumber" [(ngModel)]="addressList.billingDetail.houseNumber"
                                            [disabled]="!billingAddressEditable" #houseNumber="ngModel"
                                            (keyup)="checkHouseBL(houseNumber.value)"
                                            placeholder="{{'web.address_house' | translate }}"
                                            [ngClass]="{ 'error': (houseNumber.touched && houseNumber.invalid) || (billingForm.submitted && houseNumber.invalid)}" minlength="1" maxlength="10" (keydown)="characterValidation({type:'houseNumber',minimumLength:1,maximumLength:10,value2:$event.target.value})" (input)="characterValidation({type:'houseNumber',minimumLength:1,maximumLength:10,value2:$event.target.value});submitDisabled=false"/>
                                            <div class="text-danger fs-12 align-err-text" style="top: 27px" *ngIf="errorMessage.currentValue == 'houseNumber' ">{{errorMessage.msg}}</div>

                                        <span *ngIf="streetErrorBl" class="text-danger">
                                            {{'web.register_street_house_val' | translate }}</span>
                                    </div>
                                </div>

                                <div class="row position-relative">
                                    <div class="col-3">
                                        <input type="text" name="zip"
                                            [(ngModel)]="addressList.billingDetail.zip" #zip="ngModel"
                                            maxlength="{{charMaxLengthBl}}" (keyup)="preventLeadingSpace($event);countPostalCodeBl(zip.value)"
                                            [disabled]="!billingAddressEditable"
                                            (paste)="preventLeadingSpace($event)"
                                            placeholder="{{'web.address_zip' | translate }}*" minlength="{{charMinLengthBl}}"
                                            class="form-control" required="{{isZipRequiredBl}}" pattern="{{applyPatternBl}}"
                                            [ngClass]="{ 'error': (zip.touched && zip.invalid) || (billingForm.submitted && zip.invalid)}" minlength="1" maxlength="10" (keydown)="characterValidation({type:'zip',minimumLength:1,maximumLength:10,value2:$event.target.value})" (input)="characterValidation({type:'zip',minimumLength:1,maximumLength:10,value2:$event.target.value});submitDisabled=false"/>
                                            <div class="text-danger fs-12 align-err-text" *ngIf="errorMessage.currentValue == 'zip' ">{{errorMessage.msg}}</div>
                                    </div>
                                    <div class="d-flex errorMsg">
                                        <span *ngIf="zip.invalid && (zip.dirty || zip.touched) && zip.errors.pattern"
                                            class="text-danger me-2">
                                            {{'web.myprofile_zip_val_pattern_wrong' | translate }}
                                        </span>
                                        <span *ngIf="charLenIncorrectBl && zip.dirty" class="text-danger me-2">
                                            {{'web.myprofile_zip_val_min_charac' | translate }} {{charMinLengthBl}}
                                        </span>
                                        <span *ngIf="charLenIncorrectBl && zip.dirty" class="text-danger">
                                            {{'web.myprofile_zip_val_max_charac' | translate }} {{charMaxLengthBl}}
                                        </span>
                                    </div>
                                    <div class="col-9">
                                        <input type="text" name="city"
                                            [ngClass]="text-danger" placeholder="{{'web.address_city' | translate }}*"
                                            class="form-control" [(ngModel)]="addressList.billingDetail.city" #city="ngModel"
                                            [disabled]="!billingAddressEditable"
                                            [ngClass]="{ 'error': (city.touched && city.invalid) || (billingForm.submitted && city.invalid)}"
                                            required minlength="1" maxlength="35" (paste)="preventLeadingSpace($event)" (keydown)="preventLeadingSpace($event);characterValidation({type:'city',minimumLength:1,maximumLength:35,value2:$event.target.value})" (input)="characterValidation({type:'city',minimumLength:1,maximumLength:35,value2:$event.target.value});submitDisabled=false"/>
                                            <div class="text-danger fs-12 align-err-text" *ngIf="errorMessage.currentValue == 'city' ">{{errorMessage.msg}}</div>

                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-12">

                                        <div class="select-box">
                                            <button [disabled]="!billingAddressEditable" class="btn btn-link billingCountryTag"
                                                type="button" id="billingCountryTag" data-flip="false" data-bs-toggle="dropdown"
                                                aria-haspopup="true" aria-expanded="false">
                                                <span> {{addressList.billingDetail.selectedCountry}}</span>
                                                <span *ngIf="!addressList.billingDetail.selectedCountry"
                                                    class="text-danger">{{'web.address_country' |
                                                    translate }}</span>
                                            </button>
                                            <div class="dropdown-menu billingCountryList" aria-labelledby="billingCountryTag">
                                                <span class="dropdown-item px-2 py-0 rounded-1 d-flex align-items-center" *ngFor="let country of arrOemCountries"
                                                    (click)="changeCountryAndSetExtnForBillingAddress(country.countryName, 'billingForm');submitDisabled=false">{{country.countryName}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-3">
                                        <input type="text" placeholder="{{'web.address_phone_ext' | translate }}"
                                            class="form-control" name="phoneExtension" [disabled]="!billingAddressEditable"
                                            (ngModelChange)="submitDisabled=false"
                                            [(ngModel)]="addressList.billingDetail.phoneExtension" #phoneExtension="ngModel"
                                            readonly required />
                                    </div>
                                    <div class="col-9 position-relative">
                                        <input type="tel" placeholder="{{'web.address_pnone_no' | translate}}"
                                            class="form-control" name="phoneNo" [(ngModel)]="addressList.billingDetail.phoneNo"
                                            [disabled]="!billingAddressEditable"
                                            #phoneNo="ngModel" 
                                            (keyup)="checkPhoneBl(phoneNo.value)"
                                            minlength="1" maxlength="20"
                                            (keydown)="characterValidation({type:'phoneNo',minimumLength:1,maximumLength:20,value2:$event.target.value})" 
                                            (input)="characterValidation({type:'phoneNo',minimumLength:1,maximumLength:20,value2:$event.target.value});submitDisabled=false"/>
                                            <!-- <div class="text-danger fs-12 align-err-text" *ngIf="errorMessage.currentValue == 'phoneNo' ">{{errorMessage.msg}}</div> -->
                                    </div>
                                    <!-- <div class="col-9 position-relative">
                                        <input type="tel" class="form-control" name="phoneNo" [(ngModel)]="addressList.billingDetail.phoneNo" #phoneNo="ngModel" placeholder="{{'web.address_pnone_no' | translate}}" (keyup)="checkPhoneBl(phoneNo.value)" pattern="^[1-9]\d*$" (keydown.space)="$event.preventDefault();"
                                        maxlength="13" minlength="8" required [disabled]="!billingAddressEditable" [ngClass]="{ 'error': (phoneNo.touched && phoneNo.invalid) || (billingForm.submitted && phoneNo.invalid)}" />
                                    <div *ngIf="phoneNo.invalid && (phoneNo.dirty || phoneNo.touched) && phoneNo.errors" class="errorMsg text-danger">
                                        {{'web.register_val_invalid_phone' | translate }}
                                    </div>
                                    </div> -->
                                </div>


                                <div class="row">
                                    <!-- <input type="email" name="billingEmail" class="form-control" [(ngModel)]="addressList.billingDetail.email" #billingEmail="ngModel" pattern="[\w-]+(\.[\w-]+)*@[a-z0-9]+([.-][a-z0-9]+)*\.[a-z]{2,6}(:\d{4})?$" placeholder="{{'web.register_field_label_email' | translate }}*" required readonly [ngClass]="{ 'error': error1 || (billingEmail.touched && billingEmail.invalid) || (billingForm.submitted && billingEmail.invalid)}" (keyup)="checkEmailExist(billingEmail.value)">-->
                                    <div class="col-12 position-relative">
                                        <input type="email" name="billingEmail" class="form-control"
                                            [disabled]="!billingAddressEditable" [(ngModel)]="addressList.billingDetail.email"
                                            #billingEmail="ngModel" (ngModelChange)="submitDisabled=false"
                                            appEmailValidation="contentfulService.emailValidation()"
                                            placeholder="{{'web.address_email' | translate }}*" required
                                            [ngClass]="{ 'error': error1 || (billingEmail.touched && billingEmail.invalid)}">
                                        <!-- <div class="text-danger errorMsg" *ngIf="showNonverified">
                                            {{'web.register_val_email_existing' | translate }}
                                            <a class="text-primary" (click)="showLogin()">{{'web.register_val_email_existing_login' |
                                                translate}}</a>
                                        </div> -->
                                        <div *ngIf="billingEmail.invalid && (billingEmail.dirty || billingEmail.touched) && billingEmail.errors.invalidEmail"
                                            class="text-danger errorMsg">
                                            {{'web.register_val_invalid_email' | translate }}
                                        </div>
                                        <div *ngIf="!billingAddressEditable" class="text-danger errorMsg mb-2">
                                            {{'web.address_change_not_allowed_contact_customer_service' | translate }}</div>

                                    </div>
                                </div>

                                <button class="btn btn-primary address-btn" *ngIf="billingAddressEditable"
                                    [disabled]="submitDisabled">{{'web.my_profile_cta_save' | translate}}
                                </button>

                                <div class="text-center">
                                    <small class="text-primary" id="showSavedMsg"
                                        *ngIf="showSavedProfileMsg">{{'web.my_profile_changes_saved' | translate}}</small>
                                </div>
                            </form>
                        </div>

                        <!-- <form action="" name="form" (ngSubmit)="myprofileForm.form.valid && openConfModal()" #myprofileForm="ngForm" novalidate>

                            <div class="row" *ngIf="isformDisabled">
                                <div class="col-12 col-sm-6">
                                    <label for="">{{'web.my_profile_first_name_label' | translate }}</label>
                                    <p>{{user.firstName}}</p>
                                </div>
                                <div class="col-12 col-sm-6">
                                    <label for="">{{'web.my_profile_last_name_label' | translate }}</label>
                                    <p>{{user.lastName}}</p>
                                </div>
                            </div>

                            <div class="row" *ngIf="isformDisabled">
                                <div class="col-7 col-sm-8 pe-10">
                                    <label for="">{{'web.my_profile_address_line1_label' | translate }}</label>
                                    <p>{{user.street}}</p>
                                </div>
                                <div class="col-5 col-sm-4 ps-10">
                                    <label for="">{{'web.my_profile_address_line2_label' | translate }}</label>
                                    <p>{{user.houseNumber}}</p>
                                </div>
                            </div>

                            <div class="row" *ngIf="isformDisabled">
                                <div class="col-4" *ngIf="charMaxLength != 0 && charMinLength != 0">
                                    <label for="">{{'web.my_profile_zip_label' | translate }}</label>
                                    <p>{{user.zip}}</p>
                                </div>
                                <div class="col-8">
                                    <label for="">{{'web.my_profile_city_label' | translate }}</label>
                                    <p>{{user.city}}</p>
                                </div>
                            </div>

                            <div class="row" *ngIf="isformDisabled">
                                <div class="col-12 col-sm-12">
                                    <label for="">{{'web.my_profile_country_label' | translate }}</label>
                                    <p>{{user.selectedCountry}}</p>
                                </div>
                            </div>


                            <div class="row">
                                <div class="col-4" [hidden]="!isformDisabled">
                                    <label for="">Vorwahl</label>
                                    <p [hidden]="!isPhoneNoInputDisabled">{{user.extension}}
                                    </p>
                                    <div class="d-flex align-items-center" [hidden]="isPhoneNoInputDisabled">
                                        <input type="text" class="form-control extension bg-transparent ps-0" [hidden]="isPhoneNoInputDisabled" placeholder="{{'web.register_country_code' | translate }}" name="extension" [(ngModel)]="user.extension" readonly />

                                    </div>
                                </div>
                                <div class="col-8" [hidden]="!isformDisabled">
                                    <label for="">{{'web.my_profile_phone_label' | translate }}</label>
                                    <p [hidden]="!isPhoneNoInputDisabled"> {{user.phoneNo}}
                                        <span class="edit cursor-pointer" title="{{'web.my_profile_phone_col_focus_tip' | translate }}" (click)="focusPh()"><img src="{{EDIT_ICON}}" alt="" title=""></span>
                                    </p>
                                    <div class="d-flex align-items-center" [hidden]="isPhoneNoInputDisabled">
                                        <input type="text" class="form-control phone-no mb-0" #onPhoneNoEdit [hidden]="isPhoneNoInputDisabled" placeholder="{{'web.registration_phone_format_placeholder' | translate }}" name="phoneNo" (keydown.space)="$event.preventDefault();" [ngClass]="{ 'error': (phoneNo.touched && phoneNo.invalid) || (myprofileForm.submitted && phoneNo.invalid)}"
                                            [(ngModel)]="user.phoneNo" #phoneNo="ngModel" pattern="^(?:(?:[1-9][0-9]*)|0)$" maxlength="13" minlength="8" />
                                        <span class="edit ms-2" [hidden]="isPhoneNoInputDisabled" title="{{'web.my_profile_phone_col_focus_tip' | translate }}" (click)="editPhoneNo(phoneNo)">
                                        
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div class="row" *ngIf="!isformDisabled">
                                <div class="col-12 col-sm-6">
                                    <label>{{'web.my_profile_first_name_label' | translate }}<span>*</span></label>
                                    <input type="text" placeholder="{{'web.register_field_label_fname' | translate }}" disabled="{{isInputDisabled}}" [ngClass]="{ 'error': (firstName.touched && firstName.invalid) || (myprofileForm.submitted && firstName.invalid)}" name="firstName" [(ngModel)]="user.firstName"
                                        maxLength="20" #firstName="ngModel" (keyup)="checkFirstName()" />
                                </div>

                                <div *ngIf="firstName.invalid && (firstName.dirty || firstName.touched)">
                                    <span class="text-danger" *ngIf="firstName.errors.pattern">{{'web.my_profile_first_name_msg'| translate }}</span>
                                </div>
                                <div class="col-12 col-sm-6">
                                    <label>{{'web.my_profile_last_name_label' | translate }}<span>*</span></label>
                                    <input type="text" placeholder="{{'web.register_field_label_lname' | translate }}" name="lastName" [(ngModel)]="user.lastName" #lastName="ngModel" maxLength="20" disabled="{{isInputDisabled}}" [ngClass]="{ 'error': (lastName.touched && lastName.invalid) || (myprofileForm.submitted && lastName.invalid)}"
                                        (keyup)="checkLastName()" />

                                </div>
                                <div *ngIf="lastName.invalid && (lastName.dirty || lastName.touched)">
                                    <span class="text-danger" *ngIf="lastName.errors.pattern">{{'web.my_profile_last_name_msg' |
                                        translate }}</span>
                                </div>
                            </div>
                            <div class="row" *ngIf="!isformDisabled">
                                <div class="col-sm-8 col-7 pe-10">
                                    <label for="">{{'web.my_profile_address_line1_label' | translate }}<span>*</span></label>
                                    <input type="road" name="street" #street="ngModel" [(ngModel)]="user.street" disabled="{{isformDisabled}}" [ngClass]="{ 'error': (street.touched && street.invalid) || (myprofileForm.submitted && street.invalid) || streetError}" (keyup)="checkStreet(street.value)"
                                        maxLength="20" placeholder="{{'web.register_field_label_street' | translate }}*">

                                    <div *ngIf="street.dirty && streetError" class="text-danger">
                                        {{'web.register_street_house_val' | translate }}
                                    </div>
                                </div>

                                <div class="col-sm-4 col-5 ps-10">
                                    <label for="">{{'web.my_profile_address_line2_label' | translate }}<span>*</span></label>
                                    <input type="road" name="houseNumber" #houseNumber="ngModel" disabled="{{isformDisabled}}" [(ngModel)]="user.houseNumber" (keyup)="checkHouse(houseNumber.value)" maxLength="20" [ngClass]="{ 'error': (houseNumber.touched && houseNumber.invalid) || (myprofileForm.submitted && houseNumber.invalid) || streetError}"
                                        placeholder="{{'web.register_field_label_house' | translate }}">

                                    <span *ngIf="houseNumber.dirty && streetError" class="text-danger">
                                        {{'web.register_street_house_val' | translate }}</span>
                                </div>
                            </div>

                            <div class="row" *ngIf="!isformDisabled">
                                <div class="col-4" *ngIf="charMaxLength != 0 && charMinLength != 0">
                                    <label for="">{{'web.my_profile_zip_label' | translate }}<span>*</span></label>
                                    <input type="road" name="zip" #zip="ngModel" [(ngModel)]="user.zip" disabled="{{isformDisabled}}" placeholder="{{'web.register_field_label_zip' | translate }}*" minlength="{{charMinLength}}" pattern="{{applyPattern}}" maxlength="{{charMaxLength}}" (keyup)="countPostalCode(zip.value)"
                                        [ngClass]="{ 'error': (zip.touched && zip.invalid) || (myprofileForm.submitted && zip.invalid)}">
                                    <p *ngIf="zip.invalid && (zip.dirty || zip.touched)" class="text-danger">
                                        <span *ngIf="zip.errors.pattern" class="text-danger">{{'web.myprofile_zip_val_pattern_wrong' | translate }}</span>
                                    </p>
                                    <p *ngIf="charLenIncorrect && zip.dirty" class="text-danger">
                                        {{'web.myprofile_zip_val_min_charac' | translate }} {{charMinLength}}</p>
                                    <p *ngIf="charLenIncorrect && zip.dirty" class="text-danger">
                                        {{'web.myprofile_zip_val_max_charac' | translate }} {{charMaxLength}}</p>
                                </div>
                                <div class="col-8">
                                    <label for="">{{'web.my_profile_city_label' | translate }}<span>*</span></label>
                                    <input type="road" name="city" #city="ngModel" [(ngModel)]="user.city" disabled="{{isformDisabled}}" placeholder="{{'web.register_field_label_city' | translate }}*" maxlength="30" [ngClass]="{ 'error': (city.touched && city.invalid) || (myprofileForm.submitted && city.invalid)}"
                                        (keyup)="checkCity()">
                                </div>
                            </div>

                            <div class="row" *ngIf="!isformDisabled">
                                <div class="col-12 col-sm-12">
                                    <label>{{'web.my_profile_country_label' | translate }}<span>*</span></label>
                                    <select name="country" [(ngModel)]="user.selectedCountry" #country="ngModel" (ngModelChange)="changeCountryAndSetExtn(user.selectedCountry)" disabled="{{isformDisabled}}" [ngClass]="{ 'error': (country.touched && country.invalid) || (myprofileForm.submitted && country.invalid)}"
                                        required>
                                        <option *ngFor="let country of arrCountries" [ngValue]="country.countryName">
                                            {{country.countryName}}</option>
                                    </select>
                                </div>
                            </div>

                            <div class="col-12 col-sm-12" *ngIf="!isformDisabled">
                                <div class="row">
                                    <div class="col-4">
                                        <label for="">Vorwahl</label>
                                    
                                        <input type="text" class="form-control bg-transparent" placeholder="{{'web.register_country_code' | translate }}" name="extension" [(ngModel)]="user.extension" readonly />
                                    </div>
                                    <div class="col-8">
                                        <label for="">{{'web.my_profile_phone_label' | translate }}<span>*</span></label>
                                        <input type="text" class="form-control" placeholder="{{'web.registration_phone_format_placeholder' | translate }}" name="phoneNo" (keydown.space)="$event.preventDefault();" [ngClass]="{ 'error': (phoneNo.touched && phoneNo.invalid) || (myprofileForm.submitted && phoneNo.invalid)}"
                                            [(ngModel)]="user.phoneNo" #phoneNo="ngModel" pattern="^(?:(?:[1-9][0-9]*)|0)$" maxlength="13" minlength="8" (keyup)="checkPhoneNumber()" />
                                    </div>
                                </div>

                                <div *ngIf="phoneNo.invalid && (phoneNo.dirty || phoneNo.touched)">
                                    <span class="text-danger" *ngIf="phoneNo.errors.pattern">{{'web.my_profile_val_phone' |
                                        translate }}</span>
                                </div>
                            </div>

                            <div class="discription pb-20 ">
                                {{'web.myprofile_update_restriction_msg' | translate }}
                                <a class="text-primary" routerLink="/{{contactusUrl}}">{{'web.myprofile_contact_link_txt' |
                                    translate}}</a>
                            </div>
                        
                            <button class="btn btn-primary btn-md w-100 d-block save-btn" *ngIf="!isPhoneNoInputDisabled " type="submit">{{'web.my_profile_cta_save' | translate}}</button>
                        </form> -->
                    </div>
                </div>

                <div class="referFriend" *ngIf="false">
                    <button class="btn btn-link collapse-head pb-20 px-0" type="button" data-bs-toggle="collapse"
                        data-bs-target="#collapseRefer" aria-expanded="false" aria-controls="collapseRefer">
                        <span>{{'web.myprofile_referal_tit' | translate }}</span>
                        <img src="{{imageIconPath}}arrow-up-16.svg" alt="" title="">
                    </button>
                    <div class="collapse show" id="collapseRefer">

                        <!-- <pre class="mb-0"><p class="pb-20">{{'web.myprofile_referal_msg' | translate }}</p></pre> -->

                        <p class="pb-20">{{'web.myprofile_referal_msg' | translate }}</p>
                        <!-- <span class="cursor-pointer pb-20">{{getprofiledetails.referalLink}}</span> -->
                        <span class="cursor-pointer pb-20 d-block text-underline">{{urlLink}}</span>
                        <button class="btn btn-primary btn-md w-100 d-block save-btn" *ngIf="!showLinkButton"
                            (click)="copyUrlLink(urlLink)">{{'web.myprofile_referal_copy' | translate }}</button>
                        <button class="btn btn-primary btn-md w-100 d-block save-btn" *ngIf="showLinkButton"
                            (click)="copyUrlLink(urlLink)">{{'web.myprofile_referal_copied' | translate }}</button>
                        <!-- myprofile_referal_copied -->
                    </div>
                </div>

                <div class="delete-accountInfo">

                    <p class="d-flex justify-content-end" *ngIf="newsletterApiValue == 1">
                        <span>{{'web.newsletter_subscription_date' | translate}}</span>
                        <span>{{newsletterDate}} </span>
                        <a class="ps-1" routerLink="/{{newsletterUnsubscribeUrl}}"> {{'web.unsubscribe_cta' | translate}}</a>
                    </p>
                    <p *ngIf="deleteAccountEnabled">
                        <a routerLink="/{{deleteProfileUrl}}">{{'web.myprofile_delete' | translate }}</a>
                    </p>
                </div>

            </div>
            <div class="account-image" *ngIf="user.userNumber">
                <img src="{{KontoImg}}" alt="" title="">
            </div>
        </div>


        <div class="modal" tabindex="-1" role="dialog" id="referalLink">
            <div class="modal-dialog modal-dialog-centered modal-md" role="document">
                <div class="modal-content p-3">
                    <div class="modal-header border-0 p-0 bg-transparent">
                        <h3 class="modal-title">{{'web.myprofile_referal_tit' | translate }}</h3>
                        <span aria-label="Close" class="cursor-pointer" data-bs-dismiss="modal">
                            <img alt="" class="w-100" title="" src="{{imageIconPath}}checkmark-cross-32.svg">
                        </span>
                    </div>
                    <div class="modal-body px-0 py-3">
                        <p class="text-start">{{'web.myprofile_referal_msg' | translate }}</p>
                        <span class="cursor-pointer pb-20 d-block text-underline">{{urlLink}}</span>
                    </div>
                    <div class="modal-footer border-0 align-items-start p-0">

                        <button class="btn btn-md w-100 btn-primary" *ngIf="!showLinkButton"
                            (click)="copyUrlLink(urlLink)">{{'web.myprofile_referal_copy' | translate }}</button>
                        <button class="btn btn-md w-100 btn-primary" data-bs-dismiss="modal" *ngIf="showLinkButton"
                            (click)="copyUrlLink(urlLink)">{{'web.myprofile_referal_copied' | translate }}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
