<div class="main-content text-center mt-md-5 w-90">
    <div class="container">
        <h3 class="main_title ps-3">{{'web.unsubscribe_title' | translate}}</h3>
        <p  class="sub_title ps-3">{{'web.unsubscribe_text' | translate}}</p>
        <div class="content">
            <select [(ngModel)]="selectedOption" (ngModelChange)="updateReason(selectedOption)">
                <option value="{{defaultSelectedOption}}" readonly selected disabled>{{defaultSelectedOption}}</option>
                <option value="{{'web.unsubscribe_drop_down_option_1' | translate}}">{{'web.unsubscribe_drop_down_option_1' | translate}}</option>
                <option value="{{'web.unsubscribe_drop_down_option_2' | translate}}">{{'web.unsubscribe_drop_down_option_2' | translate}}</option>
                <option value="{{'web.unsubscribe_drop_down_option_3' | translate}}">{{'web.unsubscribe_drop_down_option_3' | translate}}</option>
                <option value="{{'web.unsubscribe_drop_down_option_4' | translate}}">{{'web.unsubscribe_drop_down_option_4' | translate}}</option>
                <option value="{{'web.unsubscribe_drop_down_option_5' | translate}}">{{'web.unsubscribe_drop_down_option_5' | translate}}</option>
                <option value="{{'web.unsubscribe_drop_down_option_6' | translate}}">{{'web.unsubscribe_drop_down_option_6' | translate}}</option>
                <option value="NoReson">{{'web.unsubscribe_drop_down_option_7' | translate}}</option>
            </select>
            <span *ngIf="isOptionSelected" class="text-danger">
                {{'web.unsubscribe_drop_down_select_option_msg' | translate}}
            </span>
            <div [hidden]="!isDisplayTextArea">
                <textarea [(ngModel)]="other1" id="text-Box" placeholder="{{'web.unsubscribe_comment_box' | translate}}" (blur)="getReason1(other1)"></textarea>
            </div>
            <!-- <input placeholder="{{user.email}}" name="userEmail" [(ngModel)]="user.email" type="text" readonly>
            <div class="action-btn">
                <a class="btn btn-primary" (click)=goToMyprofile()>{{'web.unsubscribe_cta_cancel' | translate}}</a>
                <a class="btn btn-light" (click)="unsubscribeLetter()">{{'web.unsubscribe_cta' | translate}}</a>
            </div>  -->    
            
            <!-- <input id="emailID" emailauto type="text" class="form-control mb-0" name="email" placeholder="{{'web.non_verified_user_register_field_label_email' | translate }}" 
            [ngClass]="{ 'error': (email.touched && email.errors) || (guestRegistrationForm.submitted && email.invalid)}"
                        [(ngModel)]="user.email" #email="ngModel" pattern="^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$" (blur)="retryUserNameOrEmail(1)" (keydown.Tab)="setFocus(500)" required> -->
          

                        
            <input #autoFocusEnabled [disabled]="showInputEnabled" type="email" [(ngModel)]="user.email" id="userEmail" class="form-control"
             name="userEmail" [placeholder]="user.email ? user.email : 'Please enter your Email'" placeholder="{{user.email}}"#userEmail="ngModel" pattern="[\w-]+(\.[\w-]+)*@[a-z0-9]+([.-][a-z0-9]+)*\.[a-z]{2,6}(:\d{4})?$"
                [ngClass]="{ 'error': userEmail.touched && userEmail.invalid}"
                required /> 

                <div class="error-msg" *ngIf="userEmail.dirty || userEmail.touched">
                    <span *ngIf="userEmail.invalid && userEmail.errors.pattern" class="text-danger">{{'web.register_val_invalid_email' | translate  }}</span>
                </div>

                <div class="action-btn">
                    <a class="btn btn-primary" type="submit" (click)=goToMyprofile()>{{'web.unsubscribe_cta_cancel' | translate}}</a>
                    <a class="btn btn-light" type="submit" (click)="unsubscribeLetter()">{{'web.unsubscribe_cta' | translate}}</a>
                </div> 
    
            
            <p class="text-success">{{apiMsg}}</p>
        </div>
    </div>
</div>