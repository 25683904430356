import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from 'src/app/services/data.service';
import { GlobalService } from 'src/app/services/global.service';
import * as global from 'src/app/globals';
import { DynamicPopupsModalComponent } from '../dynamic-popups-modal/dynamic-popups-modal.component';
import { Observable, ReplaySubject, Subscription } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ComponentCanDeactivate } from 'src/app/guard/auth-guard.service';
import { ContentfulService } from 'src/app/services/contentful.service';
import { RegisterComponent } from 'src/app/user/register/register.component';
import { environment } from 'src/environments/environment';
@Component({
    selector: 'app-deleteprofile',
    templateUrl: './deleteprofile.component.html',
    styleUrls: ['./deleteprofile.component.scss'],
    standalone: false
})
export class DeleteprofileComponent implements OnInit, ComponentCanDeactivate {
    imageIconPath: any;
    fromApp: boolean = false;
    mobileType: string;

    canDeactivate(): Observable<boolean> | boolean {
        return true
    }
    @ViewChild('password') password: any;
    isApiLoading: Boolean;
    passwordIncorrect: Boolean;
    passwordCorrect: Boolean;
    userInfo: any;
    userEmail: any;
    afterApiHit: Boolean;
    reason: any;
    otherReason: Boolean;
    userPassword: any = '';
    dicUrls: any;
    profilePageUrl: any;
    languageData: any;
    languageDataSubscription: Subscription;
    deleteUserModal: any;
    allInOnePageSections: any = [];
    deviceType: any;
    contentType: string = 'page';
    isHeaderLoaded: boolean = false;
    userisLoggedIn: boolean = false;
    private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
    constructor(private dataService: DataService, private modalService: NgbModal, private activeRoute: ActivatedRoute, private globalService: GlobalService, private router: Router, private contentfulService: ContentfulService) {
        window.onbeforeunload = function () { window.scrollTo(0, 0); }

        let url = this.router.url.split('?')[0];
        let arrUrl = url.split('/');
        // 

        this.contentfulService.$isUserLoggedIn
            .pipe(takeUntil(this.destroyed$))
            .subscribe((response) => {
                if (response === true) {
                    this.userisLoggedIn = true
                    //  this.isHeaderLoaded = true;
                    //  
                    let url = this.router.url.split('?')[0];
                    let arrUrl = url.split('/');
                    let pageSlug;//decodeURIComponent(arrUrl[1]);


                    if (url.charAt(0) === '/') {
                        pageSlug = url.substring(1);
                    } else {
                        pageSlug = url;
                    }

                    if (arrUrl && arrUrl[2] == 'delete-account') {
                        this.userEmail = localStorage.getItem("userInfo") ? JSON.parse(localStorage.getItem("userInfo")).Email : undefined;
                        if (this.globalService.gettLanguageData() != undefined) {
                            this.languageData = this.globalService.gettLanguageData();
                            this.login(this.userEmail)



                            if (pageSlug) {
                                this.getDataFromContentFul(pageSlug);
                            }
                        }
                        else {
                            this.languageDataSubscription = this.globalService.languageDataEmiter.subscribe(data => {
                                if (data) {
                                    this.languageData = data;
                                    this.login(this.userEmail)
                                    if (pageSlug) {
                                        this.getDataFromContentFul(pageSlug);
                                    }
                                }
                            });
                        }

                    }







                }
            });
    }

    ngOnInit() {

     

          if(navigator.userAgent.match(/Android/i)){
            this.mobileType='ANDROID';

          }else if(navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPad/i)
          || navigator.userAgent.match(/iPod/i)){
            this.mobileType='IOS';

          }

        window.scrollTo(0, 0)
        this.dicUrls = this.globalService.getStaticPagesUrls(global.env, global.lang);
        this.profilePageUrl = this.dicUrls['ACCOUNT_PREVIEW'];

        if (localStorage.getItem('userInfo') == null) {
            let data = { type: "OPEN_LOGIN" };
            this.userisLoggedIn = false;

            // this.globalService.setOpenRegister(data);
            this.showSignUpModal('')
            if (localStorage.getItem('cookieMsgVisible') != null) {
                //  let data = { type: "OPEN_LOGIN" };
                // this.globalService.setOpenLogin(data);
            }
        }
        else {
            this.userisLoggedIn = true
            this.passwordIncorrect = false;
            this.afterApiHit = false;
            this.otherReason = false;
            this.userEmail = localStorage.getItem("userInfo") ? JSON.parse(localStorage.getItem("userInfo")).Email : undefined;

            var localData = JSON.parse(localStorage.getItem("userInfo"));

            this.isApiLoading = false;
            var confCode = this.activeRoute.snapshot.params.confirmationCode;
            this.dicUrls = this.globalService.getStaticPagesUrls(global.env, global.lang);
            this.profilePageUrl = this.dicUrls['ACCOUNT_PREVIEW'];
            if (this.globalService.gettLanguageData() != undefined) {
                this.languageData = this.globalService.gettLanguageData();

            }
            else {
                this.languageDataSubscription = this.globalService.languageDataEmiter.subscribe(data => {
                    if (data) {
                        this.languageData = data;
                    }
                });
            }

            if (confCode) {
                //     HttpServiceCall.deleteAccountConfirm(confCode).then(function(response){
                //     $rootScope.deleteConfCode = confCode;
                //     $scope.logout();


                //     if(response.success == true)
                //     {
                //        $rootScope.deleteAccResMsg = response.message+"_success";
                //        
                var myItem = localStorage.getItem('interacted');
                localStorage.clear();
                localStorage.setItem('interacted', myItem);


                //        $state.go('home');
                //     }
                //     else
                //     {
                //       $rootScope.deleteAccResMsg = response.error.detail +"_error";
                //       
                var myItem = localStorage.getItem('interacted');
                localStorage.clear();
                localStorage.setItem('interacted', myItem);


                //       $state.go('home');
                //     }
                //  });
            }
        }

        let url = this.router.url.split('?')[0];
        let arrUrl = url.split('/');
        let pageSlug;//decodeURIComponent(arrUrl[1]);


        if (url.charAt(0) === '/') {
            pageSlug = url.substring(1);
        } else {
            pageSlug = url;
        }

        if (pageSlug) {
            this.getDataFromContentFul(pageSlug);
        }

        this.imageIconPath = environment[global.env].RESOURCE_URL_AMAZON + environment[global.env].RESOURCE_CONTAINER + environment[global.env].oemCode + "/icons/";
    }
    removeLeadingSlash(str) {

        return str;
    }

    login(userEmail, fromButton = false) {
        if (this.isApiLoading) {
            return;
        }
        var userPass = this.userPassword;



        if (userPass) {
            if (userEmail == undefined || userEmail == null || userEmail == '' || (localStorage.getItem('userInfo') == null)) {
                this.showSignUpModal('');
                return false;
            }
            this.passwordIncorrect = false;
            this.passwordCorrect = false;
            var userLowerCase = userEmail.toLowerCase();
            this.isApiLoading = true;
            this.dataService.login(userLowerCase, userPass).subscribe((res: Response) => {
                let resSTR = JSON.stringify(res);
                let resJSON = JSON.parse(resSTR);

                if (resJSON.success == true) {
                    this.passwordIncorrect = false;
                    this.passwordCorrect = true;
                    this.isApiLoading = false;
                    this.opemDeleteAccountModal();
                }
                else {
                    this.passwordIncorrect = true;
                    this.passwordCorrect = false;
                    this.isApiLoading = false;
                }
            });
        }
        else {

            if (fromButton) {
                this.passwordIncorrect = true;
            }else{
                if (this.userPassword == undefined || this.userPassword == '') {
                    this.passwordIncorrect = false;
                } else {
                    this.passwordIncorrect = true;
                }
            }


        }
    }
    showSignUpModal(data) {
        const modalRef = this.modalService.open(RegisterComponent, { size: 'md', windowClass: 'modalWindowWidth', centered: true, backdrop: 'static', keyboard: false });
        modalRef.result.then((result) => {
        }).catch((error) => {
        });
        modalRef.componentInstance.productdata = '';
    }
    opemDeleteAccountModal() {
        // 

        let title = this.languageData.web.delete_account_cofirmation_popup_tit;
        let subTitle = this.languageData.web.delete_account_cofirmation_popup_txt;
        let cancelBtn = this.languageData.web.delete_account_cofirmation_popup_cta2;
        let okBtn = this.languageData.web.delete_account_cofirmation_popup_cta1;
        const modalRef = this.modalService.open(DynamicPopupsModalComponent, { size: 'md', windowClass: 'modalWindowWidth', centered: true, backdrop: 'static', keyboard: false });
        modalRef.componentInstance.data = {
            title: title, subTitle: subTitle, cancelBtnTxt: cancelBtn, okBtnTxt: okBtn, modalName: 'deleteAccount', cancelIcon: false,
        };
        this.deleteUserModal = modalRef;
        modalRef.componentInstance.deleteAccountData.subscribe((res) => {
            if (res) {
                this.deleteAccount();
            }
        })
    }

    redirectMyProfile() {
        if (this.isApiLoading) {
            return;
        }
        //  
        //this.router.navigate([this.profilePageUrl]);
        this.router.navigate([this.profilePageUrl])
            .then(() => {
                window.location.reload();

            });
    }

    deleteAccount() {
        var email = this.userEmail;
        var reason = this.reason;
        this.isApiLoading = true;

        // this.deleteUserModal.close();




        this.dataService.deleteUserProfile(email, reason).subscribe((res: Response) => {
            let resSTR = JSON.stringify(res);
            let resJSON = JSON.parse(resSTR);
            this.afterApiHit = true;
            this.deleteUserModal.close();
            if (resJSON.success == true) {
                this.isApiLoading = false;
                localStorage.removeItem('userInfo');
                this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
                this.globalService.setUserData(this.userInfo);
                this.globalService.setCartLengthData(0);
                localStorage.setItem('reload', 'true');



                let title = this.languageData.web.delete_account_success_popup_tit;
                let subTitle = this.languageData.web.delete_account_success_popup_message;
                let okBtn = this.languageData.web.delete_account_success_popup_ok;
                const modalRef = this.modalService.open(DynamicPopupsModalComponent, { size: 'md', windowClass: 'modalWindowWidth', centered: true, backdrop: 'static', keyboard: false });
                modalRef.componentInstance.data = {
                    title: title, subTitle: subTitle, okBtnTxt: okBtn, modalName: 'deleteAccountSuccess', cancelIcon: false,
                };
                modalRef.componentInstance.deleteAccountSuccessData.subscribe((res) => {
                    if (res) {
                        modalRef.close();
                        // this.logout();

                        if (this.fromApp) {

                            if(this.mobileType=='IOS'){
                                window['webkit'].messageHandlers.close_window.postMessage("close_user_delete_window_success");
                            }else if(this.mobileType=='ANDROID'){
                                window['close_webViewWindow'].postMessage("close_user_delete_window_success");
                            }else{
                                this.router.navigate(['/'])
                                .then(() => {
                                    window.location.reload();

                                });
                            }
                           
                        }
                        else {
                            this.router.navigate(['/'])
                                .then(() => {
                                    window.location.reload();

                                });
                        }

                    }
                })
                return false
            }
            else {
                this.isApiLoading = false;
                let title = this.languageData.web.delete_account_fail_popup_tit;
                let subTitle = this.languageData.web.delete_account_fail_popup_message;
                let okBtn = this.languageData.web.delete_account_fail_popup_ok;
                const modalRef = this.modalService.open(DynamicPopupsModalComponent, { size: 'md', windowClass: 'modalWindowWidth', centered: true, backdrop: 'static', keyboard: false });
                modalRef.componentInstance.data = {
                    title: title, subTitle: subTitle, okBtnTxt: okBtn, modalName: 'deleteAccountFailed', cancelIcon: false,
                };
                modalRef.componentInstance.deleteAccountData.subscribe((res) => {
                    if (res) {
                        modalRef.close();
                        if (this.fromApp) {

                            if(this.mobileType=='IOS'){
                                window['webkit'].messageHandlers.close_window.postMessage("close_user_delete_window_fail");
                            }else if(this.mobileType=='ANDROID'){
                                window['close_webViewWindow'].postMessage("close_user_delete_window_fail");
                            }else{
                                this.redirectMyProfile();
                            }



                          //  window['webkit'].messageHandlers.close_window.postMessage("close_user_delete_window_fail");
                        } else {
                            this.redirectMyProfile();
                        }

                    }
                })
            }
        });
    }
    closeFromApp() {
       // window['webkit'].messageHandlers.close_window.postMessage("close_user_delete_window_cancel");

        if(this.mobileType=='IOS'){
            window['webkit'].messageHandlers.close_window.postMessage("close_user_delete_window_cancel");
        }else if(this.mobileType=='ANDROID'){
            window['close_webViewWindow'].postMessage("close_user_delete_window_cancel");
        }else{
            this.redirectMyProfile()
        }
    }

    updateReason(reason, index) {
        if (index != 4) {
            this.otherReason = false;
            this.reason = reason;
        }
        else {
            this.otherReason = true;
        }
    }

    getReason(reason) {
        this.reason = reason;
    }

    logout() {
        localStorage.removeItem('userInfo');
        this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
        this.globalService.setUserData(this.userInfo);
        this.router.navigate(['/']);
        this.globalService.setCartLengthData(0);
        localStorage.setItem('reload', 'true');
    }

    getDataFromContentFul(pageSlug) {
        let urlString = pageSlug.split('/')
        //  
        if (urlString && urlString[urlString.length - 1] == 'app') {
            setTimeout(() => {
                this.isHeaderLoaded = true;
                this.fromApp = true;
            }, 2000);
        }

        this.contentfulService.getContentByFieldName(this.contentType, pageSlug).then(data => {
            if (data) {
                // 


                if (data.length == 0) {
                    this.router.navigateByUrl('/404');
                    return;
                }
                if (data.length > 0 && data[0].fields['seoMetadata']) {
                    let seoData = {};
                    seoData = data[0].fields['seoMetadata'].fields;
                    this.globalService.setSeoMetaData(seoData);
                    this.contentfulService.emittranslatedUrl(data[0].fields['seoMetadata'].sys.id);


                }

                if (data[0] && (data[0].fields['topSection'] || data[0].fields['pageContent'])) {

                    this.allInOnePageSections = [];
                    if (data[0] && data[0].fields['topSection'] != undefined) {
                        let topSection = JSON.parse(JSON.stringify(data[0].fields['topSection']));
                        topSection.map((item, index) => {
                            if (item.fields) {

                                if (
                                    (this.deviceType == 'Mobile' && (item.fields.displayOn == undefined || item.fields.displayOn == 'Desktop and Mobile' || item.fields.displayOn == 'Mobile'))
                                    ||
                                    (this.deviceType == 'Desktop' && (item.fields.displayOn == undefined || item.fields.displayOn == 'Desktop and Mobile' || item.fields.displayOn == 'Desktop'))
                                ) {
                                    if (item.fields.heroBannerDesignVersion == 'Gallery-1-full-v1') {
                                        item.fields.sliderSection = 'topSection';
                                    }
                                    this.allInOnePageSections.push(item);

                                }


                            }

                        })
                    }
                    else {
                        data[0].fields['topSection'] = [];
                    }
                    if (data[0] && data[0].fields['pageContent'] != undefined) {

                        let pageContent = JSON.parse(JSON.stringify(data[0].fields['pageContent']));
                        pageContent.map((item, index) => {

                            if (item.fields) {

                                if (
                                    (this.deviceType == 'Mobile' && (item.fields.displayOn == undefined || item.fields.displayOn == 'Desktop and Mobile' || item.fields.displayOn == 'Mobile'))
                                    ||
                                    (this.deviceType == 'Desktop' && (item.fields.displayOn == undefined || item.fields.displayOn == 'Desktop and Mobile' || item.fields.displayOn == 'Desktop'))
                                ) {
                                    if (item.fields.heroBannerDesignVersion == 'Gallery-1-full-v1') {
                                        item.fields.sliderSection = 'pageContentSection';
                                    }

                                    this.allInOnePageSections.push(item);


                                }


                            }

                        })
                    }
                    else {
                        data[0].fields['pageContent'] = [];
                    }


                    let urlString = pageSlug.split('/')
                    // 
                    if (urlString && urlString[urlString.length - 1] == 'app') {
                        setTimeout(() => {
                            this.isHeaderLoaded = true;
                        }, 2000);
                    }
                    this.prepareToLoad(data);
                    this.contentfulService.emitFooterAtLast(false);
                }

            }
        })
    }
    prepareToLoad(data) {
        let headerObject = {
            headerVersion: false,
            headerData: false
        }
        let footerObject = {
            footerVersion: false,
            footerData: false
        }

        if (this.userisLoggedIn) {
            if (data[0].fields['topSection']) {
                let allComponentFilter = JSON.parse(JSON.stringify(data[0].fields['topSection']))
                let headerFound = allComponentFilter.filter(item => item.fields && item.fields.selectDesign);


                if (headerFound.length > 0) {
                    let headerData = headerFound[0];
                    if (headerData.fields.selectDesign) {
                        let headerObject = {
                            headerVersion: headerData.fields.selectDesign.split('-')[2],
                            headerData
                        }
                        ////
                        this.contentfulService.emitHeaderData(headerObject)

                    }
                } else {
                    this.contentfulService.emitHeaderData(headerObject)
                }
                setTimeout(() => {
                    this.isHeaderLoaded = true;
                }, 2000);



            } else {
                this.contentfulService.emitHeaderData(headerObject)
            }

            if (data[0].fields['pageFooter']) {

                let allComponentFilter = JSON.parse(JSON.stringify(data[0].fields['pageFooter']))
                let footerFound = allComponentFilter.filter(item => item.fields && item.fields.selectDesign);


                if (footerFound.length > 0) {
                    let footerData = footerFound[0];
                    if (footerData.fields.selectDesign) {
                        let footerObject = {
                            footerVersion: footerData.fields.selectDesign.split('-')[2],
                            footerData
                        }
                        ////
                        this.contentfulService.emitFooterData(footerObject)

                    }
                } else {
                    this.contentfulService.emitFooterData(footerObject)
                }


            } else {
                this.contentfulService.emitFooterData(footerObject)
            }
        } else {
            this.contentfulService.emitHeaderData(headerObject)
            this.contentfulService.emitFooterData(footerObject)
        }

    }
}
