<div class="cart-page-content w-90" #container>
    <div class="page-title">
        <div class="text-start">
            <h3 class="main-title">{{'web.my_profile_tit' | translate }}</h3>
            <div class="sub-title">{{'web.my_profile_sub_tit' | translate}}</div>
        </div>
    </div>
    <!-- <div class="profile-title">
        <p>{{'web.myprofile_sub_header_tit' | translate }}</p>
    </div> -->

    <div class="container">
        <div class="row">
            <div class="wrap" [hidden]="!isDataLoading">
                <div class="loader">
                    <video playsinline autoplay muted src="{{colourfulSpinner}}"></video>
                </div>
            </div>
            <div class="wrap" [hidden]="isDataLoading">
                <div class="email-confirmation">
                    <p class="text-success" *ngIf="apiMsg">{{apiMsg}}</p>
                    <p [ngStyle]="styleClassApiMsg" *ngIf="showMsgFlag && userProfile.isConfirmed == 'No'">{{confirmationMsg}}</p>
                    <p class="text-success" *ngIf="succesMessage">{{succesMessage}}</p>
                    <div *ngIf="displayResendVerifyEmailLink">
                        <p>{{'web.my_profile_resend_verification_email_txt' | translate }}</p>
                        <div class="action-button">
                            <button class="btn btn-primary btn-lg" (click)="verifyEmail()">{{'web.my_profile_resend_verification_email_link' | translate}}</button>
                        </div>
                    </div>
                </div>
                <div class="newletter" *ngIf="newsletterApiValue == 0">
                    <img src="{{bannerImage}}" alt="" title="" />
                    <div class="content">
                        <h3 class="title">{{'web.my_profile_newsletter_section_tit' | translate}}</h3>
                        <div class="bullets">
                            <p>{{'web.my_profile_newsletter_section_bullet_1' | translate}}</p>
                            <p>{{'web.my_profile_newsletter_section_bullet_2' | translate}}</p>
                            <p>{{'web.my_profile_newsletter_section_bullet_3' | translate}}</p>
                        </div>
                        <button class="btn btn-primary" (click)="newsletterSubscribe()">{{'web.my_profile_newsletter_section_cta_txt' | translate}}</button>
                        <small class="terms">{{'web.my_profile_newsletter_section_terms' | translate}}</small>
                    </div>
                </div>

                <div *ngIf="!displayForgotPasswordBtn">
                    <div class="changepassword">
                        <p>{{'web.my_profile_change_password_text' | translate}}</p>
                        <button class="btn btn-primary" type="button" (click)="changePassword()">{{'web.my_profile_change_pass_tit' | translate }}</button>
                    </div>
                    <div *ngIf="resetPasswordSuccess">
                        <h4 class="text-success">{{ message }}</h4>
                    </div>
                    <div id="passwordChange" class="password-wrap" [hidden]="!isDisplayPasswordSection">
                        <div class="heading_wrap">
                            <h4 class="text-danger" *ngIf="!resetPasswordSuccess">{{ message }}</h4>
                        </div>
                        <form name="form" #changePasswordForm="ngForm" novalidate>
                            <div class="input-wrap">
                                <label>{{'web.my_profile_current_change_pass' | translate }}<span>*</span></label>
                                <input type="password" placeholder="{{'web.my_profile_current_pass' | translate }}" name="oldpassword" #oldpassword="ngModel" (keyup.enter)="resetPassword()" [(ngModel)]="user.oldPassword" (keyup)="checkPassword('oldpassword')" [ngClass]="{ 'error': (oldpassword.touched && oldpassword.invalid) || showError}"
                                    required />
                            </div>
                            <div *ngIf="isPasswordWrong" class="text-danger">{{'web.my_profile_val1_old_pass' | translate }}</div>
                            <div class="input-wrap">
                                <label>{{'web.my_profile_new_change_pass1' | translate }}<span>*</span></label>
                                <input type="password" (keyup.enter)="resetPassword()" placeholder="{{'web.my_profile_new_pass' | translate }}" name="newPassword" [ngClass]="{ 'error': (newPassword.touched && newPassword.invalid) || showError}" #newPassword="ngModel" [(ngModel)]="user.newPassword"
                                    (keyup)="checkPassword(1); passFormat(1)" maxlength="20" required />
                            </div>
                            <div *ngIf="isPasswordMatched" class="text-danger">{{'web.my_profile_pass_val_old' | translate }}</div>
                            <div *ngIf="invalidPassword1 && newPassword.touched" class="text-danger">
                                {{'web.my_profile_val_invalid_pass' | translate }}
                            </div>
                            <div class="input-wrap">
                                <label>{{'web.my_profile_new_change_pass2' | translate }}<span>*</span></label>
                                <input type="password" (keyup.enter)="resetPassword()" placeholder="{{'web.my_profile_confirm_pass' | translate }}" name="matchPassword" [ngClass]="{ 'error': (matchPassword.touched && matchPassword.invalid) || showError}" (keyup)="checkPassword(2); passFormat(2)"
                                    maxlength="20" [(ngModel)]="user.matchPassword" #matchPassword="ngModel" required />
                            </div>
                            <div *ngIf="invalidPassword2" class="text-danger">{{'web.register_val_pass_match' | translate }}</div>
                            <div class="action-btn">
                                <button class="btn btn-primary" (click)="cancleSave(2)">{{'web.my_profile_cta_cancel' | translate}}</button>
                                <button class="btn btn-primary" type="submit" (click)="resetPassword()">{{'web.my_profile_cta_save' | translate}}</button>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="profile-wrap" [hidden]="isDataLoading">
                    <div *ngIf="updateSuccessMsg">
                        <p class="text-success">{{'web.my_profile_update_success_msg' | translate }}</p>
                    </div>
                    <div *ngIf="emailUpdateSuccess">
                        <p class="text-success">{{'web.api_val_update_email_link_success' | translate }}</p>
                    </div>
                    <div *ngIf="emailAlreadyExists">
                        <p class="text-success">{{'web.api_val_email_update_email_exist' | translate }}</p>
                    </div>
                    <div *ngIf="emailSendingFail">
                        <p class="text-success">{{'web.api_val_email_update_link_failure' | translate }}</p>
                    </div>
                    <div>
                        <form name="form" (ngSubmit)="myprofileForm.form.valid && openConfModal()" #myprofileForm="ngForm" novalidate>
                            <h3 class="title">{{'web.my_profile_personal_info_text' | translate}}</h3>
                            <div class="row pb-2" *ngIf="isformDisabled">
                                <div class="col-12 col-sm-6">
                                    <label>{{'web.my_profile_first_name_label' | translate }}</label>
                                    <p>{{user.firstName}}</p>
                                </div>
                                <div class="col-12 col-sm-6">
                                    <label>{{'web.my_profile_last_name_label' | translate }}</label>
                                    <p>{{user.lastName}}</p>
                                </div>
                            </div>
                            <div class="row" *ngIf="!isformDisabled">
                                <div class="col-12 col-sm-6">
                                    <label>{{'web.my_profile_first_name_label' | translate }}<span>*</span></label>
                                    <input type="text" placeholder="{{'web.register_field_label_fname' | translate }}" disabled="{{isInputDisabled}}" [ngClass]="{ 'error': (firstName.touched && firstName.invalid) || (myprofileForm.submitted && firstName.invalid)}" name="firstName" [(ngModel)]="user.firstName"
                                        maxLength="20" #firstName="ngModel" required />
                                </div>
                                <div *ngIf="firstName.invalid && (firstName.dirty || firstName.touched)">
                                    <span class="text-danger" *ngIf="firstName.errors.pattern">{{'web.my_profile_first_name_msg' | translate }}</span>
                                </div>
                                <div class="col-12 col-sm-6">
                                    <label>{{'web.my_profile_last_name_label' | translate }}<span>*</span></label>
                                    <input type="text" placeholder="{{'web.register_field_label_lname' | translate }}" required name="lastName" [(ngModel)]="user.lastName" #lastName="ngModel" maxLength="20" disabled="{{isInputDisabled}}" [ngClass]="{ 'error': (lastName.touched && lastName.invalid) || (myprofileForm.submitted && lastName.invalid)}"
                                    />
                                </div>
                                <div *ngIf="lastName.invalid && (lastName.dirty || lastName.touched)">
                                    <span class="text-danger" *ngIf="lastName.errors.pattern">{{'web.my_profile_last_name_msg' | translate }}</span>
                                </div>
                            </div>
                            <div class="row pb-2">
                                <div class="col-12 col-sm-6">
                                    <label>{{'web.my_profile_user_number' | translate}}</label>
                                    <p>{{user.userNumber}}</p>
                                </div>
                                <div class="col-12 col-sm-6" [hidden]="!isEmailInputDisabled">
                                    <label>{{'web.my_profile_user_email_label' | translate }}<span>*</span></label>
                                    <p>{{user.userName}}
                                        <span [hidden]="isEmailConfirmed" class="edit" title="{{'web.my_profile_email_col_focus_tip' | translate }}" (click)="focus()">
                                            <img src="{{EDIT_ICON}}" alt="" title="">
                                        </span>
                                    </p>

                                </div>
                                <div *ngIf="isEmailConfirmed" class="text-success">
                                    {{'web.my_profile_email_verification_msg' | translate }}
                                </div>
                                <div class="col-12 col-sm-6" [hidden]="isEmailInputDisabled">
                                    <label>{{'web.my_profile_user_email_label' | translate }}<span>*</span></label>
                                    <div class="d-flex align-items-center">
                                        <input type="text" #onEdit class="form-control mb-0" name="email" #input [(ngModel)]="user.userName" #email="ngModel" [disabled]="isEmailConfirmed" placeholder="{{'web.my_profile_email' | translate}}" [ngClass]="{ 'error': (email.touched && email.invalid) || (myprofileForm.submitted && email.invalid), 'disabled':isEmailConfirmed}"
                                            pattern='[\w-]+(\.[\w-]+)*@[a-z0-9]+([.-][a-z0-9]+)*\.[a-z]{2,6}(:\d{4})?$' required />
                                        <span [hidden]="isEmailConfirmed" class="edit ms-2" title="{{'web.my_profile_email_col_focus_tip' | translate }}" (click)="editEmail()"><img
                                                src="{{OK_ICON}}">
                                        </span>
                                    </div>
                                    <div *ngIf="email.invalid && (email.dirty || email.touched)">
                                        <span class="text-danger" *ngIf="email.errors.required && emailReq">{{'web.my_profile_missing_email' | translate }}</span>
                                        <span class="text-danger" *ngIf="email.errors.pattern">{{'web.my_profile_invalid_email' | translate }}</span>
                                        <span class="text-danger" *ngIf="email.errors && emailInvalid">
                                            {{'my_profile_invalid_email' | translate }}</span>
                                    </div>
                                    <div *ngIf="isEmailConfirmed" class="text-success">
                                        {{'web.my_profile_email_verification_msg' | translate }}
                                    </div>
                                </div>
                            </div>
                            <div class="row pb-2" *ngIf="isformDisabled">
                                <div class="col-12 col-sm-6">
                                    <label>{{'web.my_profile_address_line1_label' | translate }}</label>
                                    <p>{{user.street}}</p>
                                </div>
                                <div class="col-12 col-sm-6">
                                    <label>{{'web.my_profile_address_line2_label' | translate }}</label>
                                    <p>{{user.houseNumber}}</p>
                                </div>
                            </div>
                            <div class="row" *ngIf="!isformDisabled">
                                <div class="col-12 col-sm-6">
                                    <label>{{'web.my_profile_address_line1_label' | translate }}<span>*</span></label>
                                    <input type="road" name="street" #street="ngModel" [(ngModel)]="user.street" disabled="{{isformDisabled}}" [ngClass]="{ 'error': (street.touched && street.invalid) || (myprofileForm.submitted && street.invalid) || streetError}" (keyup)="checkStreet(street.value)"
                                        maxLength="20" placeholder="{{'web.register_field_label_street' | translate }}*" required>
                                    <div *ngIf="street.dirty && streetError" class="text-danger">
                                        {{'web.register_street_house_val' | translate }}
                                    </div>
                                </div>
                                <div class="col-12 col-sm-6">
                                    <label>{{'web.my_profile_address_line2_label' | translate }}<span>*</span></label>
                                    <input type="road" name="houseNumber" #houseNumber="ngModel" disabled="{{isformDisabled}}" [(ngModel)]="user.houseNumber" (keyup)="checkHouse(houseNumber.value)" maxLength="20" [ngClass]="{ 'error': (houseNumber.touched && houseNumber.invalid) || (myprofileForm.submitted && houseNumber.invalid) || streetError}"
                                        placeholder="{{'web.register_field_label_house' | translate }}" required>
                                    <span *ngIf="houseNumber.dirty && streetError" class="text-danger">
                                        {{'web.register_street_house_val' | translate }}</span>
                                </div>
                            </div>
                            <div class="row pb-2" *ngIf="isformDisabled">
                                <div class="col-12 col-sm-6" *ngIf="charMaxLength != 0 && charMinLength != 0">
                                    <label>{{'web.my_profile_zip_label' | translate }}</label>
                                    <p>{{user.zip}}</p>
                                </div>

                                <div class="col-12 col-sm-6">
                                    <label>{{'web.my_profile_city_label' | translate }}</label>
                                    <p>{{user.city}}</p>
                                </div>
                            </div>
                            <div class="row" *ngIf="!isformDisabled">
                                <div class="col-12 col-sm-6" *ngIf="charMaxLength != 0 && charMinLength != 0">
                                    <label>{{'web.my_profile_zip_label' | translate }}<span>*</span></label>
                                    <input type="road" name="zip" #zip="ngModel" [(ngModel)]="user.zip" disabled="{{isformDisabled}}" placeholder="{{'web.register_field_label_zip' | translate }}*" minlength="{{charMinLength}}" pattern="{{applyPattern}}" maxlength="{{charMaxLength}}" (keyup)="countPostalCode(zip.value)"
                                        [ngClass]="{ 'error': (zip.touched && zip.invalid) || (myprofileForm.submitted && zip.invalid)}">
                                    <p *ngIf="zip.invalid && (zip.dirty || zip.touched)" class="text-danger">
                                        <span *ngIf="zip.errors.pattern" class="text-danger">{{'web.myprofile_zip_val_pattern_wrong' | translate }}</span>
                                    </p>
                                    <p *ngIf="charLenIncorrect && zip.dirty" class="text-danger">
                                        {{'web.myprofile_zip_val_min_charac' | translate }} {{charMinLength}}</p>
                                    <p *ngIf="charLenIncorrect && zip.dirty" class="text-danger">
                                        {{'web.myprofile_zip_val_max_charac' | translate }} {{charMaxLength}}</p>
                                </div>

                                <div class="col-12 col-sm-6">
                                    <label>{{'web.my_profile_city_label' | translate }}<span>*</span></label>
                                    <input type="road" name="city" #city="ngModel" [(ngModel)]="user.city" disabled="{{isformDisabled}}" placeholder="{{'web.register_field_label_city' | translate }}*" required maxlength="30" [ngClass]="{ 'error': (city.touched && city.invalid) || (myprofileForm.submitted && city.invalid)}">
                                </div>
                            </div>
                            <div class="row pb-4">
                                <div class="col-12 col-sm-6" *ngIf="isformDisabled">
                                    <label>{{'web.my_profile_country_label' | translate }}</label>
                                    <p>{{user.selectedCountry}}</p>
                                </div>
                                <div class="col-12 col-sm-6" *ngIf="!isformDisabled">
                                    <label>{{'web.my_profile_country_label' | translate }}<span>*</span></label>
                                    <select name="country" [(ngModel)]="user.selectedCountry" #country="ngModel" (ngModelChange)="changeCountryAndSetExtn(user.selectedCountry)" disabled="{{isformDisabled}}" [ngClass]="{ 'error': (country.touched && country.invalid) || (myprofileForm.submitted && country.invalid)}"
                                        required>
                                        <option *ngFor="let country of arrCountries" [ngValue]="country.countryName">
                                            {{country.countryName}}</option>
                                    </select>
                                </div>
                                <div class="col-12 col-sm-6" [hidden]="!isformDisabled">
                                    <label>{{'web.my_profile_phone_label' | translate }}</label>
                                    <p [hidden]="!isPhoneNoInputDisabled">{{user.extension}} {{user.phoneNo}}
                                        <span class="edit" title="{{'web.my_profile_phone_col_focus_tip' | translate }}" (click)="focusPh()"><img src="{{EDIT_ICON}}" alt="" title=""></span>
                                    </p>
                                    <div class="d-flex align-items-center" [hidden]="isPhoneNoInputDisabled">
                                        <input type="text" class="form-control extension mb-0" [hidden]="isPhoneNoInputDisabled" placeholder="{{'web.register_country_code' | translate }}" name="extension" [(ngModel)]="user.extension" readonly />
                                        <input type="text" class="form-control phone-no mb-0" #onPhoneNoEdit [hidden]="isPhoneNoInputDisabled" placeholder="{{'web.registration_phone_format_placeholder' | translate }}" name="phoneNo" (keydown.space)="$event.preventDefault();" [ngClass]="{ 'error': (phoneNo.touched && phoneNo.invalid) || (myprofileForm.submitted && phoneNo.invalid)}"
                                            [(ngModel)]="user.phoneNo" #phoneNo="ngModel" pattern="^(?:(?:[1-9][0-9]*)|0)$" maxlength="13" minlength="8" required />
                                        <span class="edit ms-2" [hidden]="isPhoneNoInputDisabled" title="{{'web.my_profile_phone_col_focus_tip' | translate }}" (click)="editPhoneNo(phoneNo)"><img src="{{OK_ICON}}" alt="" title=""></span>
                                    </div>
                                    <div *ngIf="phoneNo.invalid && (phoneNo.dirty || phoneNo.touched)">
                                        <span class="text-danger" *ngIf="phoneNo.errors.pattern">{{'web.my_profile_val_phone' | translate }}
                                        </span>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-6" *ngIf="!isformDisabled">
                                    <label>{{'web.my_profile_phone_label' | translate }}<span>*</span></label>
                                    <div class="d-flex">
                                        <input type="text" class="form-control" placeholder="{{'web.register_country_code' | translate }}" name="extension" [(ngModel)]="user.extension" readonly />
                                        <input type="text" class="form-control" placeholder="{{'web.registration_phone_format_placeholder' | translate }}" name="phoneNo" (keydown.space)="$event.preventDefault();" [ngClass]="{ 'error': (phoneNo.touched && phoneNo.invalid) || (myprofileForm.submitted && phoneNo.invalid)}"
                                            [(ngModel)]="user.phoneNo" #phoneNo="ngModel" pattern="^(?:(?:[1-9][0-9]*)|0)$" maxlength="13" minlength="8" required />
                                    </div>
                                    <div *ngIf="phoneNo.invalid && (phoneNo.dirty || phoneNo.touched)">
                                        <span class="text-danger" *ngIf="phoneNo.errors.pattern">{{'web.my_profile_val_phone' | translate }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="dob-wrap" *ngIf="disableValue1 && disableValue2 && disableValue3">
                                <label>{{'web.my_profile_dob_label' | translate }}</label>
                                <p>{{selectedDay}}/{{selectedMonth}}/{{selectedYear}}</p>
                            </div>
                            <div class="dob-wrap" *ngIf="!disableValue1 && !disableValue2 && !disableValue3">
                                <label>{{'web.my_profile_dob_label' | translate }}</label>
                                <div class="select">
                                    <select [disabled]="disableValue1" class="form-control" name="date" #date="ngModel" [(ngModel)]="selectedDay" (ngModelChange)="UpdateNumberOfDays($event)">
                                        <option *ngFor="let day of mainArrDay" [ngValue]="day">{{day}}</option>
                                    </select>
                                    <select [disabled]="disableValue2 " class="form-control" name="month" #month="ngModel" [(ngModel)]="selectedMonth" (ngModelChange)="UpdateNumberOfDays($event)">
                                        <option *ngFor="let month of mainArrMonth" [ngValue]="month">{{month}}</option>
                                    </select>
                                    <select [disabled]="disableValue3" class="form-control" name="year" #year="ngModel" [(ngModel)]="selectedYear" (ngModelChange)="UpdateNumberOfDays($event)">
                                        <option *ngFor="let year of mainArrYear" [ngValue]="year">{{year}}</option>
                                    </select>
                                </div>
                            </div>
                            <div class="action-btn text-center">
                                <button class="btn btn-primary" type="submit">{{'web.my_profile_cta_save' | translate}}</button>
                            </div>
                        </form>
                    </div>

                    <div class="email-subscribe">
                        {{'web.myprofile_update_restriction_msg' | translate}}
                        <a routerLink="/{{contactusUrl}}">{{'web.myprofile_contact_link_txt' | translate}}</a>
                    </div>
                    <div class="delete-account">
                        <p *ngIf="newsletterApiValue == 1">
                            <label>{{'web.newsletter_subscription_date' | translate}}<span>*</span></label>
                            <span>{{newsletterDate}} </span>
                            <a routerLink="/{{newsletterUnsubscribeUrl}}"> {{'web.unsubscribe_cta' | translate}}</a>
                        </p>
                        <p *ngIf="newsletterApiValue == 0">
                            <label>{{'web.newsletter_unsubscription_date' | translate}}<span>*</span></label>
                            <span>{{newsletterDate}} </span>
                            <a (click)="newsletterSubscribe()"> {{'web.subscribe_cta' | translate}}</a>
                        </p>
                        <p class="text-success">{{apiMsg}}</p>
                        <p>
                            <a routerLink="/{{deleteProfileUrl}}">{{'web.myprofile_delete' | translate }}</a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>