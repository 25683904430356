import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { DataService } from 'src/app/services/data.service';
import { GlobalService } from 'src/app/services/global.service';
import { environment } from 'src/environments/environment';
import * as global from 'src/app/globals';
import { DynamicPopupsModalComponent } from '../dynamic-popups-modal/dynamic-popups-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ContentfulService } from 'src/app/services/contentful.service';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { ComponentCanDeactivate } from 'src/app/guard/auth-guard.service';
@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss'],
    standalone: false
})
export class ProfileComponent implements OnInit  ,ComponentCanDeactivate {
    colourfulSpinner: any;

  canDeactivate(): Observable<boolean> | boolean {
    return true
  }
    @ViewChild('myprofileForm') myprofileForm: any;
    @ViewChild('changePasswordForm') changePasswordForm: any;
    @ViewChild('container') container: any;
    @ViewChild('input') input: any;
    @ViewChild("onEdit") onEdit: any;
    @ViewChild("onPhoneNoEdit") onPhoneNoEdit: any;
    isPicChanged: Boolean;
    user: any = {};
    userProfile: any;
    oldEmail: any;
    isEmailConfirmed: Boolean;
    selectedDay: any;
    disableValue1: any;
    submitted: any;
    selectedMonth: any;
    disableValue2: any;
    selectedYear: any;
    disableValue3: any;
    arrCountry = [];
    profileImageUrl: any;
    profileData1: any;
    profileData2: any;
    isDisabled: Boolean;
    isPasswordMatched: Boolean;
    isPasswordWrong: Boolean;
    isPasswordSame: Boolean;
    invalidPassword1: Boolean;
    invalidPassword2: Boolean;
    arrDay = [];
    arrMonth = [];
    arrYear = [];
    ageLimitValue: any = 10;
    mainArrDay = [];
    mainArrMonth = [];
    mainArrYear = [];
    ageLimitExceed: Boolean = false;
    emailReq: Boolean;
    emailInvalid: Boolean;
    emailSendMsg: any;
    emailUpdateSuccess: any = false;
    emailAlreadyExists: any;
    emailSendingFail: any;
    isUpdateProcessing: Boolean;
    updateSuccessMsg: Boolean;
    phoneLenIncorrect: Boolean;
    matchPassword: Boolean;
    newsletterApiValue: any;
    resetPasswordSuccess: any;
    message: any;
    isPasswordSame1: any;
    isPasswordSame2: any;
    newsletterDate: any;
    arrCountries: any = [];
    charMinLength: any;
    charMaxLength: any;
    applyPattern: any;
    countryObj: any;
    isDataLoading: Boolean;
    charLenIncorrect: Boolean;
    streetError: Boolean;
    isformDisabled: Boolean = false;
    isInputDisabled: Boolean = false;
    deleteProfileUrl: any;
    newsletterUnsubscribeUrl: any;
    dicUrls: any;
    apiMsg: any;
    showError: Boolean = false;
    contactusUrl: any;
    newsletterCheckbox: any;
    isEmailInputDisabled: Boolean = true;
    isPhoneNoInputDisabled: Boolean = true;
    businessInfo = {};
    isShowBusinessInfo: Boolean = false;
    bannerImage: any;
    displayForgotPasswordBtn: Boolean = false;
    languageData: any;
    succesMessage: any;
    EDIT_ICON: any;
    OK_ICON: any;
    showMsgFlag: Boolean = false;
    confirmationMsg = undefined;
    styleClassApiMsg: any = {};
    displayResendVerifyEmailLink: Boolean = false;
    isDisplayPasswordSection: Boolean = false;



    languageDataSubscription: Subscription;
    constructor(private globalService: GlobalService, private dataService: DataService, private modalService: NgbModal,private contentfulservice:ContentfulService, private router: Router,
 private activeRoute: ActivatedRoute) {
        document.addEventListener('click', this.fillEmail.bind(this));
        this.bannerImage = environment[global.env].RESOURCE_URL_AMAZON + environment[global.env].oemCode + '/preview/my_profile_nl_banner.jpg';
        this.colourfulSpinner = environment[global.env].RESOURCE_URL_AMAZON + environment[global.env].oemCode + '/icons/colourful_spinner.webm';
        let iconsObj = this.globalService.getIconsObj();
        this.EDIT_ICON = iconsObj['EDIT_ICON'];
        this.OK_ICON = iconsObj['OK_ICON'];

          this.contentfulservice.$newsletterUpdate.subscribe(response => {

              if (response == '1') {
              this.newsletterApiValue = '1';
              }
              else if(response == '0') {
              this.newsletterApiValue = '0';
              }
             });
             window.onbeforeunload = function() {window.scrollTo(0,0);}

    }

    ngOnInit(): void {
     this.contentfulservice.emitFooterAtLast(true);
     let url = this.router.url.split('?')[0];
     let arrUrl = url.split('/');
     let pageSlug= decodeURIComponent(arrUrl[1]);
      if(this.activeRoute.snapshot.params['level1'] ){
          pageSlug=pageSlug+"/"+this.activeRoute.snapshot.params['level1'];
      }
      if(this.activeRoute.snapshot.params['level2'] ){
      pageSlug=pageSlug+"/"+this.activeRoute.snapshot.params['level2'];
      }
      if(this.activeRoute.snapshot.params['level3'] ){
      pageSlug=pageSlug+"/"+this.activeRoute.snapshot.params['level3'];
      }
      if(this.activeRoute.snapshot.params['level4'] ){
      pageSlug=pageSlug+"/"+this.activeRoute.snapshot.params['level4'];
      }

      this.contentfulservice.getContentByFieldName('page', pageSlug).then(data => {
              if (data) {
                if (data.length == 0) {
                  this.router.navigateByUrl('/404');
                    return;
                  }
                if (data.length > 0 && data[0].fields['seoMetadata']) {
                  let seoData = {};
                  seoData = data[0].fields['seoMetadata'].fields;
                  this.globalService.setSeoMetaData(seoData);
                  }

                  if (data.length > 0 && (data[0].fields['topSection'])) {
                    let allComponentFilter = JSON.parse(JSON.stringify(data[0].fields['topSection']))
                    let headerFound = allComponentFilter.filter(item => item.fields.selectDesign);
                    let headerObject={
                      headerVersion:false,
                      headerData:false
                    }

                    if (headerFound.length > 0) {
                      let headerData = headerFound[0];
                      if (headerData.fields.selectDesign) {
                        let headerObject={
                          headerVersion:headerData.fields.selectDesign.split('-')[2],
                          headerData
                        }
                        
                        this.contentfulservice.emitHeaderData(headerObject)

                      }
                    }else{
                      this.contentfulservice.emitHeaderData(headerObject)
                    }


                  }

              }
      })
    window.scrollTo(0,0)
        if (localStorage.getItem('userInfo') == null) {
            let data = { type: "OPEN_LOGIN" };
            this.globalService.setOpenLogin(data);
        }
        else {
            this.isDataLoading = true;

            if (this.globalService.gettLanguageData() != undefined) {
                this.languageData = this.globalService.gettLanguageData();
                this.fetchProfileData();
                this.arrDay = this.getDMY(1, 31, false);
                this.arrMonth = this.getDMY(1, 12, false);
                this.arrYear = this.getDMY((new Date().getFullYear() - (this.ageLimitValue)), 100, true);
                this.getfullCalendar();
            }
            else {
                this.languageDataSubscription = this.globalService.languageDataEmiter.subscribe(data => {
                    if (data) {
                        this.languageData = data;
                        this.fetchProfileData();
                        this.arrDay = this.getDMY(1, 31, false);
                        this.arrMonth = this.getDMY(1, 12, false);
                        this.arrYear = this.getDMY((new Date().getFullYear() - (this.ageLimitValue)), 100, true);
                        this.getfullCalendar();
                    }
                });
            }

            this.dicUrls = this.globalService.getStaticPagesUrls(global.env, global.lang);
            let myAccountUrl = this.dicUrls['ACCOUNT_PREVIEW'];
            this.deleteProfileUrl = myAccountUrl+'/'+this.dicUrls['DELETE_PROFILE'];
            this.newsletterUnsubscribeUrl = myAccountUrl+'/'+ this.dicUrls['NEWSLETTER_UNSUBSCRIBE_PREVIEW'];
            
            this.contactusUrl = this.dicUrls['CONTACTUS_PREVIEW'];
        }
    }


    fillEmail(event: any) {
        if (this.container.nativeElement.contains(event.target)) {
            if ((this.user.userName == "") && (this.myprofileForm.form.value.email == "")) {
                this.input.nativeElement.value = this.userProfile.userEmail;
                this.user.userName = this.userProfile.userEmail;
            }
        }
    }

    fetchProfileData() {
        let userData = JSON.parse(localStorage.getItem('userInfo'));
        if (userData.userType == "Guest") {
            let data = { type: "OPEN_LOGIN" };
            this.globalService.setOpenLogin(data);
        }

        let authkey = userData.auth;
        this.isPicChanged = false;
        this.dataService.getprofileDetails(authkey).subscribe((res: Response) => {
            let resSTR = JSON.stringify(res);
            let resJSON = JSON.parse(resSTR);
            if (resJSON.success == true) {
                this.userProfile = resJSON.data;
                this.displayResendVerifyEmailLink = this.userProfile.user_type == "Guest" || this.userProfile.user_type == "Nonverified" ? true : false;
                // if(this.userProfile.user_type == "Guest"){
                //     this.displayForgotPasswordBtn = true;
                // }
                // else{
                //     this.displayForgotPasswordBtn = false;
                // }

                if (resJSON.businessInfo) {
                    if (resJSON.businessInfo.resellerStatus == 'confirm') {
                        this.businessInfo = resJSON.businessInfo;
                        this.isShowBusinessInfo = true;
                    }
                    else {
                        this.isShowBusinessInfo = false;
                    }
                }
                else {
                    this.isShowBusinessInfo = false;
                }

                this.arrCountries = resJSON.oemCountries;

                if (this.userProfile.Street != '') {
                    this.isformDisabled = true;
                    this.isPhoneNoInputDisabled = true;
                }
                else {
                    this.isformDisabled = false;
                    this.isInputDisabled = true;
                }

                this.isEmailInputDisabled = this.userProfile.userEmail != '' && this.userProfile.userEmail != null ? true : false;

                this.user.userName = this.userProfile.userEmail;
                if (this.userProfile.Salutation != null) {
                    let salutationKey = 'my_profile_gender_' + this.userProfile.Salutation;
                    this.user.salutation = this.languageData.web[salutationKey];
                }
                if (this.userProfile.FirstName == '') {
                    this.isInputDisabled = false;
                }
                this.oldEmail = this.userProfile.userEmail;
                this.user.firstName = this.userProfile.FirstName;
                this.user.lastName = this.userProfile.LastName;
                this.user.selectedCountry = this.userProfile.countryName;
                this.user.street = this.userProfile.Street;
                this.user.houseNumber = this.userProfile.HouseNumber;
                this.user.cosUserName = this.userProfile.CosUserId;
                this.user.userNumber = this.userProfile.userNumber;
                this.user.gender = this.userProfile.gender;
                this.user.zip = this.userProfile.zipCode;
                this.user.city = this.userProfile.City
                this.user.state = this.userProfile.state;
                if (this.userProfile.countryName == undefined || this.userProfile.countryName == null || this.userProfile.countryName == '') {
                    this.countryObj = this.getCountry('', environment[global.env].defaultCountryCode);
                    this.getZipPattern(this.countryObj);
                }
                else {
                    this.countryObj = this.getCountry(this.userProfile.countryName, this.userProfile.Country);
                    this.getZipPattern(this.countryObj);
                }

                if (this.userProfile.Phone != undefined) {
                    if (this.userProfile.Phone.indexOf("-") > -1) {
                        this.user.phoneNo = this.userProfile.Phone.split("-")[1];
                    }
                    else {
                        this.user.phoneNo = this.userProfile.Phone;
                    }
                }
                else {
                    this.user.phoneNo = '';
                }

                if (this.userProfile.Status == "Emailconfirmlink" && this.userProfile.isConfirmed == "Yes") {
                    this.isEmailConfirmed = true;
                } else {
                    this.isEmailConfirmed = false;
                }

                this.user.aboutMe = (this.userProfile.AboutMe == 'null') ? '' : this.userProfile.AboutMe;


                let yyyymmdd = this.userProfile.Dateofbirth.split(' ')[0].split('-');
                if (this.languageData) {
                    if (yyyymmdd[2] == '00' || yyyymmdd[2] == '' || yyyymmdd[2] == undefined) {
                        this.selectedDay = this.languageData.web.register_field_label_dd;
                        this.disableValue1 = false;
                    }
                    else {
                        this.submitted = true;
                        this.disableValue1 = true;
                        this.selectedDay = parseInt(yyyymmdd[2]);
                    }
                    if (yyyymmdd[1] == '00' || yyyymmdd[1] == '' || yyyymmdd[1] == undefined) {
                        this.selectedMonth = this.languageData.web.register_field_label_mm;
                        this.disableValue2 = false;
                    }
                    else {
                        this.submitted = true;
                        this.disableValue2 = true;
                        this.selectedMonth = parseInt(yyyymmdd[1]);
                    }
                    if (yyyymmdd[0] == '0000' || yyyymmdd[0] == '' || yyyymmdd[0] == undefined) {
                        this.selectedYear = this.languageData.web.register_field_label_yyyy;
                        this.disableValue3 = false;
                    }
                    else {
                        this.submitted = true;
                        this.disableValue3 = true;
                        this.selectedYear = parseInt(yyyymmdd[0]);
                    }
                }

                this.user.gender = this.userProfile.gender;
                if (this.userProfile.Title == 'null' || this.userProfile.Title == undefined || this.userProfile.Title == 'undefined') {
                    this.userProfile.Title = '';
                    this.user.title = ''
                }
                else {
                    this.user.title = this.userProfile.Title
                }

                if (this.userProfile.subtitle == 'null' || this.userProfile.subtitle == undefined || this.userProfile.subtitle == 'undefined') {
                    this.userProfile.Title = '';
                    this.user.subtitle = ''
                }
                else {
                    this.user.subtitle = this.userProfile.subtitle
                }


                this.user.newsletter = this.userProfile.newsletter;
                this.newsletterApiValue = this.userProfile.newsletter;

                var profilePic = this.userProfile.ProfileImage || 'placeholder.jpg';
                this.profileImageUrl = (this.userProfile.ProfileImage == null) ? null : (environment[global.env].RESOURCE_URL_AMAZON + environment[global.env].AMAZON_CUSTOMER_FOLDER_PATH + 'thumb/250/' + profilePic);

                if (this.userProfile.newsletterUpdateDate != null) {
                    var newsletterDate = this.userProfile.newsletterUpdateDate;
                    newsletterDate = newsletterDate.split(' ')[0];
                    var letterDate = newsletterDate.split('-');
                    if (environment[global.env].oemCode == 'CD-AT' || environment[global.env].oemCode == 'FS-AT') {
                        this.newsletterDate = letterDate[2] + "." + letterDate[1] + "." + letterDate[0];
                    }
                    else {
                        this.newsletterDate = letterDate[0] + "-" + letterDate[1] + "-" + letterDate[2];
                    }
                }

                this.profileData1 = JSON.parse(JSON.stringify(this.user));
                this.profileData2 = JSON.parse(JSON.stringify(this.user));

                if (this.userProfile.Dateofbirth == "0000-00-00 00:00:00") {
                    this.isDisabled = false;
                }
                else {
                    this.isDisabled = true;
                }
                this.isDataLoading = false;
            }
        })
    }


    getZipPattern(countryObj) {
        this.user.selectedCountry = countryObj.countryName;
        this.user.extension = countryObj.ISD;
        this.charMinLength = countryObj.MinPostalLength;
        this.charMaxLength = countryObj.PostalLength;

        if (countryObj.isAlphaNumeric == "Yes") {
            this.applyPattern = '^[- a-zA-Z0-9]+$';
        }
        else if (countryObj.isAlphaNumeric == "No") {
            this.applyPattern = '^[0-9]*$';
        }
        else {
            this.applyPattern = '^[0-9]*$';
        }
    }

    newsletterSubscribe() {
        // this.user.newsletter = true;
        let email = this.oldEmail;
        var type = "subscribe"
        // this.isLoadingData = true;

        this.dataService.newsletterSubscribe(email, type).subscribe((res: Response) => {

            let resSTR = JSON.stringify(res);
            let resJSON = JSON.parse(resSTR);
            if (resJSON.success == true) {
                this.newsletterApiValue = '1';
                this.apiMsg = resJSON.message;
                let currentUserInfo=JSON.parse(localStorage.getItem('userInfo'));
                currentUserInfo['Newsletter']='1';
                localStorage.setItem('userInfo', JSON.stringify(currentUserInfo));
                this.contentfulservice.emitNewsletterUpdate('1');
                setTimeout(() => {
                    this.apiMsg = '';
                }, 4000);
            }
            else {
                 this.apiMsg = resJSON.message;
            }
        });
    }

    getCountry(countryName, countryCode) {
        for (var i = 0; i < this.arrCountries.length; i++) {
            if (this.arrCountries[i].countryName == countryName || this.arrCountries[i].countryCode == countryCode) {
                return this.arrCountries[i];
            }
        }
    }



    countPostalCode(newVal) {
        if (this.charMaxLength == 0 && this.charMinLength == 0) {
            this.user.zip = '';
            this.charLenIncorrect = false;
            return;
        } else {
            if (newVal != undefined) {
                if ((newVal.length == this.charMaxLength) || (newVal.length == this.charMinLength)) {
                    this.user.zip = newVal;
                    this.charLenIncorrect = false
                }
                else {
                    this.charLenIncorrect = true;
                }
            }
            else {
                this.charLenIncorrect = true;
            }
        }
    }


    changeCountryAndSetExtn(country) {

        this.countryObj = this.getCountry(country, '');
        this.user.selectedCountry = this.countryObj.countryName;
        this.charMinLength = this.countryObj.MinPostalLength;
        this.charMaxLength = this.countryObj.PostalLength;
        this.user.zip = '';
        this.user.extension = this.countryObj.ISD;
        this.countPostalCode('');
    }

    getfullCalendar() {
        this.mainArrDay = [];
        this.mainArrDay.push(this.languageData.web.register_field_label_dd);
        for (var k = 0; k < this.arrDay.length; k++) {
            this.mainArrDay.push(this.arrDay[k]);
        }
        this.mainArrMonth = [];
        this.mainArrMonth.push(this.languageData.web.register_field_label_mm);
        for (var j = 0; j < this.arrMonth.length; j++) {
            this.mainArrMonth.push(this.arrMonth[j]);
        }
        this.mainArrYear = [];
        this.mainArrYear.push(this.languageData.web.register_field_label_yyyy);
        for (var l = 0; l < this.arrYear.length; l++) {
            this.mainArrYear.push(this.arrYear[l]);
        }
    }

    getDMY(start, limit, isYear) {
        if (isYear == false) {
            var range = [];
            range.push(start);
            for (var i = 1; i < limit; i++) {
                range.push(start + i);
            }
            return range;
        }
        else {
            var range = [];
            range.push(start);
            for (var i = 1; i < limit; i++) {
                range.push(start - i);
            }
            return range;
        }
    }

    isLeapYear(selectedYear) {
        return ((selectedYear % 400 === 0 || selectedYear % 100 !== 0) && (selectedYear % 4 === 0)) ? 1 : 0;
    }

    ageLimit(day, month, year, ageLimitValue) {
        var d = new Date();
        if (month > d.getMonth() + 1 && year >= d.getFullYear() - ageLimitValue) {
            this.ageLimitExceed = true;
        } else {
            this.ageLimitExceed = false;
        }

    }

    UpdateNumberOfDays(obj) {
        var days31 = [1, 3, 5, 7, 8, 10, 12];

        if (this.isLeapYear(this.user.selectedYear) && this.user.selectedMonth == 2) {
            this.arrDay = this.getDMY(1, 29, false);
        } else if (this.user.selectedMonth == 2) {
            this.arrDay = this.getDMY(1, 28, false);
        } else if (days31.indexOf(this.user.selectedMonth) != -1) {
            this.arrDay = this.getDMY(1, 31, false);
        } else if (!this.user.selectedMonth && !this.user.selectedYear) {
            this.arrDay = this.getDMY(1, 31, false);
        } else {
            this.arrDay = this.getDMY(1, 30, false);
        }
        if (this.user.selectedDay && this.user.selectedMonth && this.user.selectedYear) {
            this.ageLimit(this.user.selectedDay, this.user.selectedMonth, this.user.selectedYear, this.ageLimitValue);
        }
    }

    openConfModal() {
        var myprofileForm = this.myprofileForm.form;
        let selectedMonth, selectedDay, selectedYear;
        if (myprofileForm.invalid == true) {
            if (myprofileForm.value.email == '') {
                this.emailReq = true;
                this.emailInvalid = false;
            }
            else if (myprofileForm.value.email == undefined) {
                this.emailReq = false;
                this.emailInvalid = true;
            }
            else {
                this.emailReq = false;
                this.emailInvalid = false;
            }
            return;
        }

        this.emailReq = false;
        this.emailInvalid = false;
        let phoneNum = myprofileForm.value.phoneNo;
        let extension = myprofileForm.value.extension;

        myprofileForm.value.extension = extension == undefined ? "" : extension;
        myprofileForm.value.phoneNo = phoneNum == undefined ? "" : phoneNum;

        if (this.user.newPassword === this.user.matchPassword) {
            this.matchPassword = false;
        } else {
            this.matchPassword = true;
            return false;
        }


        if (myprofileForm.value.month <= 9) {
            selectedMonth = '0' + myprofileForm.value.month
        }
        else if (myprofileForm.value.selectedMonth != 'MM') {
            selectedMonth = myprofileForm.value.month;
        }
        else {
            selectedMonth = '00';
        }

        if (myprofileForm.value.date <= 9) {
            selectedDay = '0' + myprofileForm.value.date
        }
        else if (myprofileForm.value.date != 'DD') {
            selectedDay = myprofileForm.value.date;
        }
        else {
            selectedDay = '00';
        }

        if (myprofileForm.value.year == 'YYYY') {
            selectedYear = '00';
        }
        else {
            selectedYear = myprofileForm.value.year
        }

        this.user.dob = (selectedYear || myprofileForm.value.year) + '-' + (selectedMonth || myprofileForm.value.month) + '-' + (selectedDay || myprofileForm.value.date);

        if (myprofileForm.value.extension != undefined && myprofileForm.value.extension.length > 0) {
            if (myprofileForm.value.phoneNo != undefined && myprofileForm.value.phoneNo.length > 0) {
                this.user.phone = myprofileForm.value.extension + '-' + myprofileForm.value.phoneNo;
            }
            else {
                this.user.phone = ""
            }
        }
        else if (myprofileForm.value.phoneNo != undefined && myprofileForm.value.phoneNo.length > 0) {
            if (myprofileForm.value.extension != undefined && myprofileForm.value.extension.length > 0) {
                this.user.phone = myprofileForm.value.myprofileForm + '-' + myprofileForm.value.phoneNo;
            }
            else {
                this.user.phone = this.languageData.web.register_country_code + '-' + myprofileForm.value.phoneNo;
            }
        }
        else {
            this.user.phone = ""
        }
        this.confirmSubmitModal();
    }

    confirmSubmitModal(){

        let subTitle = this.languageData.web.my_profile_update_confirmation_msg;
        let cancelBtn = this.languageData.web.my_profile_update_confirmation_cta_no;
        let okBtn = this.languageData.web.my_profile_update_confirmation_cta_yes;
        const modalRef = this.modalService.open(DynamicPopupsModalComponent, { size: 'md',windowClass:'modalWindowWidth', centered: true, backdrop: 'static', keyboard: false });
        modalRef.componentInstance.data = {subTitle: subTitle,cancelBtnTxt: cancelBtn, okBtnTxt: okBtn, modalName:'updateProfile', cancelIcon: false,
        };
        modalRef.componentInstance.updateProfileData.subscribe((res) => {
            if(res){
                this.uploadProfile()
            }
        })
    }

    checkPassword(inputField) {
        if (inputField == 1) {
            if (this.user.newPassword != undefined && this.user.oldPassword != undefined) {
                if (this.user.newPassword === this.user.oldPassword) {
                    this.isPasswordMatched = true;
                    this.isPasswordWrong = false;
                }
                else {
                    this.isPasswordMatched = false;
                    this.isPasswordSame = false;
                    this.isPasswordWrong = false;

                    if (this.user.newPassword.length > 0) {
                        let str = this.user.newPassword;
                        if (str !== undefined) {
                            if (str.length < 8) {
                                this.invalidPassword1 = true;
                            }
                            if (str.search(/\d/) == -1) {
                                this.invalidPassword1 = true;
                            }
                            if (str.search(/[A-Z]+/) == -1) {
                                this.invalidPassword1 = true;
                            }

                            if (!(str.search(/\&/) == -1)) {
                                this.invalidPassword1 = false;
                            }

                            if (!(str.search(/\%/) == -1)) {
                                this.invalidPassword1 = false;
                            }
                            if (!(str.search(/\s/) == -1)) {
                                this.invalidPassword1 = true;
                            }
                        }
                        else {
                            this.invalidPassword1 = false;
                            this.user.newPassword = '';
                        }
                    }
                    else {
                        this.invalidPassword1 = false;
                    }
                }
            }
        }
        else if (inputField === 'oldpassword') {
            if (this.user.newPassword != undefined && this.user.oldPassword != undefined) {
                if (this.user.newPassword === this.user.oldPassword) {
                    this.isPasswordMatched = true;
                    this.isPasswordWrong = false;
                }
                else {
                    this.isPasswordMatched = false;
                    this.isPasswordSame = false;
                    this.isPasswordWrong = false;
                }
            }
        }
        else {
            if (this.user.matchPassword != undefined) {
                if (this.user.matchPassword.length > 0) {
                    if (this.user.newPassword != undefined && this.user.newPassword.length > 0) {
                        if ((this.user.newPassword == this.user.matchPassword) && this.invalidPassword1 == false) {
                            this.invalidPassword2 = false;
                            this.invalidPassword1 = false;
                        }
                        else if ((this.user.newPassword !== this.user.matchPassword) && this.invalidPassword1 == false) {
                            this.invalidPassword2 = true;
                            this.invalidPassword1 = false;
                        }
                        else {
                            this.invalidPassword2 = true;
                            this.invalidPassword1 = true;
                        }
                    }
                }
                else {
                    if (this.invalidPassword1 == false) {
                        this.invalidPassword2 = false;
                        this.invalidPassword1 = false;
                    }
                }
            }
            else {
                if (this.invalidPassword1 == false) {
                    this.invalidPassword2 = false;
                    this.invalidPassword1 = false;
                }
            }
        }
    }

    checkStreet(val) {
        if (val == '' || val == undefined) {
            return;
        }
        let streetVal = val.replace(/\s/g, "");
        streetVal = streetVal.toLowerCase();
        if (this.user.houseNumber) {
            let houseVal = this.user.houseNumber.replace(/\s/g, "");
            houseVal = houseVal.toLowerCase();

            if (houseVal.length > 0) {
                if (houseVal == streetVal) {
                    this.streetError = true;
                }
                else {
                    this.streetError = false;
                }
            }
            else {
                this.streetError = false;
            }
        }
        else {
            this.streetError = false;
        }
    }

    checkHouse(val) {
        var houseVal = val.replace(/\s/g, "");
        houseVal = houseVal.toLowerCase();
        if (this.user.street) {
            var streetVal = this.user.street.replace(/\s/g, "");
            streetVal = streetVal.toLowerCase();

            if (streetVal.length > 0) {
                if (streetVal == houseVal) {
                    this.streetError = true;
                }
                else {
                    this.streetError = false;
                }
            }
            else {
                this.streetError = false;
            }
        }
        else {
            this.streetError = false;
        }
    }

    closeConfirmModal() {
        // $("#profilePic").val('');
        this.isUpdateProcessing = false;
        this.phoneLenIncorrect = false;
        // this.fetchProfileData();
        // this.profileData2 = JSON.parse(JSON.stringify(this.profileData1));
        // this.user = this.profileData2;
    }

    updateEmail(newEm, oldEm) {
        this.dataService.updateUserEmail(newEm, oldEm).subscribe((res: Response) => {
            let resSTR = JSON.stringify(res);
            let resJSON = JSON.parse(resSTR);
            this.emailSendMsg = resJSON.message;
            if (resJSON.code == 1) {
                this.emailUpdateSuccess = true;
                this.emailAlreadyExists = false;
                this.emailSendingFail = false;
                this.fetchProfileData();
            }
            else if (resJSON.code == 2) {
                this.emailUpdateSuccess = false;
                this.emailAlreadyExists = true;
                this.emailSendingFail = false;
            }
            else {
                this.emailSendingFail = true;
                this.emailUpdateSuccess = false;
                this.emailAlreadyExists = false;
            }
        })
    }

    finalChanges() {
        let countryObj = this.getCountry(this.user.selectedCountry, '');
        this.user.selectedCountryCode = countryObj.countryCode;

        this.user.newsletter = this.newsletterApiValue;

        let phoneExtension = this.user.extension;
        let phoneNumber = this.user.phoneNo;
        if (phoneExtension != undefined && phoneExtension.length > 0) {
            if (phoneNumber != undefined && phoneNumber.length > 0) {
                this.user.phone = phoneExtension + '-' + phoneNumber;
            }
            else {
                this.user.phone = ""
            }
        }
        else if (phoneNumber != undefined && phoneNumber.length > 0) {
            if (phoneExtension != undefined && phoneExtension.length > 0) {
                this.user.phone = phoneExtension + '-' + phoneNumber;
            }
            else {

                // this.user.phone = this.languageData['register_country_code']+'-'+this.shippingForm.phoneNo.$viewValue;
            }
        }
        else {
            this.user.phone = ""
        }


        this.dataService.updateProfileData(this.user).subscribe((res: Response) => {
            let resSTR = JSON.stringify(res);
            let resJSON = JSON.parse(resSTR);
            if (resJSON.success == true) {
                // var updatedProfileImage = resJSON.image;
                this.isUpdateProcessing = false;
                this.updateSuccessMsg = resJSON.message;

                var jsonString = localStorage.getItem('userInfo');
                var userDetail = JSON.parse(jsonString);
                userDetail.Email = this.oldEmail;
                this.user.userName = this.oldEmail;
                userDetail.Title = this.user.title;
                userDetail.subTitle = this.user.subtitle;
                userDetail.FirstName = this.user.firstName;
                userDetail.LastName = this.user.lastName;
                userDetail.HouseNumber = this.user.houseNumber;
                userDetail.Street = this.user.street;
                userDetail.Phone = this.user.phone;
                userDetail.Zipcode = this.user.zip;
                userDetail.City = this.user.city;
                userDetail.Dateofbirth = this.user.dob;
                userDetail.Gender = this.user.gender;

                localStorage.setItem('userInfo', JSON.stringify(userDetail));

                this.submitted = true;
                this.disableValue1 = this.submitted && (this.selectedDay !== this.languageData.web.register_field_label_dd);
                this.disableValue2 = this.submitted && (this.selectedDay !== this.languageData.web.register_field_label_mm);
                this.disableValue3 = this.submitted && (this.selectedDay !== this.languageData.web.register_field_label_yyyy);

                if (this.disableValue1) {
                    this.disableValue2 = true;
                    this.disableValue3 = true;
                } else {
                    this.disableValue2 = false;
                    this.disableValue3 = false;
                }

                this.isUpdateProcessing = false;
                this.isformDisabled = true;
                this.isPhoneNoInputDisabled = true;

                setTimeout(() => {
                    this.updateSuccessMsg = false;
                }, 8000);
            }
            else {
                this.updateSuccessMsg = false;
                this.fetchProfileData();
            }
        })
    }



    uploadProfile() {
        this.isUpdateProcessing = true;
        if (this.userProfile.userEmail === this.user.userName) {
        }
        else {
            this.updateEmail(this.user.userName, this.userProfile.userEmail)
        }
        this.finalChanges();
    }

    resetPassword() {
        if (this.changePasswordForm.invalid) {
            this.showError = true;
            return;
        }
        this.showError = false;


        this.checkPassword(1);
        this.checkPassword(2);

        if (this.isPasswordMatched) {
            return;
        }

        if (this.user.newPassword == this.user.matchPassword && this.invalidPassword1 == false && this.invalidPassword2 == false) {

            this.dataService.resetOTPPassword('', this.user.newPassword, this.user.matchPassword, '', this.user.userName, this.user.oldPassword, undefined).subscribe((res: Response) => {
                let resSTR = JSON.stringify(res);
                let resJSON = JSON.parse(resSTR);
                if (resJSON.success == true) {
                    this.contentfulservice.opensuccessModal('passwordchange',resJSON)
                    this.resetPasswordSuccess = false;
                    this.changePasswordForm.reset();
                    this.isPasswordMatched = false;
                    this.message = resJSON.message;
                    this.isDisplayPasswordSection = false;

                } else {
                    this.resetPasswordSuccess = false;

                    if (resJSON.hasOwnProperty('message')) {
                        if (resJSON.message.key == 'api_res_val_msg6') {
                            this.message = resJSON.message;
                        }
                        else {
                            this.isPasswordWrong = true;
                        }
                    }
                    else if (resJSON.error.detail == 'api_res_val_msg7') {
                        this.changePasswordForm.form.value.matchPassword = '';
                        this.changePasswordForm.form.value.newPassword = '';
                        this.isPasswordWrong = true;
                    }
                    else {
                        this.isPasswordWrong = true;
                    }
                }
            })
        }
        else {
            this.checkPassword(0);
            this.resetPasswordSuccess = false;
            this.message = ''
        }
    }

    passFormat(val) {
        if (val == 1) {
            this.invalidPassword1 = false;
            var str = this.user.newPassword || "";
            if (str.length > 0) {
                if (str.length < 8) {
                    this.invalidPassword1 = true;
                }
                if (str.search(/\d/) == -1) {
                    this.invalidPassword1 = true;
                }
                if (str.search(/[A-Z]+/) == -1) {
                    this.invalidPassword1 = true;
                }
                if (!(str.search(/\&/) == -1)) {
                    this.invalidPassword1 = true;
                }
                if (!(str.search(/\%/) == -1)) {
                    this.invalidPassword1 = true;
                }
                if (!(str.search(/\s/) == -1)) {
                    this.invalidPassword1 = true;
                }
            }
            else {
                this.invalidPassword1 = false;
                this.user.newPassword = '';
                this.user.newPassword = '';
            }
        }
        else {
            if ((this.user.newPassword == undefined) && (this.user.matchPassword.length > 0)) {
                this.invalidPassword2 = true;
                this.invalidPassword1 = true;
            }
            else if (this.user.matchPassword != undefined) {
                if (this.user.matchPassword.length == 0) {
                    this.invalidPassword2 = false;
                    this.invalidPassword1 = false;
                }
            }
        }
    }



    ngOnDestroy() {
        // unsubscribe to ensure no memory leaks
        if (this.languageDataSubscription) {
            this.languageDataSubscription.unsubscribe();
        }

    }

    cancleSave(profileOrPassword) {
        if (profileOrPassword == 1) {

            this.charLenIncorrect = false;
            this.phoneLenIncorrect = false;

            this.fetchProfileData();
            this.profileData2 = JSON.parse(JSON.stringify(this.profileData1));
            this.user = this.profileData2;

        }
        else {

            // this.changePasswordForm.submitted = false;
            this.showError = false;
            this.user.oldPassword = undefined;
            this.user.newPassword = undefined;
            this.user.matchPassword = undefined;
            this.isPasswordWrong = false;
            this.isPasswordMatched = false;
            this.isPasswordMatched = false;
            this.isPasswordSame = false;
            this.matchPassword = false;
            this.invalidPassword1 = false;
            this.invalidPassword2 = false;
            this.isDisplayPasswordSection = false;
            // $('.heading_wrap').find('i').show();
        }

    }

    changePassword() {
        this.isDisplayPasswordSection = !this.isDisplayPasswordSection;
    }

    focus() {
        this.isEmailInputDisabled = false;
        this.onEdit.nativeElement.focus();
    }
    editEmail() {
        this.user.userName = this.onEdit.nativeElement.value;
        this.isEmailInputDisabled = true;
    }
    focusPh() {
        this.isPhoneNoInputDisabled = false;
        this.onPhoneNoEdit.nativeElement.focus();
    }

    editPhoneNo(phone) {
        if (phone.control.status == 'INVALID') {
            return;
        }
        this.user.phoneNo = this.onPhoneNoEdit.nativeElement.value;
        this.isPhoneNoInputDisabled = true;
    }
    forgotPwd() {
        // this.closeModal();
        // let data = {type:"OPEN_FORGOTPASSWORD"};
        // this.globalService.setOpenForgotPassword(data)
        this.forgetPassword();
    }

    forgetPassword() {

        let loginUrl = 'reset-password';
        let email = this.userProfile.userEmail;

        this.dataService.forgetPassword(email, loginUrl).subscribe((res: Response) => {
            let resSTR = JSON.stringify(res);
            let resJSON = JSON.parse(resSTR);
            if (resJSON.success == true) {

                let msg = resJSON.message.toLowerCase();
                this.succesMessage = this.languageData.web[msg];
                setTimeout(() => {
                    this.succesMessage = '';
                }, 4000);

            } else {
                // this.forgotPassLoader = false;
                // this.resetPassLink = false;
                // this.emailNotExist = true;
                // this.email = '';
            }
        });

    }

    verifyEmail() {
        let email = this.userProfile.userEmail;
        if (!email) {
            return;
        }
        this.dataService.resendVerificationMail(email).subscribe((res: Response) => {
            let resSTR = JSON.stringify(res);
            let resJSON = JSON.parse(resSTR);
            this.showMsgFlag = true;
            // let msgkey = resJSON.message;
            // this.confirmationMsg = this.languageData.web[msgkey];
            this.confirmationMsg = resJSON.message;
            if (resJSON.success == true) {
                this.styleClassApiMsg.color = "green";
                this.styleClassApiMsg.padding = "10px";

            } else {
                this.styleClassApiMsg.color = "red";
            }

            setTimeout(() => {
                this.confirmationMsg = '';
                this.showMsgFlag = false;
            }, 5000);
        })
    }

    @HostListener('window: click', ['$event']) // for window scroll events
    onScroll(event) {
        if (event.keyCode == 9 || event.keyCode == 13) {

        }
        else {
            this.resetPasswordSuccess = false;
            this.isPasswordWrong = false;
            this.isPasswordMatched = false;
            this.invalidPassword1 = false;
            this.invalidPassword2 = false;
        }
    }

}
