import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../guard/auth-guard.service';
import { DeleteprofileComponent } from './deleteprofile/deleteprofile.component';
import { MyaccountproileComponent } from './myaccountproile/myaccountproile.component';
import { NewsletterUnsubscribeComponent } from './newsletter-unsubscribe/newsletter-unsubscribe.component';
import { ProfileComponent } from './profile/profile.component';

const routes: Routes = [
  {path:'', component: MyaccountproileComponent,canDeactivate: [AuthGuard] },
  {path:'new', component: ProfileComponent,canDeactivate: [AuthGuard]},
  {path: 'delete-account', component: DeleteprofileComponent,canDeactivate: [AuthGuard] },
  {path: 'newsletter-unsubscribe', component: NewsletterUnsubscribeComponent,canDeactivate: [AuthGuard] },
  {path: 'newsletter-unsubscribe', component: NewsletterUnsubscribeComponent,canDeactivate: [AuthGuard] },
  {path: ':level1/:level2', component: DeleteprofileComponent,canDeactivate: [AuthGuard] },
  {path: ':level1/:level2/:level3', component: ProfileComponent,canDeactivate: [AuthGuard] },
  {path: ':level1/:level2/:level3/:level4', component: ProfileComponent,canDeactivate: [AuthGuard] },


];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AccountRoutingModule { }
